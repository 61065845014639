import React from "react";
import { connect } from 'react-redux';
import * as moment from 'moment'

// reactstrap components
import {
    Button,
    Card,
    Breadcrumb,
    BreadcrumbItem,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";

// core components
import {QueryType, Roles, Routes, QueryStatusString, QueryTypeString, DATE_TIME_FORMAT } from 'constants/index.js';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DashboardLayout from "layouts/Admin";
import { QueryActions, SiteActions } from "actions/index.js";
import { RenderImage } from "components";
import { createMonitorQueryComments } from "api/index.js";
import cogoToast from "cogo-toast";
import noImageAccess from "assets/img/noImageAccess.jpeg";
    
class ViewQueryDetails extends React.Component {
    
    
    state = {
        documentPopup : false,
        docUrl : null,
    }
    
    componentDidMount() {
        this.props.getQueryDetails(this.props.token, this.props.match.params.id)
        this.props.getQueryHistory(this.props.token, this.props.match.params.id)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.query !== this.props.query) {
            this.props.getSiteSubjects(this.props.token, this.props.query.studySite._id)
            this.props.getSiteVisits(this.props.token, this.props.query.studySite._id)
        }
    }
    
    viewResponse = () => {
        const queryId = this.props.match.params.id
        this.props.history.push(`${Routes.QUERIES}/response/${queryId}`);
    }
    
    answerQuery = () => {
        const queryId = this.props.match.params.id
        this.props.history.push(`${Routes.QUERIES}/answer/${queryId}`);
    }
    
    edcUpdatedResponse = async () => {
        var commentsObj = {
            'monitorQuery': this.props.match.params.id,
            'comment': 'EDC has been updated',
            'ownerStaff': this.props.user.staffProfile._id,
        }
        console.log(commentsObj);
        try {
            // create query comments
            await createMonitorQueryComments(this.props.token, commentsObj);
            const { hide } = cogoToast.success('SDV Query response posted successfully.', {
                  onClick: () => {
                    hide();
                  },
                  position:"top-right",
                  hideAfter:3
            });
            this.onCancel();
              
        } catch(error) {
            alert("Failed to Update EDC Query");
            console.log(error);
        }
    }
    
    onCancel = () => {
        this.props.history.push(`${Routes.QUERIES}`);
    }
    
    canPostReply = () => {
        if (
            this.props.role &&
            [Roles.CCRA, Roles.DM, Roles.CSM].includes(this.props.role) &&
            this.props.query &&
            this.props.queryStatus === 'RESOLVED') {
            return true;
        }
        
        if (
            this.props.role &&
            this.props.role === Roles.SITE_SCANNER_RESOLVER &&
            this.props.query &&
            ['OPENED', 'RE_OPENED'].includes(this.props.query.queryStatus)) {
            return true;
        }
        
        return false;
    }
    
    canEditQuery = () => {
        if (this.props.role && 
            [Roles.CCRA, Roles.DM, Roles.PM, Roles.CSM].includes(this.props.role) &&
            this.props.query && this.props.query.owner && this.props.user && this.props.user.staffProfile
            && this.props.query.owner._id === this.props.user.staffProfile._id &&
            ['OPENED', 'RE_OPENED'].includes(this.props.query.queryStatus)) {
            return true;
        }
        
        return false;
    }

    editQuery = () => {
        if (this.props.query) {
            this.props.history.push(`${Routes.QUERIES}/editQuery/${this.props.query.id}`);
        }
    }
    
    getDocUrl = (document, version) => {
        let docUrl = noImageAccess;
        if (document && document.versionedDocument) {
            if (!version) {
                for (let i = 0; i < document.versionedDocument.length; ++i) { 
                    // check if current user has access to the url
                    if (this.props.user && this.props.user.staffProfile.staffType === 'EMPLOYEE' &&
                        document.versionedDocument[i].employeeAccess) {
                        docUrl = document.versionedDocument[i].url;
                    }
                    
                    if (this.props.user && this.props.user.staffProfile.staffType === 'SITE_STAFF' &&
                        document.versionedDocument[i].siteStaffAccess) {
                        docUrl = document.versionedDocument[i].url;    
                    }
                }
            } else {
                if (this.props.user && this.props.user.staffProfile.staffType === 'EMPLOYEE' &&
                    document.versionedDocument[version - 1].employeeAccess) {
                    docUrl = document.versionedDocument[version - 1].url;
                }
                
                if (this.props.user && this.props.user.staffProfile.staffType === 'SITE_STAFF' &&
                    document.versionedDocument[version - 1].siteStaffAccess) {
                    docUrl = document.versionedDocument[version - 1].url;    
                }
            }
        }
        return docUrl;
    }
    
    // check if user has access to the document on which query was raised
    hasDocAccess = (document) => {
        let staffType = [Roles.DM, Roles.DE, Roles.PM, Roles.CSM, Roles.CCRA].includes(this.props.role) ? 'EMPLOYEE' : 'SITE_STAFF';
        let hasAccess = false;
        if (document) {
            // check if there is atleast one version of the document has access
            //
            if (staffType === 'EMPLOYEE') {
                hasAccess = document.versionedDocument.findIndex(
                                version => 
                                    (version.uploadedBy.staffType === 'SITE_STAFF' && 
                                    version.employeeAccess === true)) >= 0;
            } else if (staffType === 'SITE_STAFF') {
                hasAccess = document.versionedDocument.findIndex(
                                version => 
                                    (version.uploadedBy.staffType === 'SITE_STAFF' && 
                                    version.siteStaffAccess === true)) >= 0;
            }
        }
        
        return hasAccess;
    }
    
    render() {
        const { query } = this.props;
        const { docUrl } = this.state;
        let monitorDocument = query && query.monitorDocument ? this.getDocUrl(query.monitorDocument, query.docVersion ) : null;
        let sourceDocument = query && query.linkSourceCRFDocument ? 
                                this.getDocUrl(query.linkSourceCRFDocument, query.linkVersion) : null;
                                    
        let queryAttachment =  (query && query.attachment) ? query.attachment : null;
        
        const hasAccess = query && query.monitorDocument ? this.hasDocAccess(query.monitorDocument) : true;
        
        let layout = 2;
        let answerText = 'Answer Query';
        if (query) {
            switch (query.queryType) {
                case QueryType.EDC_QUERY:
                    layout = 3;
                    if (queryAttachment) sourceDocument = null;
                break;
                case QueryType.SDV_QUERY:
                   if (sourceDocument) {
                        layout = 3;
                    } else {
                        layout = 2;
                    }
                    answerText = 'Reupload Source';
                break;
                case QueryType.SOURCE_DATA_QUERY:
                    answerText = 'Upload Source';
                break;
                default:
                break;
                    
            }
        }

        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.QUERIES}`}>Queries</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Query Details</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Modal
                        isOpen={this.state.documentPopup}
                        size="lg"
                        centered
                        toggle={async () => await this.setState({ documentPopup: false })}
                        backdrop="static"
                        keyboard={false}
                    >
                        <ModalHeader toggle={async () => await this.setState({ documentPopup: false })}>
                            Document Preview
                        </ModalHeader>
                        <ModalBody>
                        {   
                            docUrl &&
                            <div style={{ borderColor: 'gray', borderWidth: '1px' }}>
                                <RenderImage alt="." src={docUrl} /> :
                            </div>
                        }
                        </ModalBody>
                    </Modal>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Query Details</CardTitle>
                                </CardHeader>
                                <CardBody>
                                {
                                    query &&
                                    <>
                                        <Row>
                                            <Col sm={12/layout} xs={12}>
                                                <FormGroup>
                                                    <Label>{query.queryType === QueryType.EDC_QUERY ? 'EDC Query No.' : 'Query Description'}</Label>
                                                    <p>{query.query}</p>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Trial Participant ID</Label>
                                                    <p>{query.subject.uniqueCode}</ p>
                                                </FormGroup>
                                                {
                                                    query.monitorDocument &&
                                                    <FormGroup>
                                                        <Label>Data Domain</Label>
                                                        <p>{query.monitorDocument.domainData}</p>
                                                    </FormGroup>
                                                }
                                                <FormGroup>
                                                    <Label>Query Type</Label>
                                                    <p>{QueryTypeString[query.queryType]}</p>
                                                </FormGroup>
                                                 <FormGroup>
                                                    <Label>Query Status</Label>
                                                    <p>{QueryStatusString[query.queryStatus]}</p>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Raised By</Label>
                                                    <p>{`${query.owner.firstName} ${query.owner.lastName}`}</p>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Raised On</Label>
                                                    <p>{moment(query.createdAt).format(DATE_TIME_FORMAT)}</p>
                                                </FormGroup>
                                            </Col>
                                            {
                                                queryAttachment &&
                                                <Col sm={12/layout} xs={12} className="text-center">
                                                    <h6> Query Attachment </h6>
                                                     <Row className="text-center mx-auto">
                                                        <div className="query-image" style={{ cursor : 'pointer'}} 
                                                            onClick = { e =>  this.setState({ documentPopup: true, docUrl: queryAttachment})}
                                                        > 
                                                            <RenderImage alt="Query Attachment" src={queryAttachment} /> :
                                                        </div>
                                                    </Row>
                                                </Col>
                                            }
                                            {
                                                sourceDocument &&
                                                <Col sm={12/layout} xs={12} className="text-center">
                                                    <h6> Source Document </h6>
                                                    <Row className="text-center mx-auto">
                                                        <div className="query-image" style={{ cursor : 'pointer'}} 
                                                            onClick = { e =>  this.setState({ documentPopup: true, docUrl: sourceDocument})}
                                                        > 
                                                            <RenderImage alt="Source Document" src={sourceDocument} /> :
                                                        </div>
                                                    </Row>
                                                </Col>
                                            }
                                            {
                                                monitorDocument && 
                                                <Col sm={12/layout} xs={12} className="text-center">
                                                    <h6> CRF Document </h6>
                                                    <Row className="text-center mx-auto">
                                                        <div className="query-image" style={{ cursor : 'pointer'}} 
                                                            onClick = { e =>  this.setState({ documentPopup: true, docUrl: monitorDocument})}
                                                        > 
                                                            <RenderImage alt="CRF Document" src={monitorDocument} /> :
                                                        </div>
                                                    </Row>
                                                 </Col>
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="mx-auto">
                                                {
                                                    // Employee Staff Actions (CCRA, PM, DM)
                                                    // employee can edit raised query details
                                                    //
                                                    this.canEditQuery() &&
                                                    <Button color="info" className="btn-round" size="sm" onClick={this.editQuery}>
                                                        Edit Query Details
                                                    </Button>
                                                }
                                                {
                                                    this.canPostReply() &&
                                                    <Button color="info" className="btn-round" size="sm" onClick={this.answerQuery}>
                                                        {answerText}
                                                    </Button>
                                                }
                                                {
                                                    this.canPostReply() && query.queryType === QueryType.SDV_QUERY &&
                                                    <Button color="info" className="btn-round ml-3" size="sm" onClick={this.edcUpdatedResponse}>
                                                        EDC Updated
                                                    </Button>
                                                }
                                                {
                                                    this.props.responseHistory && this.props.responseHistory.length > 0 &&
                                                    <Button color="info" className="btn-round ml-3" outline size="sm" 
                                                        onClick={this.viewResponse}>
                                                        View Response
                                                    </Button>
                                                }
                                                <Button color="info ml-3" className="btn-round" outline size="sm" onClick={this.onCancel}>Back To Queries</Button>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                    {
                                        this.props.responseHistory && this.props.responseHistory.length > 0 &&
                                        <Row className="mt-4 mb-4">
                                            <Col>
                                                <span style={{ fontWeight: 500, fontSize: '18px' }}>Response History</span>
                                                {
                                                    this.props.responseHistory.map((val, index) => {
                                                        return (
                                                            <Row key={index} height={110} className="mt-4 ml-2 border-bottom">
                                                                <Col sm={1}>
                                                                    <RenderImage alt="." className="rounded-circle" width={48} height={48} style={{ maxHeight: 48, maxWidth: 48 }} 
                                                                        src={val.ownerStaff.profilePicture ? val.ownerStaff.profilePicture : require('assets/img/default_profile.jpg')} 
                                                                    />
                                                                </Col>
                                                                <Col sm={8}>
                                                                    <span style={{ fontSize: '14px', fontWeight: 700 }}>{`${val.ownerStaff.firstName} ${val.ownerStaff.lastName}`}</span><br />
                                                                    <Label style={{ fontSize: '12px', fontWeight: 700, marginBottom: '0px' }}>{moment(val.createdAt).format(DATE_TIME_FORMAT)}</Label>
                                                                    <p style={{ fontSize: '14px', fontWeight: 500 }}>{val.comment}</p>
                                                                </Col>
                                                                {
                                                                    val.attachment ?
                                                                        <Col sm={3}>
                                                                            <RenderImage alt="." className="rounded" width={48} height={48} 
                                                                                style={{ maxHeight: 48, maxWidth: 48 }} 
                                                                                src={hasAccess ? val.attachment : noImageAccess} 
                                                                                onClick={() => {
                                                                                    this.setState({ 
                                                                                        documentPopup: true, 
                                                                                        docUrl: hasAccess ? val.attachment : noImageAccess 
                                                                                    })
                                                                                }} />
                                                                        </Col>
                                                                        : null
                                                                }
                                                            </Row>
                                                        );
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout >
        );
    }
}

const mapStateToProps = ({ user, queries, sites, studies }) => {
    return {
        user : user,
        token: user.token,
        role: user.role ? user.role.name : '',
        query: queries.current,
        study: studies.current,
        site: sites.current,
        subjects: sites.subjects,
        visits: sites.visits,
        responseHistory: queries.history
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getQueryDetails: (token, id) => QueryActions.getQueryDetails(dispatch, token, id),
        getQueryHistory: (token, id) => QueryActions.getQueryHistory(dispatch, token, id),
        getSiteSubjects: (token, siteId) => SiteActions.getSiteSubjects(dispatch, token, siteId),
        getSiteVisits: (token, siteId) => SiteActions.getSiteVisitDetails(dispatch, token, siteId)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewQueryDetails);