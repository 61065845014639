import Axios from 'axios';
import { MONITOR_DOC_URL, MONITOR_SUBJECTS_URL, MONITOR_DOC_VERSIONS_URL, PAGE_SIZE } from 'api/list';

const getDocuments = (dispatch, token, queryParams, start = 0, limit=PAGE_SIZE) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    var url = queryParams ? `${MONITOR_SUBJECTS_URL}?_start=${start}&_limit=${limit}&${queryParams}` : 
                            `${MONITOR_SUBJECTS_URL}?_start=${start}&_limit=${limit}`

    Axios.get(url, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_DOCUMENTS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getDocumentsCount = (dispatch, token, queryParams) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    var countUrl = queryParams ? `${MONITOR_DOC_URL}/countbysubject?${queryParams}` : `${MONITOR_DOC_URL}/countbysubject`

    Axios.get(countUrl, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_DOCUMENTS_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}


const getDataPendingDocuments = (dispatch, token, queryParams, start = 0, limit=PAGE_SIZE) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    var url = queryParams ? `${MONITOR_SUBJECTS_URL}?_start=${start}&_limit=${limit}&${queryParams}` 
                            : `${MONITOR_SUBJECTS_URL}?_start=${start}&_limit=${limit}`

    Axios.get(url, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_DATA_PENDING_DOCUMENTS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getDataPendingDocumentsCount = (dispatch, token, queryParams) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    var countUrl = queryParams ? `${MONITOR_DOC_URL}/countbysubject?${queryParams}` : `${MONITOR_DOC_URL}/countbysubject`
    Axios.get(countUrl, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_DATA_PENDING_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getDocumentDetails = (dispatch, token, group) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_DOC_URL}?uploadGroup=${group}&_start=0&_limit=100`, { headers })
        .then((response) => {
            dispatch({
                type: 'DOCUMENT_DETAILS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getDocumentDetailsById = (dispatch, token, docId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_DOC_URL}/${docId}`, { headers })
        .then((response) => {
            dispatch({
                type: 'DOCUMENT_DETAILS_BY_ID',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const setDocumentVerified = (dispatch, token, docId, index, data) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    Axios.put(`${MONITOR_DOC_URL}/${docId}`, data, { headers })
        .then((response) => {
            dispatch({
                type: 'UPDATE_DOCUMENT',
                response: response.data,
                index : index
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const updateDocumentAccess = (dispatch, token, document, index, data) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    Axios.put(`${MONITOR_DOC_VERSIONS_URL}/bulkupdate/${document._id}`, data, { headers })
        .then((response) => {
            document.versionedDocument = response.data;
            dispatch({
                type: 'UPDATE_DOCUMENT',
                response: document,
                index : index
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getResponseDocument = (dispatch, token, url) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
 
    Axios.get(`${MONITOR_DOC_VERSIONS_URL}?url=${url}`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_RESPONSE_DOCUMENT',
                response: response.data,
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

export default { 
    getDocuments, 
    getDocumentsCount,
    getDocumentDetails, 
    setDocumentVerified, 
    getDocumentDetailsById, 
    getDataPendingDocuments, 
    getDataPendingDocumentsCount,
    updateDocumentAccess,
    getResponseDocument
}