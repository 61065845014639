import React from "react";
import { connect } from 'react-redux';

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Breadcrumb,
    BreadcrumbItem,
    Label,
    FormGroup,
    Button
} from 'reactstrap';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin"
import { Routes } from 'constants/index.js';
import { StaffActions } from "actions";
import defaultPic from 'assets/img/default_profile.jpg';
import { RenderImage } from "components";

class StaffView extends React.Component {

    constructor() {
        super();
        this.state = {
            role: null,
            requiredSite: false,
            requiredStudy: false,
        };
    }

    componentDidMount() {
        const { token } = this.props;
        this.props.getStaffDetails(token, this.props.match.params.id);
    }

    render() {
        const { staff } = this.props;
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.STAFF}`}>User Accounts</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">User Details</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card style={{ minHeight: '725px' }}>
                                <CardHeader>
                                    <Row>
                                        <Col sm={6} md={6} lg={10}>
                                            <CardTitle tag="h4">User Details</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row style={{ marginLeft: '0px' }}>
                                        <Col md="3">
                                            <div className="fileinput text-center">
                                                <div className={"thumbnail img-circle"} >
                                                    <RenderImage src={staff && staff.profilePicture ? staff.profilePicture : defaultPic} alt="." onClick={() => this.handleClick()} style={{ width: '100px', height: '100px' }} />
                                                </div>
                                                <div>{`${staff && staff.firstName} ${staff && staff.lastName}`}</div>
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup>
                                                <Label>First Name</Label>
                                                <p>{`${staff && staff.firstName}`}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>ID No.</Label>
                                                <p>.</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Mobile No.</Label>
                                                <p>{`${staff && staff.mobileNo}`}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup>
                                                <Label>Last Name</Label>
                                                <p>{`${staff && staff.lastName}`}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Email</Label>
                                                <p>{`${staff && staff.user && staff.user.email}`}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Role</Label>
                                                <p>{`${staff && staff.user && staff.user.role}`}</p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Button color="info" className="btn-round" size="sm" href={`${Routes.STAFF}`} style={{ fontWeight: 700 }}>Back to Users</Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, staff }) => {
    return {
        token: user.token,
        staff: staff.current,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getStaffDetails: (token, id) => StaffActions.getStaffDetails(dispatch, token, id),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(StaffView);
