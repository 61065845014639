const initialState = {
    data: []
};

const queries = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_QUERIES':
            return { ...state, data: action.response }
        case 'QUERY_DETAILS':
            return { ...state, current: action.response }
        case 'QUERY_HISTORY':
            return { ...state, history: action.response }
        case 'GET_QUERIES_COUNT':
            return {...state, count: action.response}
        default:
            return state;
    }
}

export default queries;