import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import { Button } from "reactstrap";

// core components
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import { uploadFileToStrapi, deleteFileFromStrapi } from "commons";
import { checkFileSize } from "commons/Utils";

class ImageUpload extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      fileData: null,
      invalidFileSize: false
    };
    this.fileInput = React.createRef();
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.file !== this.props.file) {
      this.setState({ imagePreviewUrl: this.props.file, file: this.props.file })
    }
  }

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const validFile = checkFileSize(file);
    if (validFile.valid) {
      this.setState({ invalidFileSize: false })
      // upload file
      uploadFileToStrapi(this.props.token, file).then(res => {
        const data = res.data[0];
        const fileData = { url: data.url, id: data._id }
        this.setState({ fileData: fileData })
        this.props.onFileLoaded(fileData);
      }).catch(e => console.log(e));

      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      if (file) {
        reader.readAsDataURL(file);
      }

    } else {
      this.setState({ invalidFileSize: true, invalidMessage: validFile.message })
    }

  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleClick = () => {
    this.fileInput.current.click();
  };

  handleRemove = () => {
    // delete file
    deleteFileFromStrapi(this.props.token, this.state.fileData.id).then(res => {
      this.props.onFileRemoved();
      //handle delete in the component
    }).catch(e => console.log(e))

    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
    });
    this.fileInput.current.value = null;
  };

  render() {
    return (
      <div className="fileinput">
        <input
          type="file"
          accept={this.props.acceptType}
          onChange={this.handleImageChange}
          ref={this.fileInput}
        />
        <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
          <img src={this.state.imagePreviewUrl} alt="." />
        </div>
        <div>
          {
            this.state.file === null ?
              <span>
                {
                  this.state.invalidFileSize ? <p><i className="fa fa-exclamation-triangle" style={{ color: "orange", paddingRight: '10px' }} aria-hidden="true"></i>{this.state.invalidMessage}</p> : null
                }
                <Button className="btn-round" size="sm" onClick={() => this.handleClick()}>
                  {this.props.avatar ? "Add Photo" : "Select image"}
                </Button>
              </span>
              :
              <span className="text-center">
                <Button className="btn-round" size="sm" onClick={() => this.handleClick()}>
                  Change
                  </Button>
                {this.props.avatar ? <br /> : null}
                <Button
                  color="danger" size="sm"
                  className="btn-round"
                  onClick={() => this.handleRemove()}
                >
                  <i className="fa fa-times" /> Remove
                  </Button>
              </span>
          }
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  token: PropTypes.string
};

export default ImageUpload;
