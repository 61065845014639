import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DashboardLayout from "layouts/Admin";
import { Routes, Validations } from 'constants/index.js';
import { DocumentActions } from "actions/index.js";
import { createQuery } from "api/index.js";
import cogoToast from "cogo-toast";
import { AvField, AvForm } from "availity-reactstrap-validation";


class RaiseMetaQuery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            designation: null,
            queryDetails: "",
            queryType: "Meta Data Query",
            isUploading:false
        };
        this.onChange = this.onChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.createMetaDataQuery = this.createMetaDataQuery.bind(this);

    }
    componentDidMount() {
        this.props.getDocumentDetails(this.props.token, this.props.match.params.id);
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onCancel() {
        this.props.history.push(`${Routes.DOCUMENTS}/${this.props.match.params.id}`);
    }

    async createMetaDataQuery(event, values) {
        this.setState({ isUploading: true });
        try {
            //IMAGE_QUERY,SDV_QUERY,SOURCE_DATA_QUERY,META_DATA_QUERY
            let details = this.props.details[this.props.match.params.index];
            let monitorDocQueryObj = {
                subject: details.subject.id,
                studySite: details.studySite.id,
                monitorDocument: details.id,
                docVersion: String(details.versionedDocument.length.toString()),
                queryType: "META_DATA_QUERY",
                query: values.queryDetails,
                queryStatus: 'OPENED',
                owner: this.props.user.staffProfile._id,
                cancelRemarks: "",
                isDeleted: false,
            }
            await createQuery(this.props.token, monitorDocQueryObj);
            const { hide } = cogoToast.success('Meta query raised successfully.', {
                onClick: () => {
                    hide();
                },
                position: "top-right",
                hideAfter: 3
            });
            this.props.history.push(`${Routes.DOCUMENTS}/${this.props.match.params.id}`);
        } catch (error) {
            console.log(error);
            cogoToast.error('Failed to create query.')
            this.setState({ isUploading: false });
        }
    }

    render() {
        const {isUploading } = this.state;
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.DOCUMENTS}`} >Documents</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Document Details</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Image Query</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h5">Raise Meta Data Query</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col sm={5}>
                                        <AvForm onValidSubmit={this.createMetaDataQuery}>
                                            <AvField label="Query Type" name="queryType" type="text" placeholder="Image Query" disabled defaultValue={this.state.queryType} />
                                            <AvField name="queryDetails" autoComplete="off" label="Query Details" type="textarea" validate={{
                                                required: { value: true, errorMessage: 'Please fill query details.' },
                                                pattern: Validations.MULTI_LINE_ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                                minLength: { value: 6, errorMessage: 'Query details must be at least 6 characters' },
                                                maxLength: { value: 500, errorMessage: 'Query details must not be more than 500 characters.' },
                                            }} />

                                            <Row>
                                                <Button color="info ml-5" className="btn-round" size="sm" type="submit" disabled={isUploading}>
                                                {isUploading ? 'Please wait..' : 'Raise Metadata Query'}
                                            </Button>
                                                <Button color="info" className="btn-round ml-3" outline size="sm" type="button" onClick={this.onCancel}>
                                                    Cancel
                                            </Button>
                                            </Row>
                                        </AvForm>
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        )
    }
}
const mapStateToProps = ({ user, documents, studies }) => {
    return {
        token: user.token,
        details: documents.details,
        study: studies.myStudy,
        user: user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getDocumentDetails: (token, id) => DocumentActions.getDocumentDetails(dispatch, token, id),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RaiseMetaQuery);

