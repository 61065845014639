import React from 'react';
import { RenderImage } from 'components';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';

const EDCQuery = (props) => {

    const [docUrl, setDocUrl] = React.useState(null);
    
    const latestResponse = (props.responseHistory && props.responseHistory.length > 0) ?
                            props.responseHistory[0] : null;
    const latestDocument =  latestResponse && props.responseDocument ? 
                                props.getDocUrl({versionedDocument : props.responseDocument}, 0) : null;
                                
    if (latestResponse && latestDocument) {
        return (
            <div className="text-center" style={{cursor : 'pointer'}} onClick= { e => setDocUrl(props.latestResponse.attachment)}>
                <Modal
                    isOpen={docUrl !== null}
                    size="lg"
                    centered
                    toggle={() => setDocUrl(null)}
                    backdrop="static"
                    keyboard={false}
                >
                    <ModalHeader toggle={() => setDocUrl(null)}>
                        Document Preview
                    </ModalHeader>
                    <ModalBody>
                    {   
                        docUrl &&
                        <div className="query-image">
                           <RenderImage alt="CRF Document" className="mx-auto"  src={docUrl} />
                        </div>
                    }
                    </ModalBody>
                </Modal>
                <h6>Response Attachment</h6>
                <div className="query-image">
                    <RenderImage alt="Query Response Attachment" className="mx-auto"  src={props.latestResponse.attachment} />
                </div>
            </div>
        );
    } else {
        return (<div/>);
    }
}

export default EDCQuery;