import React from 'react';
import { Roles, DocTypes, PMDocTypes } from 'constants/index';
import { selectFilter } from 'react-bootstrap-table2-filter';
import {
    Badge,
} from "reactstrap";

export default (role, state) => {
    switch (role) {
        case Roles.SITE_SCANNER:
        case Roles.SITE_SCANNER_RESOLVER:
        case Roles.CCRA:
            return [
                {
                    dataField: 'docType',
                    text: 'Document Type',
                    filter: selectFilter({
                        options: DocTypes
                    })
                },
                {
                    dataField: 'domainData',
                    text: 'Domain',
                    filter: selectFilter({
                        options: state && state.domainData ? state.domainData : []
                    })

                },
                {
                    dataField: 'subject.uniqueCode',
                    text: 'Trial Participant ID',
                    filter: selectFilter({
                        options: state && state.subjects ? state.subjects : []
                    })
                },
                {
                    dataField: 'visit.name',
                    text: 'Visit ID',
                    filter: selectFilter({
                        options: state && state.visits ? state.visits : []
                    })
                },
                {
                    dataField: 'numberOfDocuments',
                    text: 'Pages',
                },
                {
                    dataField: 'openQueryCount',
                    text: 'Open Queries',
                },
                {
                    dataField: 'isCCRAVerified',
                    text: 'Status',
                    filter: selectFilter({
                        options: { false: 'Submitted', true: 'Verified' }
                    }),
                    formatter: (cell, row) => {
                        if (row.isCCRAVerified === row.numberOfDocuments) {
                            return (
                                <Badge className="verified-badge">Verified</Badge>
                            );
                        }

                        return (
                            <Badge className="submitted-badge">Submitted</Badge>
                        );
                    }
                }
            ];
        case Roles.DE:
            return [
                {
                    dataField: 'domainData',
                    text: 'Domain',
                    filter: selectFilter({
                        options: state && state.domainData ? state.domainData : []
                    })

                },
                {
                    dataField: 'subject.uniqueCode',
                    text: 'Trial Participant ID',
                    filter: selectFilter({
                        options: state && state.subjects ? state.subjects : []
                    })
                },
                {
                    dataField: 'visit.name',
                    text: 'Visit ID',
                    filter: selectFilter({
                        options: state && state.visits ? state.visits : []
                    })
                },
                {
                    dataField: 'numberOfDocuments',
                    text: 'Pages',
                },
                {
                    dataField: 'de1CompletedBy_null',
                    text: 'DE1',
                    filter: selectFilter({
                         options: { false: 'Completed', true: 'Pending' }
                    }),
                    formatter: (cell, row) => {
                        if (row.de1CompletedBy !== row.numberOfDocuments) {
                            return (
                                <Badge className="verified-badge">Pending</Badge>
                            );
                        }

                        return (
                            <Badge className="submitted-badge">Completed</Badge>
                        );
                    }
                },
                {
                    dataField: 'de2CompletedBy_null',
                    text: 'DE2',
                    filter: selectFilter({
                        options: { false: 'Completed', true: 'Pending' }
                    }),
                    formatter: (cell, row) => {
                        if (row.de2CompletedBy !== row.numberOfDocuments) {
                            return (
                                <Badge className="verified-badge">Pending</Badge>
                            );
                        }

                        return (
                            <Badge className="submitted-badge">Completed</Badge>
                        );
                    }
                },
                {
                    dataField: 'deCompletedBy_null',
                    text: 'OverAll DE',
                    filter: selectFilter({
                        options: { false: 'Completed', true: 'Pending' }
                    }),
                    formatter: (cell, row) => {
                        if (row.deCompletedBy !== row.numberOfDocuments) {
                            return (
                                <Badge className="verified-badge">Pending</Badge>
                            );
                        }

                        return (
                            <Badge className="submitted-badge">Completed</Badge>
                        );
                    }
                },
                
            ];
        case Roles.DM:
            return [
                {
                    dataField: 'domainData',
                    text: 'Domain',
                    filter: selectFilter({
                        options: state && state.domainData ? state.domainData : []
                    })

                },
                {
                    dataField: 'subject.uniqueCode',
                    text: 'Trial Participant ID',
                    filter: selectFilter({
                        options: state && state.subjects ? state.subjects : []
                    })
                },
                {
                    dataField: 'visit.name',
                    text: 'Visit ID',
                    filter: selectFilter({
                        options: state && state.visits ? state.visits : []
                    })
                },
                {
                    dataField: 'numberOfDocuments',
                    text: 'Pages',
                },
                {
                    dataField: 'openQueryCount',
                    text: 'Open Queries',
                },
                {
                    dataField: 'de1CompletedBy_null',
                    text: 'DE1',
                    filter: selectFilter({
                        options: { false: 'Completed', true: 'Pending' }
                    }),
                    formatter: (cell, row) => {
                        if (row.de1CompletedBy !== row.numberOfDocuments) {
                            return (
                                <Badge className="verified-badge">Pending</Badge>
                            );
                        }

                        return (
                            <Badge className="submitted-badge">Completed</Badge>
                        );
                    }
                },
                {
                    dataField: 'de2CompletedBy_null',
                    text: 'DE2',
                    filter: selectFilter({
                        options: { false: 'Completed', true: 'Pending' }
                    }),
                    formatter: (cell, row) => {
                        if (row.de2CompletedBy !== row.numberOfDocuments) {
                            return (
                                <Badge className="verified-badge">Pending</Badge>
                            );
                        }

                        return (
                            <Badge className="submitted-badge">Completed</Badge>
                        );
                    }
                },
                {
                    dataField: 'deCompletedBy_null',
                    text: 'OverAll DE',
                    filter: selectFilter({
                        options: { false: 'Completed', true: 'Pending' }
                    }),
                    formatter: (cell, row) => {
                        if (row.deCompletedBy !== row.numberOfDocuments) {
                            return (
                                <Badge className="verified-badge">Pending</Badge>
                            );
                        }

                        return (
                            <Badge className="submitted-badge">Completed</Badge>
                        );
                    }
                },
            ];
        case Roles.PM:
        case Roles.CSM:
            return [
                {
                    dataField: 'docType',
                    text: 'Document Type',
                    filter: selectFilter({
                        options: PMDocTypes
                    })
                },
                {
                    dataField: 'domainData',
                    text: 'Domain',
                    filter: selectFilter({
                        options: state && state.domainData ? state.domainData : []
                    })

                },
                {
                    dataField: 'subject.uniqueCode',
                    text: 'Trial Participant ID',
                    filter: selectFilter({
                        options: state && state.subjects ? state.subjects : []
                    })
                },
                {
                    dataField: 'visit.name',
                    text: 'Visit ID',
                    filter: selectFilter({
                        options: state && state.visits ? state.visits : []
                    })
                },
                {
                    dataField: 'numberOfDocuments',
                    text: 'Pages',
                },
                {
                    dataField: 'openQueryCount',
                    text: 'Open Queries',
                },
                 {
                    dataField: 'de1CompletedBy_null',
                    text: 'DE1',
                    filter: selectFilter({
                         options: { false: 'Completed', true: 'Pending' }
                    }),
                    formatter: (cell, row) => {
                        if (row.de1CompletedBy !== row.numberOfDocuments) {
                            return (
                                <Badge className="verified-badge">Pending</Badge>
                            );
                        }

                        return (
                            <Badge className="submitted-badge">Completed</Badge>
                        );
                    }
                },
                {
                    dataField: 'de2CompletedBy_null',
                    text: 'DE2',
                    filter: selectFilter({
                        options: { false: 'Completed', true: 'Pending' }
                    }),
                    formatter: (cell, row) => {
                        if (row.de2CompletedBy !== row.numberOfDocuments) {
                            return (
                                <Badge className="verified-badge">Pending</Badge>
                            );
                        }

                        return (
                            <Badge className="submitted-badge">Completed</Badge>
                        );
                    }
                },
                {
                    dataField: 'deCompletedBy_null',
                    text: 'OverAll DE',
                    filter: selectFilter({
                        options: { false: 'Completed', true: 'Pending' }
                    }),
                    formatter: (cell, row) => {
                        if (row.deCompletedBy !== row.numberOfDocuments) {
                            return (
                                <Badge className="verified-badge">Pending</Badge>
                            );
                        }

                        return (
                            <Badge className="submitted-badge">Completed</Badge>
                        );
                    }
                },
            ];
        default:
            return [];
        
    };
};
        