const initialState = {
    data: []
};

const sponsors = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SPONSORS':
            return {
                ...state,
                data: action.response
            }
        case 'GET_SPONSORS_COUNT':
            return {
                ...state,
                count: action.response
            }
        case 'SPONSOR_DETAILS':
            return {
                ...state,
                current: action.response
            }
        default:
            return state;
    }
};

export default sponsors;