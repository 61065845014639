import React from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    Input,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
} from "reactstrap";
// core components
import { AvForm, AvField, AvGroup, } from 'availity-reactstrap-validation';
import { PanelHeader } from "components";
import DashboardLayout from "layouts/Admin"
import { Routes, Validations } from 'constants/index.js';
import { SiteActions } from "actions";
import { createSite } from "api";
import cogoToast from "cogo-toast";
import {AvFeedback, AvInput} from "availity-reactstrap-validation";
import Select from 'react-select';

class SiteAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            singleSelect: null,
            uniqueIDType: 'automatic',
            siteType: 'create',
            crfType: 'PAPER',
            validName: true,
            validPatientsCount: true,
            validAddress: true,
            validContactNumber: true,
            validEmail: true,
            validUniqueID: true,
            patientsList: [],
            isUploading: false
        }
    }

    // handle input change
    handleInputChange = (e, index) => {
        const { value } = e.target;
        const list = this.state.patientsList;
        list[index]['value'] = value;
        this.setState({
            patientsList: list
        })
    };

    onCreateTypeSelection = (e) => {
        // const  siteCreationType  = this.state;
        this.setState({
            siteCreationType: 'create'
        })
    }

    handleSubmit = (e, values) => {
        this.onCreateSite(values)
    }

    componentDidMount() {
        this.props.getSites(this.props.token);
    }

    componentDidUpdate(prevProps, nextProps) {
        if (this.props.sites !== prevProps.sites) {
            this.setState({ siteType: this.props.sites && this.props.sites.length ? 'select' : 'create' });
        }
    }

    async onCreateSite(values) {

        if (values.site) {
            const selectedSite = this.props.sites.filter(val => val._id === values.site)[0]
            values['site'] = selectedSite
        }
        const { siteType } = this.state;
        const { study } = this.props;

        const site = {}
        if (siteType === 'select') {
            site['name'] = values.site.name;
            site['contactPerson'] = values.site.contactPerson;
            site['contactNumber'] = values.site.contactNumber;
            site['address'] = values.site.address;
            site['designation'] = values.site.designation;
            site['contactEmail'] = values.site.contactEmail;
        } else if (siteType === 'create') {
            site['name'] = values.name;
            site['address'] = values.address;
            site['contactNumber'] = values.contactNumber;
            site['contactPerson'] = values.contactPerson;
            site['designation'] = values.designation;
            site['contactEmail'] = values.contactEmail;
        }
        site['crfType'] = this.state.crfType;
        site['investigator'] = values.investigator;

        site['subjectPrefix'] = values.subjectPrefix;
        if (this.state.uniqueIDType === 'automatic') {
            site['numSubjects'] = values.numSubjects;
        }
        site['domainData'] = study.domainData;
        site['riskAssessment'] = study.riskAssessment;
        site['clinicalStudy'] = this.props.match.params.id;

        const response = await createSite(this.props.token, site);
        if (response && response.status === 200) {
            const { hide } = cogoToast.success('Hospital added successfully.', {
                onClick: () => {
                    hide();
                },
                position: "top-right",
                hideAfter: 3
            });
            this.props.history.push(`${Routes.STUDIES}/${this.props.match.params.id}`);
        } else {
            alert('Failed Hospital creation');
        }
    }

    render() {
        const { uniqueIDType, siteType, patientsList, crfType, isUploading } = this.state;

        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.STUDIES}`}>Studies</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.STUDIES}/${this.props.match.params.id}`}>Study Details</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Add New Hospital</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card style={{ minHeight: '755px' }}>
                                <CardHeader>
                                    <CardTitle tag="h4">Create New Hospital</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs={4}>
                                            <AvForm onValidSubmit={this.handleSubmit} >
                                                {
                                                    <AvGroup check className="form-check-radio" inline style={{ marginBottom: '20px' }}>
                                                        <Label check style={{ paddingLeft: '12px' }}>
                                                            <Input
                                                                value="select"
                                                                name="siteType"
                                                                type="radio"
                                                                checked={siteType === 'select' ? true : false}
                                                                onChange={(value) => {
                                                                    this.setState({ siteType: 'select' })
                                                                }}
                                                            />
                                                            <span className="form-check-sign" />
                                                        Select Hospital
                                                        </Label>
                                                        <Label check style={{ paddingLeft: '12px', marginLeft: '20px' }}>
                                                            <Input
                                                                value="create"
                                                                name="siteType"
                                                                type="radio"
                                                                checked={siteType === 'create' ? true : false}
                                                                onChange={(value) => {
                                                                    this.setState({ siteType: 'create' })
                                                                }}
                                                            />
                                                            <span className="form-check-sign" />
                                                        Create Hospital
                                                        </Label>
                                                    </AvGroup>
                                                }

                                                {
                                                    siteType === 'select' &&
                                                    <>
                                                        <AvGroup>
                                                            <Label>Hospital</Label>
                                                            <Select 
                                                                className="react-select info"
                                                                classNamePrefix="react-select"
                                                                placeholder="Select Hospital"
                                                                name="site_select"
                                                                options={this.props.sites ?
                                                                    this.props.sites.map((val, index) => {
                                                                        return ({
                                                                            value: val._id,
                                                                            label: val.name
                                                                        })
                                                                    }) : []}
                                                                onChange={(value) => {this.setState({site_select: value.value})}}
                                                                />
                                                            <AvInput name="site" value={this.state.site_select} hidden autoComplete="off" required={siteType === 'select' ? true : false} />
                                                            <AvFeedback>Please select a hospital.</AvFeedback>
                                                        </AvGroup>
                                                    </>
                                                }
                                                {
                                                    siteType === 'create' &&
                                                    <>
                                                        <AvField name="name" autoComplete="off" label="Hospital Name" type="text" validate={{
                                                            required: { value: true, errorMessage: 'Please enter a site name.' },
                                                            pattern: Validations.ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                                            minLength: { value: 6, errorMessage: 'Your hospital name must be at least 6 characters' },
                                                            maxLength: { value: 50, errorMessage: 'Your hospital name must not be more than 50 characters.' }
                                                        }} />
                                                        <AvField name="address" autoComplete="off" label="Address" type="textarea" validate={{
                                                            required: { value: true, errorMessage: 'Please enter an address.' },
                                                            pattern: Validations.MULTI_LINE_ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                                            minLength: { value: 4, errorMessage: 'Your address must be at least 4 characters' },
                                                            maxLength: { value: 256, errorMessage: 'Your address must not be more than 256 characters.' }
                                                        }} />
                                                        <AvField name="contactPerson" autoComplete="off" label="Contact Person" type="text" validate={{
                                                            required: { value: true, errorMessage: 'Please enter contact person name.' },
                                                            pattern: Validations.ALPHABETS_WITH_SPACES,
                                                            minLength: { value: 4, errorMessage: 'Your contact person name must be at least 4 characters' },
                                                            maxLength: { value: 50, errorMessage: 'Your contact person name should be max 50 characters.' },
                                                        }} />
                                                        <AvField name="contactNumber" autoComplete="off" label="Contact Number" type="text" placeholder="+447911123456" validate={{
                                                            required: { value: true, errorMessage: 'Please enter a contact number.' },
                                                            pattern: Validations.TELEPHONE,
                                                        }} />
                                                        <AvField name="contactEmail" autoComplete="off" label="Contact Email" type="email" required validate={{
                                                            required: { value: true, errorMessage: 'Please enter an email.' },
                                                            email: { value: true, errorMessage: 'Please enter a valid email address.' },
                                                            maxLength: { value: 50, errorMessage: 'Contact Email should not be more than 50 characters. ' },
                                                        }} />
                                                        <AvField name="designation" autoComplete="off" label="Designation" type="text" validate={{
                                                            required: { value: true, errorMessage: 'Please enter a designation.' },
                                                            pattern: Validations.ALPHABETS_WITH_SPACES,
                                                            minLength: { value: 4, errorMessage: 'Your designation must be at least 4 characters' },
                                                            maxLength: { value: 30, errorMessage: 'Your designation must not be more than 30 characters.' },
                                                        }} />
                                                    </>
                                                }
                                                <Label> CRF Type </Label><br />
                                                <AvGroup check className="form-check-radio" inline required>
                                                    <Label check style={{ paddingLeft: '12px' }}>
                                                        <Input
                                                            value="PAPER"
                                                            name="crfType"
                                                            type="radio"
                                                            checked={crfType === 'PAPER' ? true : false}
                                                            onChange={() => { this.setState({ crfType: 'PAPER' }) }}
                                                        />
                                                        <span className="form-check-sign" />
                                                        Paper
                                                    </Label>
                                                    <Label check style={{ paddingLeft: '12px', marginLeft: '20px' }}>
                                                        <Input
                                                            value="ELECTRONIC"
                                                            name="crfType"
                                                            type="radio"
                                                            checked={crfType === 'ELECTRONIC' ? true : false}
                                                            onChange={() => { this.setState({ crfType: 'ELECTRONIC' }) }}
                                                        />
                                                        <span className="form-check-sign" />
                                                        Electronic
                                                    </Label>
                                                </AvGroup>
                                                <AvField name="investigator" autoComplete="off" label="Investigator" type="text" validate={{
                                                    pattern: Validations.ALPHABETS_WITH_SPACES,
                                                    required: { value: true, errorMessage: 'Please enter investigator name.' },
                                                    minLength: { value: 4, errorMessage: 'Your investigator name must be at least 4 characters' },
                                                    maxLength: { value: 30, errorMessage: 'Your investigator name must not be more than 30 characters.' },
                                                }}
                                                />

                                                <AvField name="numSubjects" autoComplete="off" label="No. of Trial Participants" type="number" required validate={{
                                                    required: { value: true, errorMessage: 'Please enter number of trial participants.' },
                                                    pattern: Validations.POSITIVE_NUMBERS,
                                                    min: { value: 1, errorMessage: 'At least 1 trial participant need to be created.' },
                                                    maxLength: { value: 3, errorMessage: 'number of trial participants can be 3' }
                                                }}
                                                    onChange={e => {

                                                        var patientsList = []
                                                        for (var i = 0; i < e.target.value; i++) {
                                                            patientsList.push({ key: `${i + 1}.PatientID`, value: '' })
                                                        }
                                                        this.setState({ patientsCount: e.target.value, patientsList: patientsList })
                                                    }} />
                                                <AvGroup>
                                                    <Label>Generate UniqueID format for trial participants</Label>
                                                    <AvGroup check className="form-check-radio" inline>
                                                        <Label check style={{ paddingLeft: '12px' }}>
                                                            <Input
                                                                value="automatic"
                                                                name="createType"
                                                                type="radio"
                                                                checked={uniqueIDType === 'automatic' ? true : false}
                                                                onChange={() => { this.setState({ uniqueIDType: 'automatic' }) }}
                                                            />
                                                            <span className="form-check-sign" />
                                                            Generate Automatically
                                                        </Label>
                                                        <Label check style={{ paddingLeft: '12px', marginLeft: '20px' }}>
                                                            <Input
                                                                value="manual"
                                                                name="createType"
                                                                type="radio"
                                                                checked={uniqueIDType === 'manual' ? true : false}
                                                                onChange={() => { this.setState({ uniqueIDType: 'manual' }) }}
                                                            />
                                                            <span className="form-check-sign" />
                                                            Generate Manualy
                                                    </Label>
                                                    </AvGroup>
                                                </AvGroup>
                                                {
                                                    (uniqueIDType === 'automatic') &&
                                                    <AvField name="subjectPrefix" autoComplete="off" label="UniqueID format for Trial Participants" type="text" validate={{
                                                        required: { value: true, errorMessage: 'Please enter an unique id format' },
                                                        minLength: { value: 2, errorMessage: 'Your unique id must be at least 2 characters.' },
                                                        maxLength: { value: 64, errorMessage: 'Your unique id must not be more than 64 characters.' }
                                                    }} />}

                                                {
                                                    (uniqueIDType === 'manual') &&
                                                    patientsList.map((val, i) =>
                                                        <AvField key={i} name={val.key} autoComplete="off" label={val.key} type="text" validate={{
                                                            required: { value: true, errorMessage: 'Please enter an participant id.' },
                                                        }}
                                                            onChange={e => this.handleInputChange(e, i)} />
                                                    )
                                                }

                                                <Button color="info" style={{ marginLeft: '20px' }} className="btn-round" size="sm" type="submit" disabled={isUploading}>
                                                    {isUploading ? 'Please wait..' : 'Add Hospital'}
                                                </Button>
                                                <Button color="info" className="btn-round ml-4" outline size="sm" type="button" href={`${Routes.STUDIES}/${this.props.match.params.id}`} >Cancel</Button>
                                            </AvForm>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, sites, studies }) => {
    return {
        token: user.token,
        sites: sites.data,
        study: studies.current
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSites: (token) => SiteActions.getAllSites(dispatch, token),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SiteAdd);