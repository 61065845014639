const initialState = {
    data: []
};

const staff = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_STAFF':
            return { ...state, data: action.response }
        case 'GET_STAFF_COUNT':
            return { ...state, count: action.response }
        case 'GET_SITE_STAFF':
            return { ...state, siteStaff: action.response }
        case 'GET_SITE_STAFF_COUNT':
            return { ...state, siteStaffCount: action.response }
        case 'GET_STUDY_STAFF':
            return { ...state, studyStaff: action.response }
        case 'GET_STUDY_STAFF_COUNT':
            return { ...state, studyStaffCount: action.response }
        case 'STAFF_DETAILS':
            return { ...state, current: action.response }
        case 'PROJECT_MANAGERS':
            return { ...state, pms: action.response }
        default:
            return state;
    }
};

export default staff;