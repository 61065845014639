import Axios from 'axios';
import { AUTH_URL, SPONSER_URL, STUDIES_URL, SITES_URL, STAFF_URL, SITE_STAFF_PIVOT_URL, PROCESS_DOCS_URL, SITE_SUBJECTS_URL, MONITOR_DOC_URL, MONITOR_DOC_QUERIES_URL, MONITOR_REPORTS_URL, MONITOR_DOC_VERSIONS_URL, MONITOR_QUERY_COMMENTS_URL, AUDIT_TRAIL_URL, NOTIFICATION_STATUSES_UPDATE, FORGET_PASSWORD, RESET_PASSWORD, ACTIVATE_ACCOUNT } from 'api/list';

const getHeaders = (token) => {
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    }
}

export const getToken = (identifier, password) => {
    return Axios.post(`${AUTH_URL}`, { identifier, password })
}

export const createSponsor = (token, sponsor) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.post(`${SPONSER_URL}`, sponsor, { headers });
}

export const createStudy = (token, study) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.post(`${STUDIES_URL}`, study, { headers });
}

export const createStaff = (token, staff) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.post(`${STAFF_URL}`, staff, { headers });
}

export const createSiteStaffPivot = (token, siteStaff) => {
    return Axios.post(`${SITE_STAFF_PIVOT_URL}`, siteStaff, { headers: getHeaders(token) });
}

export const updateSiteStaffPivot = async (token, id, siteStaff) => {
    return await Axios.put(`${SITE_STAFF_PIVOT_URL}/${id}`, siteStaff, { headers: getHeaders(token) });
}

export const updateSite = (token, siteId, site) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.put(`${SITES_URL}/${siteId}`, site, { headers });
}

// site bulk update is to update all study sites
// matching the site name
export const bulkUpdateSites = (token, siteName, site) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.put(`${SITES_URL}/bulkupdate/${siteName}`, site, { headers });
}

export const updateStudy = (token, studyId, study) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.put(`${STUDIES_URL}/${studyId}`, study, { headers });
}

export const updateStudyStatus = (token, id, status) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.put(`${STUDIES_URL}/${id}`, { isActive: status }, { headers });
}

export const createSite = (token, site) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.post(`${SITES_URL}`, site, { headers });
}

export const updateSponsor = (token, id, sponsor) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.put(`${SPONSER_URL}/${id}`, sponsor, { headers });
}

export const updateMonitoringPlan = (token, siteId, plan) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.put(`${SITES_URL}/updatePlan/${siteId}`, plan, { headers });
}

export const updateCRFPages = (token, siteId, crfPages) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.put(`${SITES_URL}/updateCRFPages/${siteId}`, crfPages, { headers });
}

export const updateStaff = (token, staffId, staff) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.put(`${STAFF_URL}/${staffId}`, staff, { headers });
}

export const createProcessDocs = (token, docInfo) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.post(`${PROCESS_DOCS_URL}`, docInfo, { headers });
}

export const updateSiteSubject = async (token, subjectId, subject) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return await Axios.put(`${SITE_SUBJECTS_URL}/${subjectId}`, subject, { headers });
}

export const createSiteSubject = async (token, subject) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return await Axios.post(`${SITE_SUBJECTS_URL}`, subject, { headers });
}

export const createMonitorDocument = (token, monitorDoc) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.post(`${MONITOR_DOC_URL}/bulkcreate`, monitorDoc, { headers });
}

export const getSiteDetails = (token, id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.get(`${SITES_URL}/${id}`, { headers });
}

export const getSiteStudies = (token, siteName) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.get(`${SITES_URL}?name=${siteName}`, { headers })
}

export const createQuery = (token, monitorDocQuery) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.post(`${MONITOR_DOC_QUERIES_URL}`, monitorDocQuery, { headers });
}

export const getAllQueries = (token, siteId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.get(`${MONITOR_DOC_QUERIES_URL}?studySite.id=${siteId}&_limit=100`, { headers });
}

export const getCrfSourceDocuments = (token, crfDocId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.get(`${MONITOR_DOC_URL}?linkSourceCRFDoc.id=${crfDocId}`, { headers });
}

export const getAllCrfSourceDocuments = (token, subjectId, visitId, domainData) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.get(`${MONITOR_DOC_URL}?subject.id=${subjectId}&docType=SOURCE&visit.id=${visitId}&domainData=${domainData}`,
        { headers });
}

export const getAllSourceAndIcfDocuments = (token, document) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    let subject = document.subject.id;
    let domainData = document.domainData;
    let visit = document.visit.id;

    return Axios.get(`${MONITOR_DOC_URL}?subject.id=${subject}&domainData=${domainData}&visit.id=${visit}&docType=SOURCE&docType=ICF`, { headers });
}
export const createMonitorReport = (token, report) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.post(`${MONITOR_REPORTS_URL}`, report, { headers });
}

export const getReports = (token, fromDate, toDate) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.get(`${MONITOR_REPORTS_URL}?createdAt_gte=${fromDate}&createdAt_lte=${toDate}`, { headers });
}

export const updateMonitorDocumentQuery = (token, docId, doc) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.put(`${MONITOR_DOC_QUERIES_URL}/${docId}`, doc, { headers });
}

export const createMonitorQueryComments = (token, comments) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.post(`${MONITOR_QUERY_COMMENTS_URL}`, comments, { headers });
}

export const createMonitorDocumentQuery = (token, doc) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.post(`${MONITOR_DOC_QUERIES_URL}`, doc, { headers });
}

export const createMonitorDocVersion = (token, docVersion) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.post(`${MONITOR_DOC_VERSIONS_URL}`, docVersion, { headers });
}

export const updateMonitorDocument = (token, uploadGroup, doc) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.put(`${MONITOR_DOC_URL}/bulkUpdate/${uploadGroup}`, doc, { headers });
}

export const updateCurrentMonitorDocument = (token, docId, doc) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.put(`${MONITOR_DOC_URL}/${docId}`, doc, { headers });
}

export const updateNotificationStatus = (token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return Axios.put(`${NOTIFICATION_STATUSES_UPDATE}`, {}, { headers });
}

export const downloadAuditTrail = async (token, siteId, subjectId, visitId) => {
    const headers = {
        'Content-Type': 'blob',
        Authorization: `Bearer ${token}`
    };
    let params = '?_sort=createdAt:ASC&studySite.id=' + siteId;
    if (subjectId && subjectId !== 'All') {
        params = params.concat('&siteSubject.id=' + subjectId);
    }
    if (visitId && visitId !== 'All') {
        params = params.concat('&siteVisitDetails.id=' + visitId);
    }
    return await Axios.get(`${AUDIT_TRAIL_URL}${params}`, { headers: headers, responseType: 'arraybuffer' });
}

export const forgetPassword = (email) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return Axios.post(`${FORGET_PASSWORD}`, { email: email }, { headers });
}

export const resetPassword = (object) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return Axios.post(`${RESET_PASSWORD}`, object, { headers });
}

export const activateAccount = (code) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return Axios.get(`${ACTIVATE_ACCOUNT}${code}`, { headers });
}