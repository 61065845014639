import React from "react";
import { connect } from 'react-redux';

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Breadcrumb,
    BreadcrumbItem,
    Label,
    Button
} from 'reactstrap';
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// reactstrap validations
import { AvForm, AvField } from 'availity-reactstrap-validation';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin"
import { Routes, Validations, Designations as roles, Roles } from 'constants/index.js';
import { SiteActions, StudyActions, StaffActions } from "actions";
import { updateStaff } from "api";
import SweetAlert from "react-bootstrap-sweetalert";
class StaffDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            role: null,
            requiredSite: false,
            requiredStudy: false,
            isUploading: false
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    componentDidMount() {
        const { token } = this.props;
        this.props.getStaffDetails(token, this.props.match.params.id);
        this.props.getStudies(token);
        this.props.getSites(token);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            const { firstName, lastName, mobileNo, user } = this.props.staff ? this.props.staff : {}
            this.setState({
                firstName: firstName,
                lastName: lastName,
                mobileNo: mobileNo,
                email: user ? user.email : '',
                role: user ? user.role : ''
            })
        }
    }

    handleValidSubmit(event, values) {
        this.setState({ isUploading: true })
        updateStaff(this.props.token, this.props.match.params.id, values).then(res => {
            this.showAlertMessage('User details saved successfully', () => {
                this.props.history.goBack();
            })
        }).catch(e => {
            console.log(e);
            this.setState({ isUploading: false })
        })
    }

    onStatusChange = (el, state) => {
        const staffId = this.props.match.params.id;
        const token = this.props.token;
        updateStaff(token, staffId, { isActive: state }).then(res => {
            this.showAlertMessage('User details saved successfully', () => {
                this.props.getStaffDetails(token, staffId);
                this.setState({ alert: false })
            })
        }).catch(e => console.log(e))
    }

    onChange = (e) => {
        const key = e.target.name;
        const value = e.target.value;

        switch (key) {
            case 'role':
                if (value === Roles.SITE_SCANNER || value === Roles.SITE_SCANNER_RESOLVER) {
                    this.setState({ requiredStudy: true, requiredSite: true })
                } else if (value === null || value === 'null') {
                    this.setState({
                        role: undefined,
                        requiredStudy: false,
                        requiredSite: false
                    });
                } else {
                    this.setState({ requiredStudy: false, requiredSite: false })
                }
                break
            default:
                return
        }
    }

    showAlertMessage = (message, onConfirm = null) => {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ minHeight: '200px' }}
                    closeOnClickOutside={false}
                    allowEscape={false}
                    onConfirm={() => { onConfirm ? onConfirm() : this.setState({ alert: false }) }}
                    confirmBtnBsStyle="info btn-round btn-sm"
                    cancelBtnBsStyle="outline-info btn-round btn-sm"
                    confirmBtnText="Ok"
                >
                    {message}
                </SweetAlert>
            )
        });
    }


    render() {
        const { requiredSite, requiredStudy, firstName, lastName, mobileNo, email, role, isUploading } = this.state;
        const { studies, sites } = this.props;
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.STAFF}`}>User Accounts</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">User Details</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    {this.state.alert}
                    <Row>
                        <Col md="12">
                            <Card style={{ minHeight: '755px' }}>
                                <CardHeader>
                                    <Row>
                                        <Col sm={6} md={6} lg={10}>
                                            <CardTitle tag="h4">User Details</CardTitle>
                                            <Label>Edit and save the details</Label>
                                        </Col>
                                        {
                                            this.props.role === Roles.SYSTEM_ADMIN &&
                                            <Col sm={6} md={6} lg={2}>
                                                <p className="info">Change Status</p>
                                                <Switch name="isActive" value={this.props.staff && this.props.staff.isActive} onColor="info" onChange={(el, state) => this.onStatusChange(el, state)} />
                                            </Col>
                                        }
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleValidSubmit}>
                                        <Col sm="12">
                                            <Row>
                                                <Col md="3">
                                                    <AvField name="firstName" value={firstName} autoComplete="off" placeholder="First Name" label="First Name" type="text" validate={{
                                                        required: { value: true, errorMessage: 'Please enter a first name.' },
                                                        pattern: Validations.ALPHABETS_WITH_SPACES,
                                                        minLength: { value: 4, errorMessage: 'Your first name must be at least 4 characters' },
                                                        maxLength: { value: 50, errorMessage: 'First name should not be more than 50 characters' },
                                                    }} />
                                                    <AvField name="email" value={email} autoComplete="off" placeholder="someone@mail.com" disabled label="Email ID" type="email" required validate={{
                                                        required: { value: true, errorMessage: 'Please enter an email.' },
                                                        email: { value: true, errorMessage: 'Please enter a valid email address.' }
                                                    }} />
                                                    <AvField className="minimal" type="select" name="role" label="Role" required errorMessage='Please select a role.'
                                                        onChange={this.onChange} value={role}>
                                                        <option value='null'>Select</option>
                                                        {
                                                            roles.map((val, i) => {
                                                                return <option key={i} value={val.value}>{val.label}</option>
                                                            })
                                                        }
                                                    </AvField>
                                                    {requiredSite ?
                                                        <AvField className="minimal" type="select" hidden={!requiredSite ? true : false} placeholder="Site" name="site" label="Site" errorMessage='Please select a site.'
                                                            required={requiredSite ? true : false} >
                                                            <option>Select</option>
                                                            {
                                                                sites.map(val => {
                                                                    return <option key={val._id} value={val._id}>{val.name}</option>
                                                                })
                                                            }
                                                        </AvField>
                                                        : ''
                                                    }
                                                </Col>
                                                <Col md="3">
                                                    <AvField name="lastName" value={lastName} autoComplete="off" placeholder="One" label="Last Name" type="text" validate={{
                                                        required: { value: true, errorMessage: 'Please enter a last name.' },
                                                        pattern: Validations.ALPHABETS_WITH_SPACES,
                                                        minLength: { value: 4, errorMessage: 'Your last name must be at least 4 characters' },
                                                        maxLength: { value: 50, errorMessage: 'Your last name must not be more than 50 characters.' }
                                                    }} />
                                                    <AvField name="mobileNo" value={mobileNo} autoComplete="off" placeholder="+447911123456" label="Contact Number" type="text" validate={{
                                                        required: { value: true, errorMessage: 'Please enter a contact number.' },
                                                        pattern: Validations.TELEPHONE,
                                                    }} />
                                                    {requiredStudy ?
                                                        <AvField className="minimal" type="select" placeholder="Study" name="study" label="Study" errorMessage='Please select a study.'
                                                            required={requiredStudy ? true : false}>
                                                            <option>Select</option>
                                                            {
                                                                studies.map(val => {
                                                                    return <option key={val._id} value={val._id}>{val.name}</option>
                                                                })
                                                            }
                                                        </AvField>
                                                        : ''
                                                    }
                                                </Col>
                                            </Row>
                                            <Button color="info" disabled={isUploading} className="btn-round" type="submit" size="sm">
                                                {isUploading ? 'Please wait...' : 'Save Changes'}
                                            </Button>
                                        </Col>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, staff, studies, sites }) => {
    return {
        token: user.token,
        role: user.role ? user.role.name : undefined,
        staff: staff.current,
        studies: studies.data,
        sites: sites.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getStaffDetails: (token, id) => StaffActions.getStaffDetails(dispatch, token, id),
        getStudies: (token) => StudyActions.getStudies(dispatch, token),
        getSites: (token) => SiteActions.getAllSites(dispatch, token),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(StaffDetails);
