import React, { Fragment, useState } from 'react';
import { Label, Row, Col, Button } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { uploadFileToStrapi } from 'commons';
import { checkFileSize } from 'commons/Utils';
import { Validations } from 'constants/index';

const StudyDocuments = (props, context) => {

    const [files, setFiles] = useState(props.processDocs);
    const [uploaded, setUploaded] = useState(false);
    const [title, setTitle] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [description, setDescription] = useState();
    const [invalidFileMessage, setInvalidFileMessage] = useState();
    const [invalidFile, setInvalidFile] = useState(false);

    async function onFileChange(event) {

        const file = event.target.files[0];
        try {
            console.log('checking file');
            const validFile = checkFileSize(file, 10)
            if (validFile.valid) {
                setInvalidFile(false)
                setIsUploading(true)

                const response = await uploadFileToStrapi(props.token, file)
                var doc = { name: title, description: description, url: response.data[0].url }
                files.push(doc)

                await setIsUploading(false)
                await setFiles(files);
                await setUploaded(true);
            } else {
                setInvalidFile(true)
                setInvalidFileMessage(validFile.message)
            }
        } catch (error) {
            alert('Failed file upload');
            console.log(error);
        }
    }

    async function uploadMoreDocuments() {
        setTitle('');
        setDescription('');
        await setUploaded(false);
    }

    const handleSubmit = (event, values) => {
        props.onStudyDocumentsSubmit(event, files);
    }

    const onRemoveDocument = (val, index) => {
        props.onRemoveStudyDocument(val, index)
    }

    return (
        <Fragment>
            <Row>
                <Col sm={5}>
                    <h6>Clinical Trial Documents</h6>
                    <AvForm onValidSubmit={handleSubmit}
                    // model={{ name: 'Instructions', description: 'Description of the document' }}
                    >
                        {
                            !uploaded ?
                                <>
                                    <AvGroup>
                                        {
                                            files.map((val, index) => {
                                                return (
                                                    <Row key={index}>
                                                        <i className="fa fa-file mt-2" aria-hidden="true" />
                                                        <Col>
                                                            <p>{val.name}</p>
                                                            <Label>{val.description}</Label>
                                                        </Col>
                                                        <i className="fa fa-times mt-2" aria-hidden="true" onClick={() => onRemoveDocument(val, index)} />
                                                    </Row>
                                                );
                                            })
                                        }
                                    </AvGroup>
                                    <AvField name="name" onChange={(e) => setTitle(e.target.value)} autoComplete="off" label="Document Name" type="text" validate={{
                                        required: { value: true, errorMessage: 'Please enter document name.' },
                                        pattern: Validations.ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                        minLength: { value: 4, errorMessage: 'Document name must be at least 4 characters' },
                                        maxLength: { value: 50, errorMessage: 'Document name must not be more than 50 characters.' },
                                    }} />
                                    <AvField name="description" onChange={(e) => setDescription(e.target.value)} autoComplete="off" label="Description" type="textarea" validate={{
                                        required: { value: true, errorMessage: 'Please enter document description.' },
                                        // pattern: Validations.MULTI_LINE_ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                        minLength: { value: 6, errorMessage: 'Document description must be at least 6 characters' },
                                        maxLength: { value: 200, errorMessage: 'Document description must not be more than 200 characters.' },
                                    }} />
                                    {
                                        title && description && title.trim().length > 4 && description.trim().length > 6?
                                            <>
                                                <AvGroup>
                                                    <i className=" fa fa-arrow-up text-info" aria-hidden="true" style={{ cursor: 'pointer' }} />
                                                    <Label htmlFor="url" className="text-info" style={{ marginLeft: '10px', cursor: 'pointer' }}> Upload Document</Label>
                                                    <AvInput type="file" name="url" style={{ cursor: 'pointer' }} onChange={onFileChange} validate={{
                                                        required: true,
                                                    }} />
                                                    <AvFeedback name="file">Please select a Document.</AvFeedback>
                                                </AvGroup>
                                                {invalidFile ? <p><i className="fa fa-exclamation-triangle" style={{ color: "orange", paddingRight: '10px' }} aria-hidden="true"></i>{invalidFileMessage}</p> : null}
                                            </>
                                            : ''
                                    }
                                </>
                                :
                                <>
                                    <AvGroup>
                                        {files && files.map((filesData, index) => {
                                            return (
                                                <Row>
                                                    <i className="fa fa-file mt-2" aria-hidden="true" />
                                                    <Col>
                                                        <Label>{filesData.name}</Label>
                                                        <div className="progress " style={{ height: '3px' }} >
                                                            <div style={{ width: '20px' }} className="progress-bar bg-info"></div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </AvGroup>
                                    <AvGroup>
                                        <i className=" fa fa-arrow-up text-info" aria-hidden="true" />
                                        <Button className="btn-neutral text-info" style={{ paddingLeft: '8px' }} onClick={uploadMoreDocuments}> Upload More Documents</Button>
                                    </AvGroup>
                                </>
                        }
                        <Button color="info" hidden={files.length <= 0} style={{ marginLeft: '20px' }} disabled={isUploading} className="btn-round" size="sm" type="submit">
                            {isUploading ? 'Please wait...' : 'Next'}
                        </Button>
                    </AvForm>
                </Col>
            </Row>
        </Fragment>
    );
}

StudyDocuments.contextTypes = {}

export default StudyDocuments