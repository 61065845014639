import React from 'react';

const Checkbox = ((props) => {

    var linkStyle;
    if (props.disabled) {
        linkStyle = { }
    } else {
        linkStyle = { cursor: 'pointer' }
    }

    return (
        <div>
            <input type="checkbox" hidden disabled={props.disabled} checked={props.checked} id={props.id} className="checkbox-t" name="checkbox" onChange={props.onCheckedChange} />
            <label htmlFor={props.id} style={linkStyle} />
        </div>
    );
});

export default Checkbox;