import React from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
} from "reactstrap";
// reactstrap validations
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import SweetAlert from "react-bootstrap-sweetalert";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin"
import ImageUpload from "components/Custom/ImageUpload"
import { createSponsor } from "api/index"
import { Routes, Validations } from 'constants/index.js';
import cogoToast from "cogo-toast";

//company
class SponsorAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            logo: undefined,
            alert: null,
            isUploading: false
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.props.history.goBack()
        }
    }

    handleValidSubmit(event, values) {
        this.setState({ isUploading: true });
        const { logo } = this.state;
        const { name, contactNumber, address, email, contactName, designation, } = values;
        const sponsor = {
            name: name,
            contactNumber: contactNumber,
            address: address,
            contactName: contactName,
            designation: designation,
            email: email,
            logo: logo
        };
        console.log(sponsor,"sponsor")
        createSponsor(this.props.token, sponsor)
            .then(res => {
                this.showAlertMessage('success', 'Company created successfully.', () => {
                    this.props.history.push(`${Routes.SPONSORS}`)
                })
            }).catch(error => {
                this.setState({ isUploading: false });
                if (error.response && error.response.status === 400 && error.response.data.message.name) {
                    this.showAlertMessage('warning', error.response.data.message.name.message)
                } else {
                    this.showAlertMessage('error', 'Failed Company creation');
                    console.log(error);
                }
            });
        if (logo === '') {
            cogoToast.error('Image should not be empty')
        }
    }

    onLogoLoaded = (file) => {
        this.setState({
            logo: file.url,
            logoId: file.id
        })
    }

    onLogoRemoved = () => {
        this.setState({
            logo: '',
            logoId: ''
        })
    }

    showAlertMessage = (type, message, onConfirm = undefined) => {
        this.setState({
            alert: (
                <SweetAlert
                    success={type === 'success' ? true : false}
                    warning={type === 'warning' ? true : false}
                    error={type === 'error' ? true : false}
                    style={{ minHeight: '200px' }}
                    closeOnClickOutside={false}
                    allowEscape={false}
                    onConfirm={() => { onConfirm ? onConfirm() : this.setState({ alert: false }) }}
                    confirmBtnBsStyle="info btn-round btn-sm"
                    cancelBtnBsStyle="outline-info btn-round btn-sm"
                    confirmBtnText="Ok"
                >
                    <h6>Alert</h6>
                    {message}
                </SweetAlert>
            )
        });
    }

    render() {
        const { isUploading } = this.state;
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.SPONSORS}`}>Company</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Add New Company</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    {this.state.alert}
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Create New Company</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleValidSubmit}>
                                        <Col sm="12">
                                            <Row>
                                                <Col md="3">
                                                    <AvField name="name" autoComplete="off" label="Company's Name" type="text" validate={{
                                                        required: { value: true, errorMessage: 'Please enter a company name.' },
                                                        pattern: Validations.ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                                        minLength: { value: 6, errorMessage: 'Company name must be at least 6 characters' },
                                                        maxLength: { value: 50, errorMessage: 'Company name should not be more than 50 characters' },
                                                    }} />
                                                    <AvField name="address" autoComplete="off" label="Corporate Address" type="textarea" validate={{
                                                        required: { value: true, errorMessage: 'Please enter an address.' },
                                                        pattern: Validations.MULTI_LINE_ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                                        minLength: { value: 4, errorMessage: 'Address must be at least 4 characters.' },
                                                        maxLength: { value: 100, errorMessage: 'Address must not be more than 100 characters.' }
                                                    }} />
                                                    <AvField name="email" autoComplete="off" label="Email ID" type="email" required validate={{
                                                        required: { value: true, errorMessage: 'Please enter an email.' },
                                                        email: { value: true, errorMessage: 'Please enter a valid email address.' },
                                                        maxLength: { value: 50, errorMessage: 'Email must be not more than 50 characters.' },
                                                    }} />
                                                </Col>
                                                <Col md="3">
                                                    <AvField name="contactNumber" autoComplete="off" label="Contact Number" placeholder="+447911123456" type="text" validate={{
                                                        required: { value: true, errorMessage: 'Please enter a contact number.' },
                                                        pattern: Validations.TELEPHONE,
                                                    }} />
                                                    <AvField name="contactName" autoComplete="off" label="Contact Person" type="text" validate={{
                                                        required: { value: true, errorMessage: 'Please enter a contact name.' },
                                                        pattern: Validations.ALPHABETS_WITH_SPACES,
                                                        minLength: { value: 4, errorMessage: 'Contact name must be at least 4 characters.' },
                                                        maxLength: { value: 25, errorMessage: 'Contact name must be not more than 25 characters.' },
                                                    }} />
                                                    <AvField name="designation" autoComplete="off" label="Designation" type="text" validate={{
                                                        required: { value: true, errorMessage: 'Please enter a designation.' },
                                                        pattern: Validations.ALPHABETS_WITH_SPACES,
                                                        minLength: { value: 4, errorMessage: 'Designation must be at least 4 characters.' },
                                                        maxLength: { value: 20, errorMessage: 'Designation must not be more than 20 characters.' }
                                                    }} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="3">
                                                    <AvGroup>
                                                        <Label>Company's Logo</Label>
                                                        <ImageUpload token={this.props.token} acceptType="image/*" onFileLoaded={this.onLogoLoaded} onFileRemoved={this.onLogoRemoved} />
                                                    </AvGroup>
                                                </Col>
                                            </Row>
                                            <Button color="info" type="submit" className="btn-round" disabled={isUploading}>
                                                {isUploading ? 'Please wait..' : 'Create Company'}
                                            </Button>
                                        </Col>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return {
        token: user.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SponsorAdd);