import React from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
    Row,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Col,
    Breadcrumb,
    BreadcrumbItem,
    Button
} from "reactstrap";
// reactstrap validations
import { AvForm, AvField } from 'availity-reactstrap-validation';
import cogoToast from 'cogo-toast';
// core components
import { RenderImage } from "components";
import defaultImage from "assets/img/image_placeholder.jpg";
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin"
import { Routes, Validations } from 'constants/index.js';
import { SponsorActions } from "actions";
import { uploadFileToStrapi } from "commons";
import { updateSponsor } from "api";
import { checkFileSize } from "commons/Utils";
import SweetAlert from "react-bootstrap-sweetalert";

class SponsorDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imagePreviewUrl: defaultImage,
            isUploading:false
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.fileInput = React.createRef();
    }

    componentDidMount() {
        this.props.getSponsorDetails(this.props.token, this.props.match.params.id)
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            const { name, contactNumber, address, contactName, designation, email, logo } = this.props.data ? this.props.data : {}
            this.setState({
                name: name,
                contactNumber: contactNumber,
                address: address,
                email: email,
                contactName: contactName,
                designation: designation,
            })

            if (logo) {
                this.setState({ imagePreviewUrl: logo })
            }
        }
    }

    handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        const validFile = checkFileSize(file, 2);
        if (validFile.valid) {
            // upload file
            uploadFileToStrapi(this.props.token, file).then(res => {
                const data = res.data[0];
                const fileData = { url: `${data.url}`, id: data._id }
                this.setState({ fileData: fileData })
            }).catch(e => console.log(e))

            reader.onloadend = () => {
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result,
                });
            };
            if (file) {
                reader.readAsDataURL(file);
            }
        } else {
            cogoToast.warn(validFile.message)
        }
    };

    handleValidSubmit(event, values) {
        this.setState({ isUploading: true });
        const { fileData } = this.state;
        const { name, contactNumber, address, contactName, designation, email } = values;
        const sponsor = { name: name, contactNumber: contactNumber, address: address, contactName: contactName, designation: designation, email: email }
        if (fileData && fileData.url) {
            sponsor["logo"] = fileData.url;
        }
        updateSponsor(this.props.token, this.props.match.params.id, sponsor)
            .then(res => {
                this.showAlertMessage('Company details saved successfully.', () => {
                    this.props.history.goBack(); 
                })
            }).catch(e => {
                this.setState({ isUploading: false });
                console.log(e)
                cogoToast.error('Failed to update company details.')
            })
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleClick = () => {
        this.fileInput.current.click();
    };

    showAlertMessage = (message, onConfirm = undefined) => {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ minHeight: '200px' }}
                    closeOnClickOutside={false}
                    allowEscape={false}
                    onConfirm={() => { onConfirm ? onConfirm() : this.setState({ alert: false }) }}
                    confirmBtnBsStyle="info btn-round btn-sm"
                    cancelBtnBsStyle="outline-info btn-round btn-sm"
                    confirmBtnText="Ok"
                >
                    {message}
                </SweetAlert>
            )
        });
    }

    render() {

        const { name, contactNumber, address, email, contactName, designation,isUploading} = this.state;

        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.SPONSORS}`}>Companies</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Company Details</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    {this.state.alert}
                    <Row>
                        <Col md="12">
                            <Card style={{ minHeight: '755px' }}>
                                <CardHeader>
                                    <Row>
                                        <Col sm={6} md={6} lg={10}>
                                            <CardTitle tag="h4">Company Details</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={3}>
                                            <div className="fileinput text-center">
                                                <input
                                                    type="file"
                                                    accept={this.props.acceptType}
                                                    onChange={this.handleImageChange}
                                                    ref={this.fileInput}
                                                />
                                                <div className={"thumbnail"} >
                                                    <RenderImage src={this.state.imagePreviewUrl} alt="." onClick={() => this.handleClick()} style={{ width: '100px', height: '100px' }} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <AvForm onValidSubmit={this.handleValidSubmit}>
                                        <Row>
                                            <Col sm={3}>
                                                <AvField name="name" value={name} autoComplete="off" label="Company Name" type="text" validate={{
                                                    required: { value: true, errorMessage: 'Please enter a company name.' },
                                                        pattern: Validations.ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                                        minLength: { value: 6, errorMessage: 'Company name must be at least 6 characters' },
                                                        maxLength: { value: 50, errorMessage: 'Company name should not be more than 50 characters' },
                                                }}
                                                    onChange={this.onChange} />
                                                <AvField name="address" value={address} autoComplete="off" label="Corporate Address" type="textarea" validate={{
                                                    required: { value: true, errorMessage: 'Please enter an address.' },
													pattern: Validations.MULTI_LINE_ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                                    minLength: { value: 4, errorMessage: 'Your address must be at least 4 characters.' },
                                                    maxLength: { value: 256, errorMessage: 'Your address must not be more than 100 characters.' }
                                                }} />
                                                <AvField name="email" value={email} autoComplete="off" label="Email ID" type="email" required validate={{
                                                    required: { value: true, errorMessage: 'Please enter an email.' },
                                                    email: { value: true, errorMessage: 'Please enter a valid email address.' }
                                                }}
                                                    onChange={this.onChange} />
                                            </Col>
                                            <Col sm={3}>
                                                <AvField name="contactNumber" value={contactNumber} placeholder="+447911123456" autoComplete="off" label="Contact Number" type="text"  validate={{
                                                        required: { value: true, errorMessage: 'Please enter a contact number.' },
                                                        pattern: Validations.TELEPHONE,
                                                    }}
                                                    onChange={this.onChange} />
                                                <AvField name="contactName" value={contactName} autoComplete="off" label="Contact Person" type="text" validate={{
                                                    pattern: Validations.ALPHABETS_WITH_SPACES,
                                                    required: { value: true, errorMessage: 'Please enter a contact name.' },
                                                    minLength: { value: 4, errorMessage: 'Your contact name must be at least 4 characters.' },
                                                }}
                                                    onChange={this.onChange} />
                                                <AvField name="designation" value={designation} autoComplete="off" label="Designation" type="text" validate={{
                                                    required: { value: true, errorMessage: 'Please enter a designation.' },
													pattern: Validations.ALPHABETS_WITH_SPACES,
                                                    minLength: { value: 4, errorMessage: 'Designation must be at least 4 characters.' },
                                                    maxLength: { value: 20, errorMessage: 'Designation must not be more than 20 characters.' }
                                                }}
                                                    onChange={this.onChange} />
                                            </Col>
                                        </Row>
                                        <Button className="btn-round" color="info" size="sm" disabled={isUploading} type="submit">
                                        {isUploading ? 'Please wait..' : 'Update Details'}</Button>
                                        <Button className="btn-round" color="info" outline size="sm" style={{ marginLeft: '20px' }} href={`${Routes.SPONSORS}`}>Cancel</Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, sponsors }) => {
    return {
        token: user.token,
        data: sponsors.current
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSponsorDetails: (token, id) => SponsorActions.getSponsorDetails(dispatch, token, id)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SponsorDetails);