import React from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb
} from "reactstrap";

// reactstrap validations
import { AvForm, AvField } from 'availity-reactstrap-validation';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin"
import { Routes, Designations, Roles, DEFAULT_PASSWORD, Validations } from 'constants/index.js';
import { SiteActions, StudyActions } from "actions";
import { createStaff } from "api";
import cogoToast from "cogo-toast";
class StaffAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            role: null,
            requiredSite: false,
            requiredStudy: false,
            employeeType: 'HOSPITAL_STAFF',
            isUploading: false
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const token = this.props.token
        this.props.getStudies(token, undefined, 0, -1);
        this.props.getSites(token);
    }

    handleValidSubmit(event, values) {
        this.setState({ isUploading: true })
        var staffType = 'HOSPITAL_STAFF';
        if ([Roles.SITE_SCANNER_RESOLVER, Roles.SITE_SCANNER].includes(values.role)) {
            staffType = 'HOSPITAL_STAFF';
        } else {
            staffType = 'EMPLOYEE';
        }
    
        const staff = { firstName: values.firstName, lastName: values.lastName, email: values.email, mobileNo: values.mobileNo, role: values.role, staffType: staffType, password: DEFAULT_PASSWORD }
        createStaff(this.props.token, staff).then(res => {
            const { hide } = cogoToast.success('Hospital Staff added successfully.', {
                  onClick: () => {
                    hide();
                  },
                  position:"top-right",
                  hideAfter:3
                });
            this.props.history.push(`${Routes.STAFF}`);
        }).catch(e => console.log(e))
    }

    onChange = (e) => {
        const key = e.target.name;
        const value = e.target.value;

        switch (key) {
            case 'role':
                if (value === Roles.SITE_SCANNER || value === Roles.SITE_SCANNER_RESOLVER) {
                    this.setState({ requiredStudy: true, requiredSite: true })
                } else if (value === null || value === 'null') {
                    this.setState({
                        role: undefined,
                        requiredStudy: false,
                        requiredSite: false
                    });
                } else {
                    this.setState({ requiredStudy: false, requiredSite: false })
                }
                break;
            default:
                return
        }


    }

    render() {
        const { requiredSite, requiredStudy, role,isUploading } = this.state;
        const { studies, sites } = this.props;
        console.log(isUploading,"isUploading")
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.STAFF}`}>User Accounts</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Add New User</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card style={{ minHeight: '755px' }}>
                                <CardHeader>
                                    <CardTitle tag="h4">Add New User</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleValidSubmit}>
                                        <Col sm="12">
                                            <Row>
                                                <Col md="3">
                                                    <AvField name="firstName" autoComplete="off" placeholder="First Name" label="First Name" type="text" validate={{
                                                        required: { value: true, errorMessage: 'Please enter a first name.' },
                                                        pattern: Validations.ALPHABETS_WITH_SPACES,
                                                        minLength: { value: 4, errorMessage: 'First name must be at least 4 characters' },
                                                        maxLength: { value: 50, errorMessage: 'First name must be not more than 50 characters.' },
                                                    }} />
                                                    <AvField name="email" autoComplete="off" placeholder="someone@mail.com" label="Email ID" type="email" required validate={{
                                                        required: { value: true, errorMessage: 'Please enter an email.' },
                                                        email: { value: true, errorMessage: 'Please enter a valid email address.' },
                                                        maxLength: { value: 50, errorMessage: 'Email must be not more than 50 characters.' },
                                                    }} />
                                                    <AvField className="minimal" type="select" name="role" label="Role" required errorMessage='Please select a role.'
                                                        onChange={this.onChange} value={role}>
                                                        <option value='null'>Select</option>
                                                        {
                                                            Designations.map((val, i) => {
                                                                return <option key={i} value={val.value}>{val.label}</option>
                                                            })
                                                        }
                                                    </AvField>
                                                    {requiredSite ?
                                                        <AvField className="minimal" type="select" hidden={!requiredSite ? true : false} placeholder="Site" name="site" label="Site" errorMessage='Please select a site.'
                                                            required={requiredSite ? true : false} >
                                                            <option>Select</option>
                                                            {
                                                                sites.map(val => {
                                                                    return <option key={val._id} value={val._id}>{val.name}</option>
                                                                })
                                                            }
                                                        </AvField>
                                                        : ''
                                                    }
                                                </Col>
                                                <Col md="3">
                                                    <AvField name="lastName" autoComplete="off" placeholder="Last Name" label="Last Name" type="text" validate={{
                                                        required: { value: true, errorMessage: 'Please enter a last name.' },
                                                        pattern: Validations.ALPHABETS_WITH_SPACES,
                                                        minLength: { value: 4, errorMessage: 'Your last name must be at least 4 characters' },
                                                        maxLength: { value: 50, errorMessage: 'Your last name must be not more than 50 characters.' },
                                                    }} />
                                                    <AvField name="mobileNo" autoComplete="off" placeholder="+447911123456" label="Contact Number" type="text" validate={{
                                                        required: { value: true, errorMessage: 'Please enter a contact number.' },
                                                        pattern: Validations.TELEPHONE,
                                                    }} />
                                                    {requiredStudy ?
                                                        <AvField className="minimal" type="select" placeholder="Study" name="study" label="Study" errorMessage='Please select a study.'
                                                            required={requiredStudy ? true : false}>
                                                            <option>Select</option>
                                                            {
                                                                studies.map(val => {
                                                                    return <option key={val._id} value={val._id}>{val.name}</option>
                                                                })
                                                            }
                                                        </AvField>
                                                        : ''
                                                    }
                                                </Col>
                                            </Row>
                                            <Button color="info" disabled={isUploading} className="btn-round" type="submit">
                                                {isUploading ? 'Please wait...' : 'Add User'}
                                            </Button>
                                        </Col>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, studies, sites }) => {
    return {
        token: user.token,
        studies: studies.data,
        sites: sites.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getStudies: (token, params, start, limit) => StudyActions.getStudies(dispatch, token, params, start, limit),
        getSites: (token) => SiteActions.getAllSites(dispatch, token),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(StaffAdd);