const initialState = {
    token: '',
    isAuthenticated: false
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TOKEN':
            return {
                ...state,
                ...action.response.user,
                token: action.response.jwt,
                isAuthenticated: true,
                socket : action.response.socket
            }
        case 'GET_TOKEN_ERROR':
            return {
                isAuthenticated: false,
                token: '',
                errorMessage: action.response,
            };
        case 'GET_NOTIFICATIONS':
            return {
                ...state,
                notifications: action.response
            }
        case 'GET_ROLES':
            return {
                ...state,
                ...action.response
            };
        case 'GET_MY_SITES':
            return {
                ...state,
                mySites: action.response
            }
        case 'UPDATE_PROFILE':
            return {
                ...state,
                staffProfile: action.response
            }
        // case 'STUDY_SELECTED':
        //     return {
        //         ...state,
        //         myStudy: action.response
        //     }
        // case 'SITE_SELECTED':
        //     return {
        //         ...state,
        //         mySite: action.response
        //     }
        default:
            return state;
    }
};

export default user;