import React from 'react';
import { Button } from 'reactstrap';

export const userTableColumns = (start, action, studyUserAccess = false) => {
    
    let columns = [
        {
            dataField: 'id',
            text: 'S.No',
            formatter: (value, row, index, field) => {
                return (start + index + 1);
            }
        },
        {
            dataField: 'name',
            text : 'Name',
            isDummy : true,
            formatter: (value, row, index, field) => {
                return `${row.staffProfile && row.staffProfile.firstName} ${row.staffProfile && row.staffProfile.lastName}`;
            }
        },
        {
            dataField: 'role',
            text: 'Role',
            formatter: (value, row, index, field) => {
                return row.role.name
            }
        },
        {
            dataField: 'email',
            text : 'Email ID',
        },
        {
            dataField: 'mobileNo.',
            text : 'Mobile No.',
            isDummy : true,
            formatter: (value, row, index, field) => {
                return row.staffProfile && row.staffProfile.mobileNo;
            }
        },
    ];
    
    if (action) {
        columns.push ({
            dataField: 'isAccess',
            text: 'Status',
            formatter: (value, row, index, field) => {
                return value ? 'Active' : 'Inactive';
            }
        });
        
        columns.push({
            dataField: 'action',
            text : 'Action',
            isDummy : true,
            formatter: (value, row, index, field) => {
                if (row.accessLevel !== 'CLINICAL_STUDY' || studyUserAccess) {
                    return (<Button color="primary" className="btn-round" size="sm" outline onClick={() => action(row)}>{row.isAccess ? "Revoke Access" : "Grant Access"}</Button>);
                } else {
                    return null;
                }
            }
        });
    }
    
    return columns;
}