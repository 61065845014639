import { combineReducers } from 'redux';
import user from './user';
import dashboard from './dashboard';
import sponsors from './sponsors';
import studies from './studies';
import sites from './sites';
import staff from './staff';
import documents from './documents';
import queries from './queries';

const RootReducer = combineReducers({
    user,
    dashboard,
    sponsors,
    studies,
    sites,
    staff,
    documents,
    queries,
});

export default RootReducer;