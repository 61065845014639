import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navbar, RootSidebar } from "components"
import { withRouter } from "react-router-dom";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications
import NotificationAlert from "react-notification-alert";
import { StudyActions, SiteActions } from 'actions'
import { Routes, Roles } from "constants/index.js";
import { ChangeStudySite } from "../views/Protocol/Components";
import { updateStaff } from "api/index";

class DashboardLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: "blue",
            accepted: false,
            sites: [],
            selectedStudy : null,
            selectedSite : null,
        }
    }

    notificationAlert = React.createRef();

    componentDidMount() {
        if (this.props.user && this.props.user.staffProfile) {
            this.props.getStudiesByStaff(this.props.token, this.props.user.staffProfile._id, this.props.user.role.name)
        }
    }

    static getDerivedStateFromProps(props, state) {
        var newstate = state;

        if (props.study !== state.study && props.studies) {
            const sites = props.studies && props.study && props.studies.filter(val => val.clinicalStudy._id === props.study._id);
            newstate['study'] = props.study
            if (props.role === Roles.SITE_SCANNER_RESOLVER || props.role === Roles.SITE_SCANNER)
                newstate['sites'] = sites
        }

        if (props.activeSites) {
            newstate['sites'] = props.activeSites;
        }

        return newstate;
    }

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    }

    onSearchFocus = (e) => {
        this.props.history.push(`${Routes.SEARCH}`)
    }

    onStudySelect = async (study) => {
        this.props.getStudyDetails(this.props.token, study.clinicalStudy._id);
        switch (this.props.role) {
            case Roles.SITE_SCANNER_RESOLVER:
            case Roles.SITE_SCANNER:
            {
                const sites = this.props.studies && study && this.props.studies.filter(val => val.clinicalStudy._id === study.clinicalStudy._id);
                    this.setState({ sites: sites, selectedStudy: study.clinicalStudy, selectedSite: study.studySite });
                    this.props.getSiteDetails(this.props.token, study.studySite._id);
                if (this.state.accepted) {
                    // update default study site for the user
                    await updateStaff(this.props.token, 
                        this.props.user.staffProfile.id,
                        {defaultStudySite : study.studySite._id});
                            
                    // window.location.reload();
                    this.props.history.push(`${Routes.DASHBOARD}`)
                    this.hideAlert();
                }
                break;
            }
            case Roles.CCRA:
                const sites = this.props.studies && study && this.props.studies.filter(val => val.clinicalStudy._id === study.clinicalStudy._id);
                this.setState({
                    sites : sites,
                    selectedStudy : study.clinicalStudy,
                    selectedSite : null
                });
                break;
            case Roles.PM:
            case Roles.DM:
            case Roles.CSM:
            case Roles.DE:
                this.props.getActiveSites(this.props.token, study.clinicalStudy._id);
                this.setState({
                    sites : this.props.activeSites,
                    selectedStudy : study.clinicalStudy,
                    selectedSite : null,
                 });
                break;
            default:
        }
    }

    onSiteSelect = async (site) => {
        switch (this.props.role) {
            case Roles.CCRA:
                this.setState({selectedStudy : site.clinicalStudy, selectedSite : site.studySite});
                this.props.getSiteDetails(this.props.token, site.studySite._id);
                if (this.state.accepted) {
                    // update default study site for the user
                    await updateStaff(this.props.token, 
                        this.props.user.staffProfile.id,
                        {defaultStudySite : site.studySite._id});
                                
                    // window.location.reload();
                    this.props.history.push(`${Routes.DASHBOARD}`)
                    this.hideAlert();
                }
                break;
            case Roles.DM:
            case Roles.CSM:
            case Roles.DE:
            case Roles.PM:
                this.setState({selectedStudy : site.clinicalStudy, selectedSite : site});
                this.props.getSiteDetails(this.props.token, site._id);
                
                if (this.state.accepted) {
                    // update default study site for the user
                    await updateStaff(this.props.token, 
                        this.props.user.staffProfile.id,
                        {defaultStudySite : site._id});
                    // window.location.reload();
                    this.props.history.push(`${Routes.DASHBOARD}`)
                    this.hideAlert();
                }
                break;
            default:
                break;
        }
    }

    onAcceptGuidelines = async (accepted) => {
        this.setState({ accepted });
        if (accepted && this.state.selectedSite) {
            // update default study site for the user
            await updateStaff(this.props.token, 
                this.props.user.staffProfile.id,
                {defaultStudySite : this.state.selectedSite._id});
            
            if (this.props.location.pathname.includes('studies')) {
                if (this.props.match.params.id !== this.state.selectedStudy.id) {
                    this.props.history.push(`${Routes.STUDIES}/${this.state.selectedStudy.id}`);
                } else {
                    this.props.history.push(`${Routes.DASHBOARD}`)
                }
            } else if (this.props.location.pathname.includes('dashboard')){
                // if currently on dashboard page, reload the same page
                window.location.reload();
            } else {
                this.props.history.push(`${Routes.DASHBOARD}`)
            }
 
            this.hideAlert();
        }

    }

    onStudyChange = () => {
        this.setState({ alert : true, selectedStudy : null, selectedSite : null, accepted : false});
    }

    render() {
        const { children, role, user } = this.props;
        const backgroundColor = "yellow"
        return (
            <div className="wrapper" style={{overflow:"hidden"}}>
                <NotificationAlert ref={this.notificationAlert} />
                <RootSidebar
                    {...this.props}
                    role={role}
                    user={user}
                    backgroundColor={this.state.backgroundColor}
                />
                <div className="main-panel" ref={this.mainPanel}>
                    <Navbar
                        onSearchFocus={this.onSearchFocus}
                        onStudyChange={this.onStudyChange}
                        onSearchChange={this.props.onSearchChange}
                        searchFocus={this.props.searchFocus}
                    />
                    <>
                        {
                            this.state.alert &&
                            <SweetAlert
                                title={''}
                                showConfirm={false}
                                // confirmBtnStyle={{ display: 'none' }}
                                // showCloseButton
                                onCancel={this.hideAlert}>
                                <div style={{ maxHeight: '500px', margin: '20px' }}>
                                    <ChangeStudySite
                                        role = {this.props.role}
                                        onStudySelect={this.onStudySelect}
                                        onSiteSelect={this.onSiteSelect}
                                        onAcceptGuidelines={this.onAcceptGuidelines}
                                        studies={this.props.studies}
                                        sites={this.state.sites} 
                                        site={this.state.selectedSite}
                                        study={this.state.selectedStudy}/>
                                </div>
                            </SweetAlert>
                        }
                        {children}
                    </>
                </div>
            </div>
        );
    }
}

DashboardLayout.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    title: PropTypes.string,
    role: PropTypes.string
};

const mapStateToProps = ({ user, studies, sites }) => {
    return {
        token: user.token,
        role: user.role ? user.role.name : '',
        user: user,
        studies: studies.myStudies,
        study: studies.current,
        site: sites.current,
        activeSites: studies.activeSites,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getStudiesByStaff: (token, staffId, role) => StudyActions.getStudiesByStaff(dispatch, token, staffId, role),
        getStudyDetails: (token, studyId) => StudyActions.getStudyDetails(dispatch, token, studyId),
        getSiteDetails: (token, siteId) => SiteActions.getSiteDetails(dispatch, token, siteId),
        getActiveSites: (token, studyId) => StudyActions.getActiveSites(dispatch, token, studyId)
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardLayout));