import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    BreadcrumbItem,
    Row,
    Col,
    Label,
    Breadcrumb,
} from "reactstrap";
import _ from 'lodash';

// core components
import DashboardLayout from "layouts/Admin";
import { Routes, Roles } from 'constants/index.js';
import { DocumentActions, DashboardActions, SiteActions } from "actions";
import { RemoteTable, PanelHeader } from "components";
import { PAGE_SIZE } from "api/list";
import { DocumentFields } from './Components';

class Documents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            item: '',
            page: 1,
        }
    }

    async componentDidMount() {

        const siteId = this.props.site ? this.props.site._id : undefined;
        const token = this.props.token;

        if (siteId) {
            await this.props.getMyMetrics(token, siteId);
            await this.props.getSiteSubjects(token, siteId);
            await this.props.getSiteVisitDetails(token, siteId);
        }
        var params = [`studySite.id=${siteId}`]

        switch (this.props.role) {
            case Roles.DM:
            case Roles.DE:
                params.push(`isCCRAVerified=true`);
                params.push(`docType=CRF`);
                break;
            case Roles.PM:
            case Roles.CSM:
                params.push(`isCCRAVerified=true`);
                params.push(`docType=SOURCE`);
                params.push(`docType=CRF`);
                break;
            default:
                break;
        }
        await this.props.getDocumentsCount(token, _.join(params, '&'));
        await this.props.getDocuments(token, _.join(params, '&'), 0, PAGE_SIZE);
    }

    static getDerivedStateFromProps(props, state) {

        var newstate = state;
        // Typical usage (don't forget to compare props):
        if (props.documents.length !== state.totalSize) {
            newstate['totalSize'] = props.documents.length;
        }

        if (props.visits !== state.visits) {
            newstate['visits'] = props.visits.map(val => {
                return { value: val.name, label: val.name }
            })
        }

        if (props.subjects !== state.subjects) {
            newstate['subjects'] = props.subjects.map(val => {
                return { value: val.uniqueCode, label: val.uniqueCode }
            })
        }
        if (props.site && props.site.domainData !== state.domainData) {
            newstate['domainData'] = props.site.domainData.map(val => {
                return { value: val, label: val }
            })
        }
        if (props.totalSize !== state.totalSize) {
            newstate['totalSize'] = props.totalSize
        }
        return newstate;
    }

    onItemClick = (e, row, rowIndex) => {
        switch (this.props.role) {
            case Roles.SITE_SCANNER_RESOLVER:
            case Roles.SITE_SCANNER:
            case Roles.CCRA:
            case Roles.DM:
            case Roles.DE:
            case Roles.PM:
            case Roles.CSM:
                this.props.history.push(`${Routes.DOCUMENTS}/${row.uploadGroup}`);
                break;
            default:
                break;
        }

    }

    handleClickDisplay(e, index) {
        e.preventDefault();
        this.setState({ activePage: index })
    }

    handleTableChange = (type, { filters, page, sizePerPage }) => {

        const currentIndex = (page - 1) * sizePerPage;
        this.setState({ page })

        var params = [`studySite.id=${this.props.site.id}`]
        switch (this.props.role) {
            case Roles.DM:
            case Roles.DE:
                params.push(`isCCRAVerified=true`);
                params.push(`docType=CRF`);
                break;
            case Roles.PM:
            case Roles.CSM:
                params.push(`isCCRAVerified=true`);
                params.push(`docType=SOURCE`);
                params.push(`docType=CRF`);
                break;
            default:
                break;
        }
        Object.keys(filters).map((key) => {
            params.push(`${key}=${filters[key].filterVal}`)
            // fix warning
            return true;
        })
        
        if (Object.keys(filters).length) {
            this.props.getDocumentsCount(this.props.token, _.join(params, '&'));
        }
        this.props.getDocuments(this.props.token, _.join(params, '&'), currentIndex, sizePerPage);

    }

    render() {
        const { documents, role, metrics } = this.props;
        const { page, totalSize } = this.state;
        const verifiedDocs = metrics ? metrics.documentMetrics.totalVerified : '';
        const submittedDocs = metrics ? (metrics.documentMetrics.totalUploaded - verifiedDocs) : '';
        
        // convert de1 and de2 status values to pending / completed
       /* let tableData = documents.map( doc => {
                            doc.de1CompletedBy = doc.de1CompletedBy !== doc.numberOfDocuments;
                            doc.de2CompletedBy = doc.de2CompletedBy !== doc.numberOfDocuments;
                            doc.deCompletedBy = doc.deCompletedBy !== doc.numberOfDocuments;
                            return doc;
                        });*/
                        
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.ADMIN_DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Documents</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <CardTitle tag="h4" style={{ fontWeight: '500' }}>
                                                Documents
                                            </CardTitle>
                                            <Row className="px-3" style={{ marginTop: '-8px' }}>
                                                <Label>Documents Uploaded : </Label>
                                                <Label style={{ color: 'blue', marginLeft: '15px', marginRight: '40px' }}>{metrics ? metrics.documentMetrics.totalUploaded : ''}</Label>
                                                <Label>Documents Verified : </Label>
                                                <Label style={{ color: '#3E9B4C', marginLeft: '15px', marginRight: '40px' }}>{verifiedDocs}</Label>
                                                <Label className="ml-2">Documents Pending Verification : </Label>
                                                <Label style={{ color: '#F15A67', marginLeft: '15px' }}>{submittedDocs}</Label>
                                            </Row>
                                        </Col>
                                        <Col sm={3}>
                                            {
                                                (role === Roles.SITE_SCANNER_RESOLVER || role === Roles.SITE_SCANNER) ?
                                                    <div className="pull-right">
                                                        <Button color="info" className="btn-round" size="sm" style={{ maxHeight: '30px' }} href={`${Routes.DOCUMENTS}/add`}>
                                                            Upload New Document
                                                        </Button>
                                                    </div>
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <RemoteTable
                                        columns={DocumentFields(role, this.state)}
                                        onTableChange={this.handleTableChange}
                                        data={documents}
                                        onItemClick={this.onItemClick}
                                        page={page}
                                        sizePerPage={PAGE_SIZE}
                                        totalSize={totalSize}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout >
        )
    }
}

const mapStateToProps = ({ user, documents, sites, dashboard }) => {
    return {
        token: user.token,
        role: user.role ? user.role.name : undefined,
        documents: documents.data,
        site: sites.current,
        metrics: dashboard.metrics,
        visits: sites.visits,
        subjects: sites.subjects,
        totalSize: documents.count
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getDocumentsCount: (token, params, dataIndex) => DocumentActions.getDocumentsCount(dispatch, token, params),
        getDocuments: (token, params, start, limit) => DocumentActions.getDocuments(dispatch, token, params, start, limit),
        getMyMetrics: (token, siteId) => DashboardActions.getSSRMetrics(dispatch, token, siteId),
        getSiteSubjects: (token, siteId) => SiteActions.getSiteSubjects(dispatch, token, siteId),
        getSiteVisitDetails: (token, siteId) => SiteActions.getSiteVisitDetails(dispatch, token, siteId)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Documents);


