import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
// ...
const RemoteTable = ({ columns, data, page, sizePerPage, onTableChange, totalSize, onItemClick }) => {
    return (
        <div>
            <BootstrapTable
                remote
                keyField="id"
                data={data}
                columns={columns}
                hover
                bordered={false}
                noDataIndication="No Records Found"
                filter={filterFactory()}
                pagination={paginationFactory({ page, sizePerPage, totalSize, showTotal: true, hideSizePerPage: true })}
                onTableChange={onTableChange}
                rowEvents={{
                    onClick: onItemClick
                }}
            />
        </div>
    );
};

export default RemoteTable;