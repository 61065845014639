import Axios from 'axios';
import { ROLES_URL, SITE_STAFF_PIVOT_URL, NOTIFICATION_STATUSES } from 'api/list';

const updateProfile = (dispatch, token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${ROLES_URL}`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_ROLES',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getMySites = (dispatch, token, staffProfileId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${SITE_STAFF_PIVOT_URL}?staffProfile._id=${staffProfileId}`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_MY_SITES',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const logout = (dispatch) => {
    dispatch({
        type: 'GET_TOKEN_ERROR',
    });
}

const getMyNotifications = (dispatch, token, staffId) => {

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${NOTIFICATION_STATUSES}?staffProfile=${staffId}&read=false`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_NOTIFICATIONS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

export default { updateProfile, logout, getMySites, getMyNotifications }