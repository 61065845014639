import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Button
} from "reactstrap";
// reactstrap validations
import { AvForm, AvField } from 'availity-reactstrap-validation';

import BasicImageRedactor from 'views/Documents/Components/BasicImageRedactor';
import uploadImagesLogo from 'assets/img/upload_images.svg';
import { captureWidth, captureHeight, readContentFromScreenshot } from 'commons/Utils';
import { DocTypes } from "constants/index.js";

export default function UploadSDVDocument(props) {

    const fileInput = React.useRef();
    const videoElem = React.useRef();

    const [stage, setStage] = useState('data');
    
    const startCapture = async () => {
        try {
            const image = await readContentFromScreenshot(videoElem);
            props.onScreenshotCapture(image);
        } catch (e) {
            alert('capture screenshot failed')
            console.log(e);
        }
    }

    const handleValidSubmit = (event, values) => {
        values['site'] = props.site._id;
        values['study'] = props.study._id;
        console.log(values);
        props.onDocumentDataCollected(values);
    }
    
    return (
        <Card style={{ minHeight: '726px' }}>
            <CardHeader>
                <CardTitle tag="h5">Upload New Document</CardTitle>
            </CardHeader>
            <CardBody>
                <AvForm onValidSubmit={handleValidSubmit}>
                    <Row style={{ marginLeft: '0px' }}>
                        <Col xs={6} md={12} lg={3}>
                            <AvField name="site" autoComplete="off" value={props.site ? props.site.name : ''} placeholder="Site Name" label="Site Name" type="text" disabled />
                            <AvField name="protocol" autoComplete="off" value={props.study ? props.study.protocolId : ''} placeholder="Protocol" label="Study Name" type="text" disabled />
                            <AvField className="minimal" type="select" 
                                value={'SOURCE'} 
                                name="docType" 
                                disabled
                                label="Document Type" 
                                errorMessage='Please select a type.'
                                onChange={(e) => props.onDocTypeChange(e.target.value)} 
                                required  >
                                <option>Select</option>
                                {
                                    DocTypes.map((val, index) => {
                                        return (
                                            <option key={val.value} value={val.value}>{val.label}</option>            
                                        )
                                    })
                                }
                            </AvField>
                            <AvField disabled className="minimal" type="select" 
                                value={props.query && props.query.subject ? props.query.subject._id : undefined} 
                                name="subject" label="Trial Participant ID" 
                                errorMessage='Please select a Trial Participant.'
                            >
                                <option>Select</option>
                                {
                                    props.subjects && props.subjects.map(val =>
                                        <option key={val._id} value={val._id}>{val.uniqueCode}</option>
                                    )
                                }
                            </AvField>
                            <AvField 
                                disabled = { props.query.visit }
                                className="minimal" 
                                type="select" 
                                name="visit" 
                                label="Visit ID" 
                                value={props.query && props.query.visit ? props.query.visit._id : undefined}
                                errorMessage='Please select a visit.'
                                
                            >
                                <option>Select</option>
                                {
                                    props.visits && props.visits.map(val =>
                                        <option key={val} value={val._id}>{val.name}</option>
                                    )
                                }
                            </AvField>
                            <AvField name="description" autoComplete="off"
                                required
                                label="Post Reply To Query"
                                type="textarea"
                                errorMessage='Please enter text in Post Reply To Query'
                                onChange={(e) => props.onDescriptionChange(e.target.value)}
                                validate={{
									minLength: { value: 6, errorMessage: 'Query details must be at least 6 characters' },
                                    maxLength: { value: 500, errorMessage: 'Description must not be more than 500 characters.' }
                                }}/>
                        </Col>
                        <Col className="text-center">
                            <div className="mx-auto mb-4 query-image" style={{ borderStyle: 'dashed', borderWidth: '2px' }}>
                                {
                                    props.file ?
                                        <img
                                            alt="Monitor Document" src={props.file.previewUrl}
                                        />
                                        :
                                        <>
                                            <img alt="Upload" className="mx-auto" src={uploadImagesLogo} style={{
                                                height: '120px', display: 'block', marginTop: '100px', marginBottom: '20px'
                                            }} />
                                            <span>Upload Images</span>
                                        </>
                                }
                            </div>
                            {
                                props.file ?
                                    <>
                                        <Button className="btn-round" outline color="primary" size="sm" 
                                            onClick={e => setStage('redact')}>
                                            <i className="fa fa-pen mr-2" aria-hidden="true" style={{}}></i>
                                            Edit/Redact
                                        </Button>
                                        <Button color="info" className="btn-round ml-4" size="sm" type="submit" >{stage === 'confirm' ? 'Submit' : 'Upload'}</Button>
                                        <Button color="info" className="btn-round ml-4" outline size="sm" type="button" onClick={props.onCancel} >Cancel</Button>
                                        {
                                            stage === 'redact'&&
                                            <BasicImageRedactor 
                                                editMode = {stage === 'redact'}
                                                closeRedactWindow = {() => setStage('data')}
                                                url = {props.file ? props.file.previewUrl : null}
                                                saveRedactedDocument = { async (image) => {
                                                    setStage('data');
                                                    await props.saveRedactedDocument(image, 0)
                                                }}
                                            />
                                        }
                                    </>
                                    :
                                    <>
                                        <input
                                            hidden
                                            type="file"
                                            accept='image/*,.pdf'
                                            onChange={props.onFileUploadFromComputer}
                                            ref={fileInput}
                                        />
                                        <Button color="info" className="btn-round btn-title" size="sm" onClick={() => fileInput.current.click()}>
                                            Upload From Computer
                                        </Button>
                                        <video
                                            autoPlay
                                            hidden
                                            ref={videoElem}
                                            width={captureWidth}
                                            height={captureHeight}
                                        />
                                        <Button color="info" className="btn-round btn-title ml-4" size="sm" onClick={startCapture}>
                                            Screen Capture
                                        </Button>
                                        <Button color="info" className="btn-round btn-title ml-4" size="sm">
                                            Upload From Scanner
                                        </Button>
                                    </>
                            }
                        </Col>
                    </Row>
                </AvForm>
            </CardBody>
        </Card>
    );
};
