import React from "react";
import { connect } from 'react-redux';
// react plugin used to create switch buttons
import DashboardLayout from "layouts/Admin"

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Label,
} from "reactstrap";
// core components
import { PanelHeader } from "components";
import documentsIcon from "assets/img/documents.svg"
import queryIcon from "assets/img/query.svg"
import subjectsIcon from "assets/img/subject.svg"
import { DashboardActions, DocumentActions } from "actions";
import { Routes } from "constants/index";
import { RemoteTable } from "components";
import { crfVerifiedTableColumns, dataPendingColumns } from './TableColumns';
import { PAGE_SIZE } from "api/list";

class DMDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dePendingPage : 1,
            crfPage : 1
        };
    }

    componentDidMount() {
        const { token, site } = this.props;
        if (this.props && site && site._id) {
            
            this.props.getMyMetrics(token, site._id)
            let documentParams = `studySite.id=${site._id}&isCCRAVerified=true&docType=CRF`;
            this.props.getCRFVerifiedDocumentsCount(token, documentParams);
            this.props.getCRFVerifiedDocuments(token, documentParams, 0, PAGE_SIZE);
            documentParams = documentParams.concat('&deCompletedBy_null=true');
            this.props.getDEPendingDocumentsCount(token, documentParams);
            this.props.getDEPendingDocuments(token, documentParams, 0, PAGE_SIZE);
        }
    }

    handleTableChange = (type, paging) => {
        let start = (paging.page - 1) * paging.sizePerPage;
        let documentParams = `studySite.id=${this.props.site._id}&isCCRAVerified=true&docType=CRF`;
        if (type === 'dataPending') {
            documentParams = documentParams.concat('&deCompletedBy_null=true');
            this.props.getDEPendingDocuments(this.props.token, documentParams, start, paging.sizePerPage);
            this.setState({ dePendingPage : paging.page });
        } else if (type === 'crfVerified') {
            this.props.getCRFVerifiedDocuments(this.props.token, documentParams, start, paging.sizePerPage);
            this.setState({ crfPage : paging.page });
        }
    }
    
    onDocumentClick = (event, row, rowIndex) => {
        this.props.history.push(`${Routes.DOCUMENTS}/${row.uploadGroup}`)
    }
    
    render() {
        const { documentMetrics, subjectMetrics } = this.props.data ? this.props.data : {};
        const { verifiedCRFDocs, pendingDEDocs, dataPendingCount, count } = this.props
        return (
            <DashboardLayout>
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col lg="4" sm="6" tag="a" href={Routes.DOCUMENTS}>
                            <Card className="card-stats">
                                <CardBody className="dashboard-card bg-blue">
                                    <div className="statistics statistics-horizontal">
                                        <div className="info info-horizontal">
                                            <Row className="d-flex align-items-center">
                                                <Col className="text-left" xs="9">
                                                    <h1 className="dashboard-card-title">{documentMetrics ? documentMetrics.totalUploaded : 0}</h1>
                                                    <Label className="dashboard-card-subtitle">Total CRF Documents</Label>
                                                </Col>
                                                <Col xs="3">
                                                    <div className="icon icon-circle bg-blue-light" style={{ boxShadow: 'none' }}>
                                                        <img alt="..." src={documentsIcon} style={{ margin: '22px' }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" sm="6" tag="a">
                            <Card className="card-stats">
                                <CardBody className="dashboard-card bg-green">
                                    <div className="statistics statistics-horizontal">
                                        <div className="info info-horizontal">
                                            <Row className="d-flex align-items-center">
                                                <Col className="text-left" xs="9">
                                                    <h1 className="dashboard-card-title">{subjectMetrics ? subjectMetrics.totalSubjects : 0}</h1>
                                                    <Label className="dashboard-card-subtitle">Total Trial Participants</Label>
                                                </Col>
                                                <Col xs="3">
                                                    <div className="icon icon-circle bg-green-light" style={{ boxShadow: 'none' }}>
                                                        <img alt="..." src={queryIcon} style={{ margin: '20px' }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" sm="6" tag="a">
                            <Card className="card-stats">
                                <CardBody className="dashboard-card bg-pink">
                                    <div className="statistics statistics-horizontal">
                                        <div className="info info-horizontal">
                                            <Row className="d-flex align-items-center">
                                                <Col className="text-left" xs="9">
                                                    <h1 className="dashboard-card-title">{documentMetrics ? documentMetrics.totalDECompleted : 0}</h1>
                                                    <Label className="dashboard-card-subtitle">Total DE Completed</Label>
                                                </Col>
                                                <Col xs="3">
                                                    <div className="icon icon-circle bg-pink-light" style={{ boxShadow: 'none' }}>
                                                        <img alt="..." src={subjectsIcon} style={{ margin: '22px' }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" sm="12">
                            <Card style={{ minHeight: '80px' }}>
                                <CardHeader>
                                    <CardTitle className="graph-card-title">CRF Documents Verified</CardTitle>
                                </CardHeader>
                                <CardBody>
                                   <RemoteTable
                                        columns={crfVerifiedTableColumns((this.state.crfPage -1) * PAGE_SIZE)}
                                        onTableChange={(type, paging) => this.handleTableChange('crfVerified', paging)}
                                        data={verifiedCRFDocs}
                                        onItemClick={this.onDocumentClick}
                                        page={this.state.crfPage}
                                        sizePerPage={PAGE_SIZE}
                                        totalSize={count}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="6" sm="12">
                            <Card style={{ minHeight: '80px' }}>
                                <CardHeader>
                                    <CardTitle className="graph-card-title">Data Entry Pending Documents</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <RemoteTable
                                        columns={dataPendingColumns((this.state.dePendingPage -1) * PAGE_SIZE)}
                                        onTableChange={(type, paging) => this.handleTableChange('dataPending', paging)}
                                        data={pendingDEDocs}
                                        onItemClick={this.onDocumentClick}
                                        page={this.state.dePendingPage}
                                        sizePerPage={PAGE_SIZE}
                                        totalSize={dataPendingCount}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}


const mapStateToProps = ({ user, dashboard, studies, sites, documents }) => {
    return {
        token: user.token,
        data: dashboard.metrics,
        verifiedCRFDocs: documents.data || [],
        pendingDEDocs: documents.dataPending || [],
        count : documents.count,
        dataPendingCount : documents.dataPendingCount,
        study: studies.current,
        site: sites.current
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMyMetrics: (token, siteId) => DashboardActions.getSSRMetrics(dispatch, token, siteId),
        getDEPendingDocuments: (token, params, start, limit) => DocumentActions.getDataPendingDocuments(dispatch, token, params, start, limit),
        getDEPendingDocumentsCount: (token, params) => DocumentActions.getDataPendingDocumentsCount(dispatch, token, params),
        getCRFVerifiedDocuments: (token, params, start, limit) => DocumentActions.getDocuments(dispatch, token, params, start, limit),
        getCRFVerifiedDocumentsCount: (token, params) => DocumentActions.getDocuments(dispatch, token, params)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DMDashboard);