import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import * as serviceWorker from './serviceWorker';
import rootReducer from './reducers';
import secureStorage from './secureStorage';

import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/now-ui-dashboard.css";
import "./assets/css/app.css";


export const loadState = () => {
  var currentState = secureStorage.getItem('state');
  if (currentState && currentState !== null)
    return currentState
  else return undefined;
};

export const saveState = (state) => {
  secureStorage.setItem('state', state);
};

const composeEnhancer = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const middleware = [];
const persistedState = loadState();

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancer(applyMiddleware(...middleware))
);

store.subscribe(() => {
  saveState({
    user: store.getState().user,
    studies: { current: store.getState().studies.current },
    sites: { current: store.getState().sites.current }
  });
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
