import React from 'react';
import { RenderImage } from 'components';
import { Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';

const SDVQuery = (props) => {

    const [docUrl, setDocUrl] = React.useState(null);
    const data = props.query;

    const latestResponse = (props.responseHistory && props.responseHistory.length > 0) ?
                            props.responseHistory[0] : null;
    const sourceDocument =  latestResponse && props.responseDocument ? 
                                props.getDocUrl({versionedDocument : props.responseDocument}, 0) : null;
    const document = data.monitorDocument ? props.getDocUrl(data.monitorDocument, data.docVersion) : null;
    
    return (
        <Row>
            <Modal
                isOpen={docUrl !== null}
                size="lg"
                centered
                toggle={() => setDocUrl(null)}
                backdrop="static"
                keyboard={false}
            >
                <ModalHeader toggle={() => setDocUrl(null)}>
                    Document Preview
                </ModalHeader>
                <ModalBody>
                {   
                    docUrl &&
                    <div style={{ borderColor: 'gray', borderWidth: '1px' }}>
                       <RenderImage alt="CRF Document" className="mx-auto"  src={docUrl} />
                    </div>
                }
                </ModalBody>
            </Modal>
            {
                sourceDocument && 
                <Col className="text-center" style={{cursor : 'pointer'}} onClick= { e => setDocUrl(sourceDocument)}>
                    <h6>Source Document</h6>
                    <RenderImage className="query-image" 
                        alt="New Source Document" src={sourceDocument}/>
                </Col> 
            }
            <Col className="text-center" style={{cursor : 'pointer'}} onClick= { e => setDocUrl(document)}>
                <h6>CRF Document</h6>
                <RenderImage className="query-image" 
                    alt="CRF Document" src={document} />
            </Col>
        </Row>
    );
}

export default SDVQuery;