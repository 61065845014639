import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Breadcrumb,
    BreadcrumbItem,

} from "reactstrap";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin";
import { SiteActions, StudyActions } from "actions"
import { Routes } from 'constants/index.js';
import { RemoteTable } from "components";
import { PAGE_SIZE } from "api/list";

class Sites extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sites: [],
            study: { value: "All", label: "All" },
            page : 1
        };
        this.addSite = this.addSite.bind(this);
    }

    componentDidMount() {
        this.props.getSites(this.props.token, '', 0, PAGE_SIZE, true)
        this.props.getStudies(this.props.token)
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                sites: this.props.sites
            })
        }
    }
    handleClick(e, index) {
        e.preventDefault();
        const data = this.props.sites.slice(this.state.currentPage * this.state.itemsPerPage, (this.state.currentPage + 1) * this.state.itemsPerPage);
        console.log('data', data);
        this.setState({
            currentPage: index,
            data: data,
            click: this.state.click + 1,
        });
    }
    addSite() {
        this.props.history.push(`${Routes.SITES}/add`)
    }

    onItemClick = (e, row, index) => {
        this.props.history.push(`${Routes.SITES}/${row._id}`)
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;
        this.setState({ page })

        this.props.getSites(this.props.token, '', currentIndex, PAGE_SIZE, false);
    }

    render() {
        const { studies, } = this.props;
        const { study, page } = this.state;
        var sites = this.props.sites;
      
        var studyFilter = [{ value: "All", label: "All" }]
        studies && studies.map(val => {
            return studyFilter.push({ value: val._id, label: val.name })
        })

        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={Routes.DASHBOARD}>Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Sites</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Row>
                        <Col sm="12" style={{ maxHeight: '755px' }}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col sm={12}>
                                            <div>
                                                <CardTitle tag="h5" className="float-left mt-2">Sites</CardTitle>
                                                <div className="float-right mt-2 mr-4" style={{ lineHeight: 1, width: '140px' }} >
                                                    <Select
                                                        className="react-select info tableView"
                                                        classNamePrefix="react-select"
                                                        placeholder="Filter by Study"
                                                        name="singleSelect"
                                                        value={study}
                                                        options={studyFilter}
                                                        onChange={(value) => {
                                                            console.log(value);
                                                            const params = value.value !== 'All' ? `clinicalStudy.id=${value.value}` : '';
                                                            this.props.getSites(this.props.token, params, 0, PAGE_SIZE, true)
                                                            this.setState({ study: value })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <RemoteTable
                                        data={sites}
                                        page={page}
                                        totalSize={this.props.totalSize}
                                        sizePerPage={PAGE_SIZE}
                                        onItemClick={this.onItemClick}
                                        columns={
                                            [
                                                {
                                                    dataField: 'id',
                                                    text: 'S. No',
                                                    formatter: (cell, row, index) => {
                                                        return `${(index + ((this.state.page - 1) * PAGE_SIZE)) + 1}`
                                                    }
                                                },
                                                {
                                                    dataField: 'name',
                                                    text: 'Site\'s Name',
                                                },
                                                {
                                                    dataField: 'numberOfStudies',
                                                    text: 'No. of Studies',
                                                },
                                                {
                                                    dataField: 'contactEmail',
                                                    text: 'Contact Email',
                                                },
                                                {
                                                    dataField: 'isActive',
                                                    text: 'Status',
                                                    formatter: (cell, row) => {
                                                        if(row.isActive)
                                                            return <Badge className="active-badge">Active</Badge>
                                                        else
                                                            return <Badge className="inactive-badge">InActive</Badge>
                                                    }
                                                }
                                            ]
                                        }
                                        onTableChange={this.handleTableChange}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, sites, studies }) => {
    return {
        token: user.token,
        sites: sites.data ? sites.data : [],
        studies: studies.data,
        totalSize: sites.count
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSites: (token, params, start, limit, count) => SiteActions.getSites(dispatch, token, params, start, limit, count),
        getStudies: (token) => StudyActions.getStudies(dispatch, token, '', 0, PAGE_SIZE*10, true)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sites);
