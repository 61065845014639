import { Roles } from 'constants/index';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DEMetrics from './DEMetrics';
import Metrics from './Metrics';

class RootMetrics extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const role = this.props.role;
        return (
            <>
                {
                    ([Roles.SYSTEM_ADMIN, Roles.SITE_SCANNER_RESOLVER, Roles.SITE_SCANNER, Roles.CCRA, Roles.PM].indexOf(role) > -1) ?
                        <Metrics />
                        : null
                }
                {
                    ([Roles.DM, Roles.CSM, Roles.DE].indexOf(role) > -1) ?
                        <DEMetrics />
                        : null
                }
            </>
        );
    }
}

const mapStateToProps = ({ user, studies, sites }) => {
    return {
        role: user.role ? user.role.name : '',
        user: user,
        token: user.token,
        site: sites.current,
        study: studies.current
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RootMetrics);
