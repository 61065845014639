import React, { useState } from "react";

// reactstrap components
import {
    Row,
    Col,
    FormGroup,
    Label,
    Button,
    Input,
    FormFeedback
} from "reactstrap";

// react plugin used to create DropdownMenu for selecting items
import CreatableSelect from 'react-select/creatable';
import { MonitoringPlan } from "components";
import { Domains, Validations } from "constants/index.js";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";

const PublishMonitorPlan = (props) => {
    const [domains, setDomains] = useState(props.domains)
    const [visits, setVisits] = useState(props.visits)
    const [visitsList, setVisitsList] = useState([{ "visit0": '' }])
    const [domainInvalid, setDomainInvalid] = useState(false)

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;

        const list = [...visitsList];
        list[index][name] = value;
        setVisitsList(list);
        var data = []
        visitsList.forEach((val, index) => {
            data.push(val[`visit${index}`])
        })

        setVisits(data);
        props.onVisitsDataChanged(data)


    };

    const changeVisitsType = (e) => {
        const value = e.target.value;
        props.onVisitTypeChange(value);
        visitsDataList(props.visitsCount, value);
    }

    const domainsData = () => {
        var data = []
        domains && domains.forEach(ele => {
            data.push(ele.label)
        })
        return data
    }

    const visitsDataList = (newValue, visitType) => {

        props.onVisitsCountChanged(newValue);

        var automaticData = ['Screening']
        var manualData = [{ 'visit0': 'Screening' }]

        for (var i = 0; i < newValue; i++) {
            if (visitType === 'automatic')
                automaticData.push(`V${i + 1}`)

            if (visitType === 'manual') {
                const la = `visit${i + 1}`
                var obj = {}
                obj[la] = ''
                manualData.push(obj)
                automaticData.push('')
            }

        }
        setVisits(automaticData);
        setVisitsList(manualData);

        if (visitType === 'automatic') {
            props.onVisitsDataChanged(automaticData)
        }
    }

    const uniqueValidation = (value, ctx) => {
        var valueArr = []
        Object.keys(ctx).forEach(val => {
            valueArr.push(ctx[val]);
        });
        var isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) !== idx
        });

        if (isDuplicate) {
            return 'Visit name must be unique.'
        }
        return true;
    }

    const visitsManualView = () => {

        var _tempVisitsList = visitsList;
        var views = [
            <AvField name="visit0" label="Screening" required defaultValue={'Screening'} onChange={e => handleInputChange(e, 0)}
                validate={{
                    required: { value: true, errorMessage: 'Please enter visit name.' },
                    minLength: { value: 4, errorMessage: 'Visit name must be at least 4 characters.' },
                    maxLength: { value: 20, errorMessage: 'Visit name must not be more than 20 characters.' },
                }} />
        ]

        _tempVisitsList.forEach((val, i) => {
            if (i === 0) {
                return
            }
            views.push(
                <AvField key={i}
                    name={`visit${i}`}
                    label={`Visit ${i}`}
                    required
                    value={val.visit}
                    maxLength={20}
                    onChange={e => handleInputChange(e, i)}
                    validate={{
                        required: { value: true, errorMessage: 'Please enter visit name.' },
                        minLength: { value: 4, errorMessage: 'Visit name must be at least 4 characters' },
                        maxLength: { value: 20, errorMessage: 'Visit name must not be more than 20 characters.' },
                        unique: uniqueValidation
                    }} />
            );
        })
        return views;
    }

    const validateForm = (event, errors, values) => {
        console.log(event, errors, values);
    }

    return (
        <>
            <AvForm onInvalidSubmit={validateForm} onValidSubmit={props.onMonitorNextClick}>
                <Row>
                    <Col sm={6}>
                        <h6>Monitoring Plan</h6>
                        <AvField name="visitsCount" label="No. of Visits" placeholder="Visits count" defaultValue={props.visitsCount} type="number" validate={{
                            required: { value: true, errorMessage: 'Please enter number of visits.' },
                            pattern: Validations.POSITIVE_NUMBERS,
                            min: { value: 1, errorMessage: 'Visits should should be atleast 1' },
                            maxLength: { value: 3, errorMessage: 'Visits count cannot be more than 999.' }
                        }}
                            onChange={e => {
                                const newValue = e.target.value;
                                if (newValue > 0 && newValue <= 999)
                                    visitsDataList(newValue, props.visitType);
                            }} />
                        <AvGroup>
                            <Label>Select Data Domains</Label>
                            <CreatableSelect
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Select"
                                isMulti
                                invalid={domainInvalid}
                                value={domains}
                                name="domains"
                                options={Domains}
                                isValidNewOption={(value) => {
                                    if (value && value.length > 0 && (value.replace(' ', '').length > 1 && value.replace(' ', '').length <= 20)) {
                                        setDomainInvalid(false)
                                        return true
                                    } else {
                                        setDomainInvalid(true)
                                        return false;
                                    }
                                }}
                                onChange={(value) => {

                                    setDomainInvalid(false)
                                    setDomains(value)
                                    props.onDomainsDataChanged(value)

                                }}
                            />
                            <FormFeedback>Domain should not be more than 20 characters.</FormFeedback>
                        </AvGroup>
                        <FormGroup style={{ marginTop: '8px' }}>
                            <Label>Generate Visit Names</Label>
                            <FormGroup check className="form-check-radio" inline>
                                <Label check style={{ paddingLeft: '12px' }}>
                                    <Input
                                        value="automatic"
                                        name="visitNamesType"
                                        type="radio"
                                        checked={props.visitType === 'automatic' ? true : false}
                                        onChange={(value) => {
                                            changeVisitsType(value)
                                        }}
                                    />
                                    <span className="form-check-sign" />Generate Automatically
                            </Label>
                                <Label check style={{ paddingLeft: '12px', marginLeft: '20px' }}>
                                    <Input
                                        value="manual"
                                        name="visitNamesType"
                                        type="radio"
                                        checked={props.visitType === 'manual' ? true : false}
                                        onChange={(value) => {
                                            changeVisitsType(value)
                                        }}
                                    />
                                    <span className="form-check-sign" />Generate Manually
                            </Label>
                            </FormGroup>
                        </FormGroup>
                        {
                            props.visitType === 'manual' &&
                            visitsManualView()
                        }
                    </Col>
                </Row>
                {
                    (domains && domains.length > 0) ? <MonitoringPlan data={props.monitorData} visits={visits} domains={domainsData()} onDataChange={props.onMonitorDataChange} /> : ''
                }
                <Button color="info" hidden={props.monitorData.length <= 0} style={{ marginLeft: '20px' }} className="btn-round" type="submit" size="sm">
                    Next
            </Button>
            </AvForm>
        </>
    )
}

export default PublishMonitorPlan
