import React from "react";
import { connect } from 'react-redux';
import { Roles } from "constants/index.js";
import { StudyDetails, SiteStudyDetails } from "./Components";

class RootStudyDetails extends React.Component {

    render() {
        const role = this.props.role;
        switch (role) {
            case Roles.SYSTEM_ADMIN:
                return <StudyDetails {...this.props} />
            default:
                return <SiteStudyDetails {...this.props} />
        }
    }
}

const mapStateToProps = ({ user }) => {
    return {
        role: user.role ? user.role.name : '',
        user: user,
        token: user.token
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RootStudyDetails);