import React from 'react';
import {
    Label, Row, Col, Button, Breadcrumb, BreadcrumbItem, Card, FormGroup, Input, CardBody,
    CardHeader, CardTitle
} from 'reactstrap';
import { Routes } from 'constants/index.js';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DashboardLayout from "layouts/Admin";

export default class SDocuments extends React.Component {
    constructor(){
        super();
        this.state={

        }
        this.onUpload=this.onUpload.bind(this);
    }
    onUpload(){
        this.props.history.push(`${Routes.STUDYINFORMATION}`)
    }
    render() {
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.STUDYINFORMATION}`}>StudyInformation</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Upload Document</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card style={{ minHeight: '755px' }}>
                                <CardHeader>
                                    <CardTitle tag="h4">Upload Document</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={4}>
                                            <FormGroup>
                                                <Label>Document Name</Label>
                                                <Input type="text" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Description</Label>
                                                <Input type="textarea" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Button className="btn-neutral" ><i className="fa fa-arrow-up text-primary" aria-hidden="true" style={{ marginRight: '10px' }} />Upload Document</Button>
                                            </FormGroup>
                                            <Button color="primary" className="btn-round" size="sm" onClick={this.onUpload}>Upload</Button>
                                            <Button color="primary" outline className="btn-round" size="sm" style={{ marginLeft: '20px' }}>Cancel</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}
