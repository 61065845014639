import React from "react";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import moment from 'moment';

// core components
import { Routes } from 'constants/index.js';

import UploadSDVDocument from './UploadSDVDocument';
import UploadSDVConfirm from  './UploadSDVConfirm';

import { getToken, createMonitorDocument, createMonitorQueryComments } from "api/index.js";
import { uploadBlobToStrapi, readFileAsDataURL, readContentAsImage, checkFileSize } from "commons/Utils.js";
import cogoToast from "cogo-toast";

export default class SDVQuery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            stage: 'data',
            file : null,
            monitorDoc: undefined,
        }
    }

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    }

    componentDidMount() {
        const token = this.props.token;
        this.props.getQueryDetails(token, this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.query !== this.props.query) {
            const token = this.props.token;
            const siteId = this.props.site && this.props.site._id;
            if (siteId) {
                this.props.getSiteSubjects(token, siteId);
                this.props.getSiteVisits(token, siteId);
            }
        }
    }

    onDocumentDataCollected = (values) => {
        this.setState({ monitorDoc: values, stage: 'confirm' })
    }

    onAddMorePagesClick = () => {
        this.setState({ stage: 'upload' })
    }

    onDocTypeChange = (docType) => {
        var doc = this.state.monitorDoc;
        if (doc) {
            doc['docType'] = docType;
        } else {
            doc = { docType: docType }
        }
        this.setState({
            monitorDoc: doc
        });
    }

    onSubjectChange = (subject) => {
        var doc = this.state.monitorDoc;
        if (doc) {
            doc['subject'] = subject;
        } else {
            doc = { subject: subject }
        }
        this.setState({
            monitorDoc: doc
        });
    }

    onDescriptionChange = (description) => {
        var doc = this.state.monitorDoc;
        if (doc) {
            doc['description'] = description;
        } else {
            doc = { description: description }
        }
        this.setState({
            monitorDoc: doc
        });
    }

    onFileUploadFromComputer = async (e) => {
        const file = e.target.files[0];
        const validFile = checkFileSize(file, 10)
        if (validFile.valid) {
            const result = await readContentAsImage(await readFileAsDataURL(file));
            if (result && result.length > 0)
                this.setState({ file: { previewUrl: result[0] } })    
        } else {
            cogoToast.warn(validFile.message)
        }
    }

    onScreenshotCapture = async (image) => {
        this.setState({ file: { previewUrl: image } })
    }
    
     saveRedactedDocument = async (image, index) => {
        await this.setState({file : {previewUrl: image }});
    }

    onUploadConfirmClick = (values) => {
        //todo create monitor document 
        const identifier = this.props.user.email;
        getToken(identifier, values.password).then(async (response) => {
            try {
                this.props.saveToken(response.data);
                const { file, monitorDoc } = this.state;
                const queryDocument = this.props.query ? this.props.query.monitorDocument : null;
                const uploadedBy = `${this.props.user.staffProfile.firstName} ${this.props.user.staffProfile.lastName}`
                var filesData = []
                var pageNos = [1]
                const fileResponse = await uploadBlobToStrapi(this.props.user.token, file.previewUrl);
                filesData.push({ 'version': 1, 'url': fileResponse.data[0].url, 'uploadDate': moment.utc().valueOf(), 'uploadedBy': uploadedBy });
                
                const monitorDocObj = {
                    subject: queryDocument ? queryDocument.subject : monitorDoc.subject,
                    studySite: this.props.query ? this.props.query.studySite._id : monitorDoc.site,
                    docType: monitorDoc.docType,
                    pageNo: pageNos,
                    visit:  this.props.query && this.props.query.visit ? this.props.query.visit._id : monitorDoc.visit,
                    domainData : queryDocument ? queryDocument.domainData : monitorDoc.domainData,
                    description: monitorDoc.description,
                    linkSourceCRFDoc : queryDocument ? queryDocument._id : null,
                    documents: filesData
                }
              
                await createMonitorDocument(this.props.token, monitorDocObj);

                var commentsObj = {
                    'monitorQuery': this.props.match.params.id,
                    'comment': monitorDoc.description,
                    'ownerStaff': this.props.user.staffProfile._id,
                    'attachment': fileResponse.data[0].url
                }
                
                // create query comments
                await createMonitorQueryComments(this.props.token, commentsObj)
                this.props.history.push(Routes.QUERIES);
            } catch (error) {
                alert('API failed!')
                console.log(error);
            }

        }).catch((e) => {
            const m = e.response ? e.response.data.data[0].messages[0].message : 'Something went wrong';
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        title="Alert"
                        onConfirm={this.hideAlert}
                    >
                        {m}
                    </SweetAlert>
                )
            });
        });
    }

    render() {
        const { stage, file, monitorDoc } = this.state;
        return (
            <>
                {this.state.alert}
                {
                    stage === 'data' &&
                    <UploadSDVDocument
                        {...this.props}
                        file={file}
                        document={this.props.query ? this.props.query.monitorDocument : null}
                        onDescriptionChange={this.onDescriptionChange}
                        onSubjectChange={this.onSubjectChange}
                        onCancel={this.props.onCancel}
                        onDocTypeChange={this.onDocTypeChange}
                        onScreenshotCapture={this.onScreenshotCapture}
                        onFileUploadFromComputer={this.onFileUploadFromComputer}
                        saveRedactedDocument = {this.saveRedactedDocument}
                        onDocumentDataCollected={this.onDocumentDataCollected} 
                    />
                }
                        
                {
                    stage === 'confirm' &&
                    <UploadSDVConfirm
                        {...this.props}
                        document={monitorDoc}
                        file={file}
                        user={this.props.user}
                        onUploadConfirmClick={this.onUploadConfirmClick} 
                        onCancel={this.props.onCancel}
                    />
                }
            </>
        );
    }
}