import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import * as moment from 'moment';
// used for making the prop types of this component
import PropTypes from "prop-types";
import Badge from '@material-ui/core/Badge';
import { RenderImage } from 'components';

// reactstrap components
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Col,
  Button,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover, Label, FormGroup
} from "reactstrap";
import { BASE_URL } from "api/list";
import socketIOClient from "socket.io-client";
import { Roles, DATE_TIME_FORMAT } from "constants/index.js";
import logoutLogo from 'assets/img/log-out.svg'
import helpLogo from 'assets/img/help.svg'
import defaultPic from 'assets/img/default_profile.jpg'

import siteScannerpdf from 'assets/img/UserManualSiteStaffScanner.pdf'

import dmPdf from 'assets/img/UserManualDM.pdf'

import siteResolverPdf from 'assets/img/UserManualSiteStaffScannerAndResolver.pdf'

import ccraPdf from 'assets/img/UserManualcCRA.pdf'

import sysAdminPdf from 'assets/img/UserManualSystemAdmin.pdf'

import csmPdf from 'assets/img/UserManualCSM.pdf'

import pmPdf from 'assets/img/UserManualPM.pdf'
import dePdf from 'assets/img/UserManualDE.pdf'


import { UserActions } from 'actions';
import { updateNotificationStatus } from "api";

var notificationSocket = null;

class AdminNavbar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      navbarOpen: false,
      color: "yellow",
      // color: "transparent",
      notificationsCount: 0,
      socNotifications: [],
      notificationOpen: false
    };
  }

  sidebarToggle = React.createRef();

  toggle = () => {
    if (this.state.navbarOpen) {
      this.setState({
        color: "transparent",
      });
    } else {
      this.setState({
        color: "yellow",
      });
    }
    this.setState({
      navbarOpen: !this.state.navbarOpen,
    });
  };

  openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  };

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.navbarOpen) {
      this.setState({
        color: "white",
      });
    } else {
      this.setState({
        color: "transparent",
      });
    }
  };
  onResumeClick = () => {
    if(this.props.role === Roles.DM) {
      // if(this.props.role === Roles.DM) {
      window.open(dmPdf);
    } else if(this.props.role === Roles.SITE_SCANNER_RESOLVER) {
       window.open(siteResolverPdf);
    } else if(this.props.role === Roles.HOSPITAL_SCANNER) {
      // } else if(this.props.role === Roles.SITE_SCANNER) {
       window.open(siteScannerpdf);
    } else if(this.props.role === Roles.PM) {
       window.open((pmPdf));
    } else if(this.props.role === Roles.DE) {
       window.open((dePdf));
    } else if(this.props.role === Roles.CSM) {
       window.open((csmPdf));
    } else if(this.props.role === Roles.CCRA) {
       window.open((ccraPdf));
    } else if(this.props.role === Roles.SYSTEM_ADMIN) {
       window.open((sysAdminPdf));
    }
}

  handleSocNotification = (data) => {
    let socNotifications = this.state.socNotifications;
    socNotifications.push(data);
    this.setState({ socNotifications: socNotifications });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));

    // user email to set user socket id for client
    if (!notificationSocket) {
      console.log("initializing socket for user");
      notificationSocket = socketIOClient(BASE_URL, { transports: ['websocket'] });

      // register user email as socket identifier
      notificationSocket.on('connect', () => {
        console.log("new socket connection");
        notificationSocket.emit('setUserId', this.props.user.email);
      });

      // to check if io is successful. server emits test data if connection is successful
      notificationSocket.on('test', data => {
        console.log(data);
      });

      notificationSocket.on("notification", data => {
        console.log(data);
        this.handleSocNotification(data);
      });
    }

    this.fetchMyNotifications();

  }

  fetchMyNotifications = () => {
    if (this.props.user && this.props.user.staffProfile) {
      const staffId = this.props.user.staffProfile._id;
      this.props.getMyNotifications(this.props.user.token, staffId)
    }
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current.classList.toggle("toggled");
    }

    if (this.props.notifications && this.props.notifications.length !== this.state.notificationsCount) {
      this.setState({ notificationsCount: this.props.notifications.length })
    }
  }


  onLogout = () => {
    if (notificationSocket) {
      console.log("disconnecting notification socket");
      // disconnect socket on logout
      notificationSocket.disconnect();
      notificationSocket = null;
    }

    this.props.logout();
  }

  showNotifications = () => {
    this.setState({ notificationOpen: !this.state.notificationOpen });
    setTimeout(() => {
      this.fetchMyNotifications();
    }, 100)
  }

  markAllNotifications = async () => {
    try {
      await updateNotificationStatus(this.props.user.token);
    } catch (error) {
      console.log(error);
      alert('failed updating notification status');
    }

    this.setState({ notificationOpen: !this.state.notificationOpen, notificationsCount: 0, socNotifications: [] })
  }

  render() {

    const { role, study, site, notifications } = this.props;
    const { notificationOpen, notificationsCount, socNotifications } = this.state;
    // console.log(role,pdf,"roleeeee")

    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={this.state.color}
        expand="lg"
        className={(this.state.color === "transparent" ? "navbar-transparent " : "")}
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={this.sidebarToggle}
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={this.state.navbarOpen}
            navbar
          >
            {
              ([Roles.CCRA, Roles.SITE_SCANNER_RESOLVER, Roles.SITE_SCANNER, Roles.PM, Roles.DM, Roles.DE, Roles.CSM].includes(role)) ?
                <Col sm={6} xs={12}>
                  <FormGroup className="form-inline">
                    <Label className="text-primary w-25"> Current Protocol </Label>
                    <div className={[Roles.SITE_SCANNER_RESOLVER, Roles.SITE_SCANNER].includes(role) ? "form-control w-50" : "form-control w-75"} style={{border : 'none', fontSize : '14px'}}>
                        <InputGroup>
                          <Input
                            disabled
                            placeholder="Protocol Name ..."
                            value={(role === Roles.CCRA || role === Roles.PM || role === Roles.DM || role === Roles.DE || role === Roles.CSM) ?
                              (study ? study.name : '') + (site ? ` / ${site.name}` : '') : (study ? study.name : '')}
                            style={{ backgroundColor: '#fff', color: '#000' }} />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <Button className="text-primary btn-neutral" style={{ fontSize: '12px', padding: '0px', margin: '0px' }} onClick={this.props.onStudyChange}>Change</Button>
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                    </div>
                  </FormGroup>
                </Col>
                : ''
            }
            {
              role === Roles.SYSTEM_ADMIN ?
                <Col md={12} xs={12} lg={3}>
                  <InputGroup>
                    <Input className="text-primary" name="" placeholder="Search for Sites" autoFocus={this.props.searchFocus} onFocus={this.props.onSearchFocus} onChange={this.props.onSearchChange} style={{ backgroundColor: '#fff' }} />
                    {/* <InputGroupAddon addonType="append" onClick={this.props.searchFocus}>
                      <InputGroupText >
                        <i className="now-ui-icons ui-1_zoom-bold text-primary" />
                      </InputGroupText>
                    </InputGroupAddon> */}

                    <InputGroupAddon addonType="append">
                      <InputGroupText style={{ paddingRight: '18px', borderTopRightRadius: '30px', borderBottomRightRadius: '30px' }} onClick={() => { this.props.onSearchFocus() }}><i className="now-ui-icons ui-1_zoom-bold text-primary" /></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                : null
            }
            <Nav navbar xs={2} md={2} className="ml-auto">
              <NavItem>
                <div className="nav-link">
                  <Badge badgeContent={notificationsCount + socNotifications.length} color="error" id="popover" style={{ cursor: 'pointer' }}>
                    <i className="now-ui-icons ui-1_bell-53" style={{ color: '#848587' }} />
                  </Badge>
                  <UncontrolledPopover isOpen={notificationOpen} placement="bottom" target="popover" toggle={this.showNotifications}>
                    <PopoverHeader className="text-center" style={{ color: "green", marginBottom: '10px' }}>
                      <span style={{ float: 'left' }}> Notifications </span>
                      {
                        ((notificationsCount + socNotifications.length) > 0) &&
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a href='#' onClick={this.markAllNotifications}>
                          <span style={{ float: 'right', fontSize: '12px' }}> Mark All As Read</span>
                        </a>
                      }
                    </PopoverHeader>
                    <PopoverBody>
                      <ul>
                        {
                          socNotifications.map(val => {
                            const staffProfile = val.staffProfile;
                            const activity = val.activityLogger;
                            if (activity && staffProfile)
                              return <li key={val._id}>
                                <div className="image">
                                  <RenderImage alt="." width={42} height={42} src={activity.owner && activity.owner.profilePicture ? activity.owner.profilePicture : defaultPic} />
                                </div>
                                <div className="content">
                                  <p style={{ overflow: 'hidden', fontSize: '12px' }}>{activity.notificationString}</p>
                                  <p style={{ marginBottom: '0px', color: 'gray', fontSize: '10px', float: 'right' }}>{moment(activity.createdAt).format(DATE_TIME_FORMAT)}</p>
                                </div>
                              </li>
                            else
                              return null
                          })
                        }
                        {
                          notifications.map(val => {
                            const staffProfile = val.staffProfile;
                            const activity = val.activityLogger;
                            if (activity && staffProfile)
                              return <li key={val._id}>
                                <div className="image">
                                  <RenderImage alt="." width={42} height={42} src={activity.owner && activity.owner.profilePicture ? activity.owner.profilePicture : defaultPic} />
                                </div>
                                <div className="content">
                                  <p style={{ overflow: 'hidden', fontSize: '12px' }}>{activity.notificationString}</p>
                                  <p style={{ marginBottom: '0px', color: 'green', fontSize: '10px', float: 'right' }}>{moment(activity.createdAt).format(DATE_TIME_FORMAT)}</p>
                                </div>
                              </li>
                            else
                              return null
                          })
                        }
                        {
                          ((notificationsCount + socNotifications.length) === 0) &&
                          <li className="text-center">
                            <p style={{ marginBottom: '0px', fontSize: '12px' }}> No Unread Notifications </p>
                          </li>
                        }
                      </ul>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <p>
                    <span className="d-lg-none d-md-block ml-2" style={{ color: '#333' }}>Notifications</span>
                  </p>
                </div>
              </NavItem>
              <NavItem>
                <Link to="#help" className="nav-link">
                  
                  <a  onClick={this.onResumeClick} target="_blank" download="revathi">
                  <img alt="..." src={helpLogo} width={24} height={24} />
                  </a>
                  <p>
                    <span className="d-lg-none d-md-block ml-2">
                   </span>
                  </p>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/" onClick={this.onLogout} className="nav-link">
                  <img alt="..." src={logoutLogo} width={24} height={24} />
                  <p>
                    <span className="d-lg-none d-md-block ml-2">Logout</span>
                  </p>
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar >
    );
  }
}

AdminNavbar.propTypes = {
  role: PropTypes.string,
  study: PropTypes.object
};

const mapStateToProps = ({ user, studies, sites }) => {
  return {
    user: user,
    role: user.role ? user.role.name : '',
    study: studies.current,
    site: sites.current,
    notifications: user.notifications ? user.notifications : []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMyNotifications: (token, staffId) => UserActions.getMyNotifications(dispatch, token, staffId),
    logout: () => UserActions.logout(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
