import React, {  useState, Fragment } from 'react';
import { Label, Row, Col, Button, Input } from 'reactstrap';
// reactstrap validations
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { ImageUpload } from 'components';
import { Validations } from 'constants/index.js';
import Select from 'react-select';

const CreateSponsor = (props) => {

    const [sponsorType, setSponsorType] = useState(props.sponsors && props.sponsors.length ? 'select' : 'create');
    const [file, setFile] = useState()
    const [sponsor_select, setSponsorSelect] = useState()

    const handleSubmit = (event, values) => {
        values['type'] = sponsorType;
        values['logo'] = file;
        props.onSponsorDetailsSubmit(event, values);
    }

    const onLogoLoaded = (file) => {
        setFile(file)
    }

    const onLogoRemoved = () => {
        setFile()
    }

    return (
        <Fragment>
            <Row>
                <Col xs={5}>
                    <h6>Company Details</h6>
                    <AvForm onValidSubmit={handleSubmit}
                        // model={{ sponsor: '5f1b3b20e1623566c4f33ffc' }}
                    >
                        {
                            props.sponsors && props.sponsors.length > 0 &&
                            <AvGroup check className="form-check-radio" inline style={{ marginBottom: '20px' }}>
                                <Label check style={{ paddingLeft: '12px' }}>
                                    <Input
                                        value="select"
                                        name="siteType"
                                        type="radio"
                                        checked={sponsorType === 'select' ? true : false}
                                        onChange={(value) => { setSponsorType('select') }}
                                    />
                                    <span className="form-check-sign" />Select Company
                                                                        </Label>
                                <Label check style={{ paddingLeft: '12px', marginLeft: '20px' }}>
                                    <Input
                                        value="create"
                                        name="siteType"
                                        type="radio"
                                        checked={sponsorType === 'create' ? true : false}
                                        onChange={(value) => { setSponsorType('create') }}
                                    />
                                    <span className="form-check-sign" />Create Company
                                    </Label>
                            </AvGroup>
                        }
                        {
                            sponsorType === 'select' ?
                                <>
                                    {/* <AvField className="minimal" type="select" placeholder="Sponsor" name="sponsor" label="Sponsor" errorMessage='Please select a sponsor.'
                                        required={sponsorType === 'select' ? true : false}>
                                        <option>Select</option>
                                        {
                                            props.sponsors && props.sponsors.map(val => {
                                                return <option key={val._id} value={val._id}>{val.name}</option>
                                            })
                                        }
                                    </AvField> */}

                                    <AvGroup>
                                        <Label>Company</Label>
                                        <Select
                                            className="react-select info"
                                            classNamePrefix="react-select"
                                            placeholder="Company"
                                            name="company_select"
                                            options={props.sponsors ?
                                                props.sponsors.map((val, index) => {
                                                    return ({
                                                        value: val._id,
                                                        label: val.name
                                                    })
                                                }) : []}
                                            onChange={(value) => { setSponsorSelect(value.value) }}
                                        />
                                        <AvInput name="sponsor" value={sponsor_select} hidden autoComplete="off" required />
                                        <AvFeedback>Please select a company.</AvFeedback>
                                    </AvGroup>
                                </>
                                : ''
                        }
                        {
                            sponsorType === 'create' ?
                                <>
                                    <AvField name="name" autoComplete="off" label="Company's Name" type="text" validate={{
                                        required: { value: true, errorMessage: 'Please enter a company name.' },
                                        pattern: Validations.ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                        minLength: { value: 6, errorMessage: 'Company name must be at least 6 characters' },
                                        maxLength: { value: 50, errorMessage: 'Company name should not be more than 50 characters' },
                                    }} />
                                    <AvField name="address" autoComplete="off" label="Corporate Address" type="textarea" validate={{
                                        required: { value: true, errorMessage: 'Please enter an address.' },
                                        pattern: Validations.MULTI_LINE_ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                        minLength: { value: 4, errorMessage: 'Address must be at least 4 characters.' },
                                        maxLength: { value: 100, errorMessage: 'Address must not be more than 100 characters.' }
                                    }} />
                                    <AvField name="email" autoComplete="off" label="Email ID" type="email" required validate={{
                                        required: { value: true, errorMessage: 'Please enter an email.' },
                                        email: { value: true, errorMessage: 'Please enter a valid email address.' },
                                        maxLength: { value: 50, errorMessage: 'Email must be not more than 50 characters.' },
                                    }} />
                                    <AvField name="contactNumber" placeholder="+447911123456" autoComplete="off" label="Contact Number" type="text"  validate={{
                                        required: { value: true, errorMessage: 'Please enter a contact number.' },
                                        pattern: Validations.TELEPHONE,
                                    }} />
                                    <AvField name="contactName" autoComplete="off" label="Contact Person" type="text" validate={{
                                        required: { value: true, errorMessage: 'Please enter a contact name.' },
                                        pattern: Validations.ALPHABETS_WITH_SPACES,
                                        minLength: { value: 4, errorMessage: 'Contact name must be at least 4 characters.' },
                                        maxLength: { value: 25, errorMessage: 'Contact name must be not more than 25 characters.' },
                                    }} />
                                    <AvField name="designation" autoComplete="off" label="Designation" type="text" validate={{
                                        required: { value: true, errorMessage: 'Please enter a designation.' },
                                        pattern: Validations.ALPHABETS_WITH_SPACES,
                                        minLength: { value: 4, errorMessage: 'Designation must be at least 4 characters.' },
                                        maxLength: { value: 20, errorMessage: 'Designation must not be more than 20 characters.' }
                                    }} />
                                    <AvGroup>
                                        <Label>Company's Logo</Label>
                                        <ImageUpload token={props.token} acceptType="image/*" onFileLoaded={onLogoLoaded} onFileRemoved={onLogoRemoved} />
                                    </AvGroup>
                                </>
                                : ''
                        }
                        <Button color="info" className="btn-round" size="sm" type="submit">
                            Next
                        </Button>
                    </AvForm>
                </Col>
            </Row>
        </Fragment>
    );
}

export default CreateSponsor