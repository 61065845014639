import React from "react";
import { connect } from 'react-redux';
import {
    Col, Breadcrumb, BreadcrumbItem, CardHeader, CardTitle,
    Card,
    CardBody,
    Label,
    Button,
    Row,
    FormGroup,
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane, FormFeedback
} from 'reactstrap';

import FileSaver from 'file-saver';

import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin";
// react plugin used to create datetimepicker
import Datetime from "react-datetime";
// react plugin used to create DropdownMenu for selecting items
import * as moment from 'moment';
import _ from 'lodash';
import { getReports, downloadAuditTrail } from "api";
import { SiteActions } from "actions";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { RemoteTable } from "components";
import { getFileFromUrl } from "commons";

/*var selectOptions = [
    { value: "protocol", label: "Protocol" },
    { value: "site", label: "Site" },
    { value: "patients", label: "Trial Participants" },
    { value: "data domain", label: "Data Domain" },
    { value: "visit number", label: "Visit Number" },
    { value: "image", label: "Image" },
    { value: "data uploaded", label: "Data Uploaded" },
    { value: "author", label: "Author" },
    { value: "data approved", label: "Data Approved" },
];*/

const tabs = [
    { id: 'ht1', title: 'Monitor Reports' },
    { id: 'ht2', title: 'Audit Trails' }
]

class Reports extends React.Component {
    constructor() {
        super();
        this.state = {
            activeTab: "ht1",
            dataId: false,
            fromDate: undefined,
            toDate: undefined,
            reports: undefined,
            invalidFrom: false,
            invalidTo: false,
            // invalidSubject: false,
            // invalidVisit: false, subjectOptions: [], visitOptions: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        var newstate = state;

        console.log('getDerivedStateFromProps');
        if (props.subjects) {
            var subjects = props.subjects.map((val, index) => {
                return ({
                    value: val._id,
                    label: val.uniqueCode
                })
            })
            newstate['subjectOptions'] = subjects;

            if (!newstate['subjects']) {
                newstate['subjects'] = subjects;
            }
        }

        if (props.visits) {
            var visits = props.visits.map((val, index) => {
                return ({
                    value: val._id,
                    label: val.name
                })
            })
            newstate['visitOptions'] = visits;

            if (!newstate['visits']) {
                newstate['visits'] = visits;
            }
        }


        return newstate;
    }

    downloadMonitorReport = async () => {
        const { fromDate, toDate } = this.state;
        if (!fromDate) {
            this.setState({ invalidFrom: true })
        } else {
            this.setState({ invalidFrom: false })
        }

        if (!toDate) {
            this.setState({ invalidTo: true })
        } else {
            this.setState({ invalidTo: false })
        }

        const dateFormat = 'YYYY-MM-DDTHH:mm:ss'
        if (fromDate && toDate) {
            const _reports = await getReports(this.props.token, fromDate.startOf('day').format(dateFormat), toDate.endOf('day').format(dateFormat))
            this.setState({ reports: _reports.data })
        }
    }

    saveDocument = async (doc) => {
        if (doc) {
            const filename = `${doc.name}.${doc.url.split('.').pop()}`;
            let fileBlob = await getFileFromUrl(doc.url, this.props.token, true);
            FileSaver.saveAs(fileBlob, filename);
        }
    }

    downloadAuditReport = async (event, values) => {
        try {
            const response = await downloadAuditTrail(this.props.token, this.props.site._id, values.subject, values.visit);
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const filename = 'Audit_Trail_Report_' + moment().format('YYYYMMDD_HH:MM') + '.xlsx';

            // Download the file
            if (response.status === 200) FileSaver.saveAs(blob, filename);
        } catch (e) {
            console.log(e);
            alert(e.message);
        }
    }

    componentDidMount() {
        this.props.getSiteSubjects(this.props.token, this.props.site._id);
        this.props.getSiteVisitDetails(this.props.token, this.props.site._id);
    }

    subjectOptions = () => {
        var subjects = []
        subjects = this.props.subjects.map((val, index) => {
            return ({
                value: val._id,
                label: val.uniqueCode
            })
        });
        this.setState({ subjects })
        return subjects;
    }

    render() {
        const { reports, invalidFrom, invalidTo } = this.state;
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" >Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Reports</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Reports</CardTitle>
                            <p>Download Audit Trials and Monitoring reports</p>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Nav pills className="nav-pills-info">
                                        {
                                            tabs.map((val, index) => {
                                                return (
                                                    <NavItem key={index}>
                                                        <NavLink
                                                            className={this.state.activeTab === `${val.id}` ? "active" : ""}
                                                            onClick={() => this.setState({ activeTab: `${val.id}`, editMode: false })}
                                                        >
                                                            {val.title}
                                                        </NavLink>
                                                    </NavItem>
                                                )
                                            })
                                        }
                                    </Nav>
                                    <TabContent
                                        activeTab={this.state.activeTab} style={{ border: '2px' }}
                                        className="tab-space">
                                        <TabPane tabId="ht1">
                                            <Row>
                                                <Col lg={3} sm={12}>
                                                    <FormGroup>
                                                        <Label>From Date</Label>
                                                        <Datetime
                                                            dateFormat="YYYY-MM-DD"
                                                            timeFormat={false}
                                                            closeOnSelect={true}
                                                            isValidDate={(current) => {
                                                                if (this.state.toDate) {
                                                                    return current.isSameOrBefore(this.state.toDate);
                                                                } else {
                                                                    return current.isAfter(Datetime.moment()) ? false : true
                                                                }

                                                            }}
                                                            onChange={(value) => {
                                                                this.setState({ fromDate: value, invalidFrom: false })
                                                            }}
                                                            inputProps={{ placeholder: "From Date" }}
                                                        />
                                                        <FormFeedback style={{ display: invalidFrom ? 'block' : 'none' }}>Please select from date.</FormFeedback>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>To Date</Label>
                                                        <Datetime
                                                            dateFormat="YYYY-MM-DD"
                                                            timeFormat={false}
                                                            closeOnSelect={true}
                                                            isValidDate={(current) => {
                                                                return current.isSameOrAfter(this.state.fromDate) && current.isSameOrBefore(Datetime.moment()) ? true : false
                                                            }}
                                                            onChange={(value) => {
                                                                this.setState({ toDate: value, invalidTo: false })
                                                            }}
                                                            inputProps={{ placeholder: "To Date", invalidTo: false }}
                                                        />
                                                        <FormFeedback style={{ display: invalidTo ? 'block' : 'none' }}>Please select to date.</FormFeedback>
                                                    </FormGroup>
                                                    <Button color="info" size="sm" className="btn btn-round" onClick={this.downloadMonitorReport}>Search Reports</Button>
                                                </Col>
                                            </Row>
                                            {
                                                reports ?
                                                    <Row>
                                                        <Col>
                                                            <RemoteTable
                                                                columns={[{
                                                                    dataField: 'name',
                                                                    text: 'Title',
                                                                    formatter: (cell, row, index) => {
                                                                        const doc = _.last(row.reportDocument)
                                                                        return `${doc.name}`
                                                                    }
                                                                },
                                                                {
                                                                    dataField: 'uploadedBy',
                                                                    text: 'Uploaded By',
                                                                    formatter: (cell, row) => {
                                                                        const doc = _.last(row.reportDocument)
                                                                        return `${doc.uploadedBy}`
                                                                    }
                                                                },
                                                                {
                                                                    dataField: 'uploadedDate',
                                                                    text: 'Uploaded Date',
                                                                    formatter: (cell, row) => {
                                                                        return moment(row.uploadedDate).format('MM/DD/YYYY')
                                                                    }
                                                                },
                                                                {
                                                                    dataField: 'url',
                                                                    text: 'Download',
                                                                    formatter: (cell, row) => {
                                                                        const doc = _.last(row.reportDocument)
                                                                        return <Button color="info" className="btn-round" outline size="sm" onClick={async () => await this.saveDocument(doc)}>Download</Button>
                                                                    }
                                                                }]}
                                                                data={reports}
                                                                page={1}
                                                                totalSize={reports.length}
                                                            />

                                                        </Col>
                                                    </Row>

                                                    : null
                                            }
                                        </TabPane>
                                        <TabPane tabId="ht2">
                                            <AvForm onValidSubmit={this.downloadAuditReport}>
                                                <Row>
                                                    <Col lg={3} sm={12}>
                                                        <AvGroup>
                                                            
                                                            <AvField className="minimal" type="select" name="subject" label="Trial Participant ID" validate={{
                                                                required: {value:true, errorMessage:'Please select a trail participant.'}
                                                            }}>
                                                                <option>Select</option>
                                                                <option value={'All'}>All</option>
                                                                {
                                                                    this.props.subjects && this.props.subjects.map(val =>
                                                                        <option key={val._id} value={val._id}>{val.uniqueCode}</option>
                                                                    )
                                                                }
                                                            </AvField>

                                                            {/* 
                                                                <Label>Trial Participant ID</Label>
                                                                <MultiSelect
                                                                options={subjectOptions}
                                                                overrideStrings={{ "allItemsAreSelected": "All Trail Participants", }}
                                                                value={subjects}
                                                                onChange={(value) => {
                                                                    console.log(this.state.subjects);
                                                                    console.log(value);
                                                                    this.setState({ subjects: value })
                                                                }}
                                                            />
                                                            <FormFeedback style={{ display: invalidSubject ? 'block' : 'none' }}>Please select a Trail Participant ID.</FormFeedback> */}
                                                        </AvGroup>
                                                        <AvGroup>
                                                            <AvField className="minimal" type="select" name="visit" label="Visit ID" validate={{
                                                                required: { value: true, errorMessage: 'Please select a visit.' }
                                                            }}>
                                                                <option>Select</option>
                                                                <option value={'All'}>All</option>
                                                                {
                                                                    this.props.visits && this.props.visits.map(val =>
                                                                        <option key={val._id} value={val._id}>{val.name}</option>
                                                                    )
                                                                }
                                                            </AvField>

                                                            {/* <Label>Visit ID</Label>
                                                            <MultiSelect
                                                                options={visitOptions}
                                                                overrideStrings={{ "allItemsAreSelected": "All Visits", }}
                                                                value={visits}
                                                                onChange={(value) => {
                                                                    this.setState({ visits: value })
                                                                }}
                                                            />
                                                            <FormFeedback style={{ display: invalidVisit ? 'block' : 'none' }}>Please select a Visit ID.</FormFeedback> */}
                                                        </AvGroup>
                                                        <Button color="info" size="sm" className="btn btn-round" type="submit">Search Reports</Button>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, sites }) => {
    return {
        token: user.token,
        subjects: sites.subjects,
        site: sites.current,
        visits: sites.visits
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSiteSubjects: (token, siteId) => SiteActions.getSiteSubjects(dispatch, token, siteId),
        getSiteVisitDetails: (token, siteId) => SiteActions.getSiteVisitDetails(dispatch, token, siteId),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports);