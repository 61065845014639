import Axios from 'axios';
import { SPONSER_URL, PAGE_SIZE } from 'api/list';

const getSponsors = (dispatch, token, start = 0, limit = PAGE_SIZE, count = false) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    if (count) {
        Axios.get(`${SPONSER_URL}/count`, { headers })
            .then((response) => {
                dispatch({
                    type: 'GET_SPONSORS_COUNT',
                    response: response.data
                });
            })
            .catch((e) => {
                if (e && e.response && e.response.status === 401) {
                    dispatch({
                        type: 'GET_TOKEN_ERROR',
                    });
                }
                console.log('error', e);
            });
    }

    Axios.get(`${SPONSER_URL}?_start=${start}&_limit=${limit}`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_SPONSORS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getSponsorDetails = (dispatch, token, id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${SPONSER_URL}/${id}`, { headers })
        .then((response) => {
            dispatch({
                type: 'SPONSOR_DETAILS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

export default {getSponsors, getSponsorDetails}

