import Axios from 'axios';
import { MONITOR_DOC_QUERIES_URL, MONITOR_QUERY_COMMENTS_URL, PAGE_SIZE } from 'api/list';

const getQueries = (dispatch, token, queryParams, dataIndex = 0) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    var countUrl = queryParams ? `${MONITOR_DOC_QUERIES_URL}/count?${queryParams}` : `${MONITOR_DOC_QUERIES_URL}/count`

    Axios.get(countUrl, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_QUERIES_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });

    var url = queryParams ? `${MONITOR_DOC_QUERIES_URL}?_start=${dataIndex}&_limit=${PAGE_SIZE}&${queryParams}` : `${MONITOR_DOC_QUERIES_URL}?_start=${dataIndex}&_limit=${PAGE_SIZE}`

    Axios.get(url, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_QUERIES',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
    
    
}

const getQueryDetails = (dispatch, token, id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_DOC_QUERIES_URL}/${id}`, { headers })
        .then((response) => {
            dispatch({
                type: 'QUERY_DETAILS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getQueryHistory = (dispatch, token, id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_QUERY_COMMENTS_URL}?monitorQuery._id=${id}`, { headers })
        .then((response) => {
            dispatch({
                type: 'QUERY_HISTORY',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

export default { getQueries, getQueryDetails, getQueryHistory }