import React, { useState } from 'react';
import Select from "react-select";
import { Row, Col, Label, FormGroup } from 'reactstrap';
import _ from 'lodash';
import folderImage from "assets/img/folder.svg";
import { Roles } from "constants/index.js";
import { Checkbox, withStyles } from '@material-ui/core';

const PrimaryCheckbox = withStyles({
    root: {
        color: '',
        '&$checked': {
            color: '#426cb5',
        },
        checked: {},
    },
})((props) => <Checkbox {...props} />);

const ChangeStudySite = (props) => {

    const [checkedB, setChecked] = useState(false)

    return (
        <div className="wrapper wrapper-full-page">
            <div className="content mx-auto">
                <Row>
                    <Col className="text-center">
                        <Row>
                            <img alt="." src={folderImage} style={{ width: '150px' }} className="mx-auto d-block" />
                        </Row>
                        <Row>
                            <Label style={{ fontWeight: 500 }} className="mx-auto d-block">Choose protocol from the available list</Label>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <div style={{ width: '320px' }} className="mx-auto d-block">
                        <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            placeholder="Select Clinical Trial"
                            styles={{ control: styles => ({ ...styles, backgroundColor: 'red', marginTop: '20px' }), }}
                            name="study"
                            defaultValue={props.study ? { value: props.study._id, label: props.study.name } : undefined}
                            options={
                                props.studies && _.uniqBy(props.studies, 'clinicalStudy._id').map(val => {
                                    const clinicalStudy = val.clinicalStudy
                                    return { value: clinicalStudy._id, label: clinicalStudy.name }
                                })
                            }
                            onChange={(value) => {
                                var study = props.studies.filter(val => val.clinicalStudy._id === value.value)[0]
                                props.onStudySelect(study);
                            }}
                        />

                        {
                            (props.role === Roles.CCRA) ?
                                <Select
                                    className="react-select info"
                                    classNamePrefix="react-select"
                                    placeholder="Select Hospital"
                                    styles={{ control: styles => ({ ...styles, backgroundColor: 'red', marginTop: '20px', width: '300px' }), }}
                                    name="study"
                                    width='300px'
                                    defaultValue={props.site ? { value: props.site._id, label: props.site.name } : undefined}
                                    options={
                                        props.sites && _.uniqBy(props.sites, 'studySite._id').map(val => {
                                            const studySite = val.studySite
                                            return { value: studySite._id, label: studySite.name }
                                        })
                                    }
                                    onChange={(value) => {
                                        var site = props.sites.filter(val => val.studySite._id === value.value)[0]
                                        props.onSiteSelect(site);
                                    }}
                                />
                                : null
                        }

                        {
                            (props.role === Roles.DM || props.role === Roles.PM || props.role === Roles.DE || props.role === Roles.CSM) ?
                                <Select
                                    className="react-select info"
                                    classNamePrefix="react-select"
                                    placeholder="Select Site"
                                    styles={{ control: styles => ({ ...styles, backgroundColor: 'red', marginTop: '20px', width: '300px' }), }}
                                    name="study"
                                    width='300px'
                                    defaultValue={props.site ? { value: props.site._id, label: props.site.name } : undefined}
                                    options={
                                        props.sites && props.sites.map(val => {
                                            return { value: val._id, label: val.name }
                                        })
                                    }
                                    onChange={(value) => {
                                        var site = props.sites.filter(val => val._id === value.value)[0]
                                        props.onSiteSelect(site);
                                    }}
                                />
                                : null
                        }
                    </div>
                </Row>
                <Row>
                    <FormGroup check className="mx-auto d-block">
                        <PrimaryCheckbox
                            checked={checkedB}
                            color="default"
                            onClick={e => {
                                setChecked(!checkedB)
                                if (!checkedB) {
                                    props.onAcceptGuidelines(true)
                                }
                            }}
                        />
                        <Label style={{marginTop:'5px'}}>Check to accept guidelines</Label>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="mx-auto d-block">
                        <Label className="mt-4 mb-0" tag="p" style={{ fontSize: '14px', fontWeigth: 700 }}>
                            Study missing from the list ? Email us at:
                            <b><a href="mailto:rsdv.support@quanticate.com">rsdv.support@quanticate.com</a></b>
                        </Label>
                    </FormGroup>
                </Row>
            </div>
        </div>
    );
}

export default ChangeStudySite;