import React from "react";
import { connect } from 'react-redux';
import * as moment from 'moment'

// reactstrap validations
import { AvForm, AvField } from 'availity-reactstrap-validation';
// reactstrap components
import {
    Button,
    Card,
    Breadcrumb,
    BreadcrumbItem,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";

// core components
import { Roles, Routes, QueryType, QueryStatusString, QueryTypeString, DATE_TIME_FORMAT, Validations } from 'constants/index.js';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DashboardLayout from "layouts/Admin";
import { QueryActions, SiteActions, DocumentActions } from "actions/index.js";

import {
    ImageQueryResponse,
    MetaDataQueryResponse,
    EDCQueryResponse,
    SDVQueryResponse,
    SourceDataQueryResponse
} from "./";

import { updateMonitorDocumentQuery, createMonitorQueryComments } from "api";
import { RenderImage } from "components";
import noImageAccess from "assets/img/noImageAccess.jpeg";

class ViewQueryResponse extends React.Component {

    state = {
        documentPopup: false,
        docUrl: null,
    }

    componentDidMount() {
        this.props.getQueryDetails(this.props.user.token, this.props.match.params.id)
        this.props.getQueryHistory(this.props.user.token, this.props.match.params.id)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.query !== this.props.query) {
            this.props.getSiteSubjects(this.props.user.token, this.props.query.studySite._id);
            this.props.getSiteVisits(this.props.user.token, this.props.query.studySite._id);
        }
        
        if (prevProps.responseHistory !== this.props.responseHistory) {
            const latestResponse = (this.props.responseHistory && this.props.responseHistory.length > 0) ?
                                    this.props.responseHistory[0] : null;
            if (latestResponse && latestResponse.attachment) {
                this.props.getResponseDocument(this.props.user.token, latestResponse.attachment);
            }
        }
    }

    onCancel = () => {
        this.props.history.push(`${Routes.QUERIES}/${this.props.match.params.id}`);
    }
    
    // check if user has access to the document on which query was raised
    hasDocAccess = (document) => {
        let staffType = [Roles.DM, Roles.DE, Roles.PM, Roles.CSM, Roles.CCRA].includes(this.props.role) ? 'EMPLOYEE' : 'SITE_STAFF';
        let hasAccess = false;
        if (document) {
            // check if there is atleast one version of the document has access
            //
            if (staffType === 'EMPLOYEE') {
                hasAccess = document.versionedDocument.findIndex(
                                version => 
                                    (version.uploadedBy.staffType === 'SITE_STAFF' && 
                                    version.employeeAccess === true)) >= 0;
            } else if (staffType === 'SITE_STAFF') {
                hasAccess = document.versionedDocument.findIndex(
                                version => 
                                    (version.uploadedBy.staffType === 'SITE_STAFF' && 
                                    version.siteStaffAccess === true)) >= 0;
            }
        }
        
        return hasAccess;
    }

    onReissueQuery = async (event, values) => {
        console.log(this.props.user);
        try {
            var commentsObj = {
                'monitorQuery': this.props.match.params.id,
                'comment': values.comments,
                'ownerStaff': this.props.user.staffProfile._id,
            }

            if (values.url) {
                commentsObj['attachment'] = values.url
            }

            await createMonitorQueryComments(this.props.user.token, commentsObj)
            this.props.history.push(`${Routes.QUERIES}/${this.props.match.params.id}`)
        } catch (e) {
            alert('Reissue Query failed!')
            console.log(e);
        }
    }

    onCloseQuery = async () => {
        try {
            await updateMonitorDocumentQuery(this.props.user.token, this.props.match.params.id, { 'queryStatus': 'CLOSED' })
            this.props.history.push(`${Routes.QUERIES}/${this.props.match.params.id}`);
        } catch (e) {
            alert('updateMonitorDocumentQuery with status closed failed!')
            console.log(e);
        }
    }

    getDocUrl = (document, version) => {    
        let docUrl = noImageAccess;
        if (document && document.versionedDocument) {
            if (!version) {
                for (let i = 0; i < document.versionedDocument.length; ++i) { 
                    // check if current user has access to the url
                    if (this.props.user && this.props.user.staffProfile.staffType === 'EMPLOYEE' &&
                        document.versionedDocument[i].employeeAccess) {
                        docUrl = document.versionedDocument[i].url;
                    }
                    
                    if (this.props.user && this.props.user.staffProfile.staffType === 'SITE_STAFF' &&
                        document.versionedDocument[i].siteStaffAccess) {
                        docUrl = document.versionedDocument[i].url;    
                    }
                }
            } else {
                if (this.props.user && this.props.user.staffProfile.staffType === 'EMPLOYEE' &&
                    document.versionedDocument[version - 1].employeeAccess) {
                    docUrl = document.versionedDocument[version - 1].url;
                }
                
                if (this.props.user && this.props.user.staffProfile.staffType === 'SITE_STAFF' &&
                    document.versionedDocument[version - 1].siteStaffAccess) {
                    docUrl = document.versionedDocument[version - 1].url;    
                }
            }
        }
        return docUrl;
    }
    
    canReissueOrClose = () => {
        if (
            this.props.user.role.name &&
            [Roles.CCRA, Roles.DM, Roles.PM].includes(this.props.user.role.name) &&
            this.props.query &&
            this.props.query.queryStatus === 'RESOLVED') {
            return true;
        }
        return false;
    }

    render() {

        const { query } = this.props;
        const { docUrl } = this.state;

        const latestResponse = (this.props.responseHistory && this.props.responseHistory.length > 0) ?
            this.props.responseHistory[0] : null;
            
        const hasAccess = query && query.monitorDocument ? this.hasDocAccess(query.monitorDocument) : true;

        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.QUERIES}`}>Queries</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.QUERIES}/${this.props.match.params.id}`}>Query Details </BreadcrumbItem>
                            <BreadcrumbItem active tag="span">View Response</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Modal
                        isOpen={this.state.documentPopup}
                        size="lg"
                        centered
                        toggle={async () => await this.setState({ documentPopup: false })}
                        backdrop="static"
                        keyboard={false}
                    >
                        <ModalHeader toggle={async () => await this.setState({ documentPopup: false })}>
                            Document Preview
                        </ModalHeader>
                        <ModalBody>
                            {
                                docUrl &&
                                <div style={{ borderColor: 'gray', borderWidth: '1px' }}>
                                    <RenderImage alt="." src={docUrl} /> :
                            </div>
                            }
                        </ModalBody>
                    </Modal>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Query Response</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {
                                        query &&
                                        <>
                                            <Row>
                                                <Col sm={5} xs={12}>
                                                    <FormGroup>
                                                        <Label>Query Description</Label>
                                                        <p>
                                                            {query.query}
                                                        </p>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Trial Participant ID</Label>
                                                        <p>{query.subject.uniqueCode}</ p>
                                                    </FormGroup>
                                                    {
                                                        query.monitorDocument &&
                                                        <FormGroup>
                                                            <Label>Data Domain</Label>
                                                            <p>{query.monitorDocument.domainData}</p>
                                                        </FormGroup>
                                                    }
                                                    <FormGroup>
                                                        <Label>Query Type</Label>
                                                        <p>{QueryTypeString[query.queryType]}</p>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Query Status</Label>
                                                        <p>{QueryStatusString[query.queryStatus]}</p>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Raised By</Label>
                                                        <p>{`${query.owner.firstName} ${query.owner.lastName}`}</p>
                                                    </FormGroup>
                                                    {
                                                        latestResponse &&
                                                        <>
                                                            <FormGroup>
                                                                <Label>Response</Label>
                                                                <p>{latestResponse.comment}</p>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label>Response By</Label>
                                                                <p>{`${latestResponse.ownerStaff.firstName} ${latestResponse.ownerStaff.lastName}`}</p>
                                                            </FormGroup>
                                                        </>
                                                    }
                                                    {

                                                        this.canReissueOrClose() ?
                                                            <AvForm onValidSubmit={
                                                                (event, values) => {
                                                                    this.onReissueQuery(event, values)
                                                                }}
                                                            >
                                                                <AvField name="comments" autoComplete="off"
                                                                    label="Re-Issue Query"
                                                                    type="textarea" validate={{
                                                                        required: { value: true, errorMessage: 'Please enter re-issue description.' },
                                                                        pattern: Validations.MULTI_LINE_ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                                                        minLength: { value: 6, errorMessage: 'Description must be at least 6 characters' },
                                                                        maxLength: { value: 500, errorMessage: 'Description must not be more than 500 characters.' },
                                                                    }} />
                                                                <Button color="info" className="btn-round btn-title" type="submit" size="sm">
                                                                    Re-issue Query
                                                        </Button>
                                                                <Button color="info ml-4" className="btn-round btn-title" type="button" size="sm" onClick={this.onCloseQuery}>
                                                                    Close Query
                                                        </Button>
                                                                <Button color="info ml-4" className="btn-round btn-title" size="sm" type="button" outline onClick={this.onCancel}>Back to Query</Button>
                                                            </AvForm> :
                                                            <Button color="info ml-4" className="btn-round btn-title" size="sm" type="button" outline onClick={this.onCancel}>Back to Query</Button>
                                                    }

                                                </Col>
                                                <Col sm={7} xs={12}>
                                                    {query && query.queryType === QueryType.IMAGE_QUERY &&

                                                        <ImageQueryResponse {...this.props} getDocUrl={this.getDocUrl}/>
                                                    }
                                                    {query && query.queryType === QueryType.SDV_QUERY &&
                                                        < SDVQueryResponse {...this.props} getDocUrl={this.getDocUrl}/>
                                                    }
                                                    {query && query.queryType === QueryType.EDC_QUERY &&
                                                        <EDCQueryResponse {...this.props} getDocUrl={this.getDocUrl}/>
                                                    }
                                                    {query && query.queryType === QueryType.META_DATA_QUERY &&
                                                        <MetaDataQueryResponse {...this.props} getDocUrl={this.getDocUrl}/>
                                                    }
                                                    {query && query.queryType === QueryType.SOURCE_DATA_QUERY &&
                                                        <SourceDataQueryResponse {...this.props} getDocUrl={this.getDocUrl}/>
                                                    }
                                                </Col>
                                            </Row>

                                            {
                                                this.props.responseHistory && this.props.responseHistory.length > 0 &&
                                                <Row className="mt-4 mb-4">
                                                    <Col>
                                                        <span style={{ fontWeight: 500, fontSize: '18px' }}>Response History</span>
                                                        {
                                                            this.props.responseHistory.map((val, index) => {
                                                                return (
                                                                    <Row key={index} height={110} className="mt-4 ml-2 border-bottom">
                                                                        <Col sm={1}>
                                                                            <RenderImage alt="." className="rounded-circle" width={48} height={48} style={{ maxHeight: 48, maxWidth: 48 }} 
                                                                                src={val.ownerStaff.profilePicture ? val.ownerStaff.profilePicture : require('assets/img/default_profile.jpg')} 
                                                                            />
                                                                        </Col>
                                                                        <Col sm={8}>
                                                                            <span style={{ fontSize: '14px', fontWeight: 700 }}>{`${val.ownerStaff.firstName} ${val.ownerStaff.lastName}`}</span><br />
                                                                            <Label style={{ fontSize: '12px', fontWeight: 700, marginBottom: '0px' }}>{moment(val.createdAt).format(DATE_TIME_FORMAT)}</Label>
                                                                            <p style={{ fontSize: '14px', fontWeight: 500 }}>{val.comment}</p>
                                                                        </Col>
                                                                        {
                                                                            val.attachment &&
                                                                            <Col sm={3}>
                                                                                <RenderImage alt="." className="rounded" width={48} height={48} style={{ maxHeight: 48, maxWidth: 48 }} 
                                                                                    src={hasAccess ? val.attachment : noImageAccess } 
                                                                                    onClick={() => {
                                                                                        this.setState({ 
                                                                                            documentPopup: true, docUrl: 
                                                                                            hasAccess ? val.attachment : noImageAccess
                                                                                        })
                                                                                    }} />
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                );
                                                            })
                                                        }
                                                    </Col>
                                                </Row>
                                            }
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout >
        );
    }
}

const mapStateToProps = ({ user, queries, sites, studies, documents }) => {
    return {
        user: user,
        query: queries.current,
        study: studies.current,
        site: sites.current,
        subjects: sites.subjects,
        visits: sites.visits,
        responseHistory: queries.history,
        responseDocument : documents.responseDocument
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getQueryDetails: (token, id) => QueryActions.getQueryDetails(dispatch, token, id),
        getQueryHistory: (token, id) => QueryActions.getQueryHistory(dispatch, token, id),
        getSiteSubjects: (token, siteId) => SiteActions.getSiteSubjects(dispatch, token, siteId),
        getSiteVisits: (token, siteId) => SiteActions.getSiteVisitDetails(dispatch, token, siteId),
        getResponseDocument : (token, url) => DocumentActions.getResponseDocument(dispatch, token, url)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewQueryResponse);