import React from 'react';
import { Row, Col, Label, FormGroup } from 'reactstrap';
// plugin that creates slider
import { Slider, Handles, Tracks, Ticks } from 'react-compound-slider'

const Handle = ({
    handle: { id, value, percent },
    backgroundColor,
    getHandleProps
}) => {
    return (
        <div
            style={{
                left: `${percent}%`,
                position: 'absolute',
                marginLeft: -10,
                marginTop: 30,
                zIndex: 2,
                width: 18,
                height: 18,
                border: 0,
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '50%',
                backgroundColor: backgroundColor,
                color: '#333',
            }}
            {...getHandleProps(id)}
        >
            <div style={{ fontFamily: 'Roboto', fontSize: 10, marginTop: -20, backgroundColor: backgroundColor, color: 'white', borderRadius: '4px' }}>
                {value}
            </div>
        </div>
    )
}

const Track = ({ source, target, backgroundColor, getTrackProps }) => {
    return (
        <div
            style={{
                position: 'absolute',
                height: 10,
                zIndex: 1,
                marginTop: 35,
                backgroundColor: backgroundColor ? backgroundColor : '#f00',
                borderRadius: 5,
                cursor: 'pointer',
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */}
        />
    )
}

const Tick = ({ tick, count }) => {
    return (
        <div>
            <div
                style={{
                    position: 'absolute',
                    marginTop: 52,
                    marginLeft: -0.5,
                    width: 1,
                    height: 8,
                    backgroundColor: 'silver',
                    left: `${tick.percent}%`,
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    marginTop: 60,
                    fontSize: 10,
                    textAlign: 'center',
                    marginLeft: `${-(100 / count) / 2}%`,
                    width: `${100 / count}%`,
                    left: `${tick.percent}%`,
                }}
            >
                {tick.value}
            </div>
        </div>
    )
}

const handleColors = ["#0db70c", "#e7ad3a"]

const RiskParameters = (props) => {
    
    var greenValue = props.data ? props.data.Green ? props.data.Green.to : 5 : 5;
    var amberValue = props.data ? props.data.Amber ? props.data.Amber.to : 25 : 25;

    const onSliderValueChange = (e) => {
        props.onChange({ Green: { from: 0, to: e[0] }, Amber: {from: e[0], to: e[1]}, Red: {from: e[1], to: 100} });
    }

    return (
        <>
            <Row>
                <Col>
                    <FormGroup>
                        <br />
                        <Label tag="h5">Define Risk Assessment Categorization</Label>
                        <Label>Use the sliders to define the risk categories. Define Green and Amber, rest will be red.</Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <Slider
                        disabled={props.disabled}
                        rootStyle={{ position: 'relative', height: 60 }}
                        domain={[0, 100]}
                        step={1}
                        mode={2}
                        values={[greenValue, amberValue]}
                        onChange={onSliderValueChange}>
                        <Handles>
                            {
                                ({ handles, getHandleProps }) => (
                                    <div className="slider-handles">
                                        {handles.map((handle, index) => (
                                            <Handle
                                                key={handle.id}
                                                handle={handle}
                                                backgroundColor={handleColors[index]}
                                                getHandleProps={getHandleProps}
                                            />
                                        ))}
                                    </div>
                                )
                            }
                        </Handles>
                        <Tracks right={true} left={true}>
                            {
                                ({ tracks, getTrackProps }) => (
                                    <div className="slider-tracks">
                                        {
                                            tracks.map(({ id, source, target }, index) => (
                                                <Track
                                                    key={id}
                                                    source={source}
                                                    target={target}
                                                    backgroundColor={handleColors[index]}
                                                    getTrackProps={getTrackProps}
                                                />
                                            ))
                                        }
                                    </div>
                                )
                            }
                        </Tracks>
                        <Ticks count={1}>
                            {({ ticks }) => (
                                <div className="slider-ticks">
                                    {ticks.map(tick => (
                                        <Tick key={tick.id} tick={tick} count={ticks.length} />
                                    ))}
                                </div>
                            )}
                        </Ticks>
                    </Slider>
                </Col>
            </Row>
            <Row>
                <Col sm={4} className="mt-5">
                    <Label tag="p"><i className="fa fa-circle" style={{ color: "#0db70c", paddingRight: '10px' }} aria-hidden="true"></i>Green less than {greenValue}%</Label>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Label tag="p"><i className="fa fa-circle" style={{ color: "#e7ad3a", paddingRight: '10px' }} aria-hidden="true"></i>Amber from {greenValue}% to {amberValue}%</Label>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Label tag="p"><i className="fa fa-circle" style={{ color: "#f00", paddingRight: '10px' }} aria-hidden="true"></i>Red greater than {amberValue}%</Label>
                </Col>
            </Row>
        </>
    );
}

export default RiskParameters;