import React from 'react';
import { Row, Col, Label, CardBody, CardHeader, CardTitle, Card } from 'reactstrap';
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const MetricCard = (props) => {

    return (
        <Card className="metrics-card">
            <CardHeader>
                <CardTitle>
                    <Label tag="h6">{props.label}</Label>
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col sm={4}>
                        <div className='mx-auto' style={{ width: "100px" }}>
                            <CircularProgressbarWithChildren
                                value={props.actual / props.total * 100}
                                strokeWidth={8}
                                styles={buildStyles({
                                    strokeLinecap: 'butt',
                                    textColor: 'black',
                                    pathColor: `#426CB5`,
                                    trailColor: 'rgba(66, 181, 169, .2)',
                                })} >
                                <div>{props.total > 0 ? Math.round(props.actual / props.total * 100) : 0}%</div>
                                <div className="text-center" style={{fontSize:'12px', width:'64px'}}>{props.measure}</div>
                            </CircularProgressbarWithChildren>
                        </div>
                    </Col>
                    <Col>
                        <Row style={{ lineHeight: 1, marginTop: '24px' }}>
                            <Col xs={1}>
                                <div className='circular-graph-legend' style={{ backgroundColor: "rgba(66, 181, 169, .2)" }}></div>
                            </Col>
                            <Col xs={7}>
                                <p style={{fontSize:12}}>{props.totalLabel}</p>
                            </Col>
                            <Col>
                                <p style={{fontSize:12}}>{props.total}</p>
                            </Col>
                        </Row>
                        <Row style={{ lineHeight: 1, marginTop: '24px' }}>
                            <Col xs={1}>
                                <div className='circular-graph-legend bg-info'></div>
                            </Col>
                            <Col xs={7}>
                                <p style={{fontSize:12}}> {props.actualLabel}</p>
                            </Col>
                            <Col>
                                <p style={{fontSize:12}}>{props.actual}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default MetricCard;