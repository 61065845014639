import React from "react";
import { connect } from 'react-redux';
import { Row, Col, Container } from 'reactstrap';
import { Link } from "react-router-dom";
import { Routes, Roles } from "constants/index.js";

// core components
// import bgImage from "assets/img/login-background.png";
import bgImage from "assets/img/home1.jpeg";

import logoutLogo from 'assets/img/log-out.svg';

import helpImage from "assets/img/help.svg";
import { StudyActions, SiteActions, UserActions } from 'actions'
import { ChangeStudySite } from "./Components";
import { updateStaff } from  "api/index";


class Protocol extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            study: null,
            site : null,
            accepted: false
        }
    }

    async componentDidMount() {
        if (this.props.user && this.props.user.staffProfile) {
            if (this.props.user.staffProfile.defaultStudySite) {
                this.props.getSiteDetails(this.props.token, 
                                          this.props.user.staffProfile.defaultStudySite);
            } else {
                this.props.getStudiesByStaff(this.props.token, this.props.user.staffProfile._id, this.props.user.role.name);
            }
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        // check if user already has a default site, then redirect to site dashboard
        if (this.props.user.staffProfile.defaultStudySite && 
            this.props.site !== prevProps.site && 
            this.props.site) {
            if(this.props.user.staffProfile.defaultStudySite === this.props.site.id &&
               this.props.site.isActive) {
                this.props.getStudyDetails(this.props.token, this.props.site.clinicalStudy.id);
                this.props.history.push(Routes.DASHBOARD);
            } else {
                this.props.getStudiesByStaff(this.props.token, this.props.user.staffProfile._id, this.props.user.role.name);
            }
        }
    }

    onStudySelect = async (study) => {
        this.props.getStudyDetails(this.props.token, study.clinicalStudy._id);
        switch (this.props.role) {
            case Roles.SITE_SCANNER_RESOLVER:
            case Roles.SITE_SCANNER:
                if (study.studySite) {
                    this.setState({ site : study.studySite, study : study.clinicalStudy});
                    this.props.getSiteDetails(this.props.token, study.studySite._id);
                    if (this.state.accepted) {
                        // update default study site for the user
                        await updateStaff(this.props.token, 
                            this.props.user.staffProfile.id,
                            {defaultStudySite : study.studySite._id});
                        this.props.history.push(Routes.DASHBOARD)
                    }
                }
                break;
            case Roles.CCRA:
                this.setState({study : study.clinicalStudy});
                break;
            case Roles.DM:
            case Roles.PM:
            case Roles.DE:
            case Roles.CSM:
                this.props.getActiveSites(this.props.token, study.clinicalStudy._id);
                break;
            default:
                break;
        }
    }

    onSiteSelect = async (site) => {
        switch (this.props.role) {
            case Roles.CCRA:
                this.setState({ site : site.studySite});
                this.props.getSiteDetails(this.props.token, site.studySite._id);
                if (this.state.study && this.state.accepted) {
                    // update default study site for the user
                    await updateStaff(this.props.token, 
                        this.props.user.staffProfile.id,
                        {defaultStudySite : site.studySite._id});
                    this.props.history.push(Routes.DASHBOARD)
                }
                break;
            case Roles.DM:
            case Roles.PM:
            case Roles.DE:
            case Roles.CSM:
                this.setState({ site : site});
                this.props.getSiteDetails(this.props.token, site._id);
                if (this.state.study && this.state.accepted) {
                    // update default study site for the user
                    await updateStaff(this.props.token, 
                        this.props.user.staffProfile.id,
                        {defaultStudySite : site._id});
                            
                    this.props.history.push(Routes.DASHBOARD);
                }
                break;
            default:
                break;
        }

    }

    onAcceptGuidelines = async (accepted) => {
        this.setState({ accepted })
        if (accepted && this.state.site) {
            // update default study site for the user
            await updateStaff(this.props.token, 
                this.props.user.staffProfile.id,
                {defaultStudySite : this.state.site._id});
            this.props.history.push(Routes.DASHBOARD);
        }
    }

    render() {
        const { study } = this.state
        const { studies, username, role, activeSites } = this.props;
        var sites = [];
        switch (role) {
            case Roles.SITE_SCANNER_RESOLVER:
            case Roles.SITE_SCANNER:
            case Roles.CCRA:
                sites = studies && study && studies.filter(val => val.clinicalStudy._id === study.id);
                break;
            case Roles.PM:
            case Roles.DM:
            case Roles.DE:
            case Roles.CSM:
                sites = activeSites;
                break;
            default:
                break;
        }

        // do not render until default site details are fetched.
        if (this.props.user.staffProfile.defaultStudySite && 
            (!this.props.site || !this.props.site._id)) {
            return (<div/>);
        }

        return (
            <Row style={{ backgroundColor: '#EDF0FA' }}>
                <Col xs={12} sm={6}>
                    <div className="wrapper wrapper-full-page">
                        <div className="full-page section-image">
                            <div className="content">
                                <div className="login-page">
                                    <Container>
                                        <Col xs={8} className="mx-auto">
                                            <span className="title" style={{ fontSize: '32px' }}>Welcome back, {username}</span>
                                            <p className="info-title">Please select the protocol to view your dashboard.</p>
                                        </Col>
                                    </Container>
                                </div>
                            </div>
                            <div
                                className="full-page-background"
                                style={{ backgroundImage: "url(" + bgImage + ")", }}
                            />
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={6}>
                     <Row>
                        <Col xs={6}>
                            <Link to="/"  className="nav-link" style={{ textTransform: 'none', color: "gray", verticalAlign: 'middle', cursor : 'pointer' }}>
                                <img alt="." src={helpImage} height="24" /> 
                                <span style={{ fontWeight: 'bold', margin : "10px" }}>Need Help ?</span>
                            </Link>
                        </Col>
                        <Col xs={6} className="text-right">
                            <Link to="/"  onClick={this.props.logout} className="nav-link" style={{ textTransform: 'none', color: "gray",  cursor : 'pointer', verticalAlign: 'middle' }}>
                                <img alt="." src={logoutLogo} height="24" /> 
                                <span style={{ fontWeight: 'bold', margin : "10px" }}>LogOut</span>
                            </Link>
                        </Col>
                    </Row>
                    <ChangeStudySite
                        role={role}
                        onStudySelect={this.onStudySelect}
                        onSiteSelect={this.onSiteSelect}
                        onAcceptGuidelines={this.onAcceptGuidelines}
                        studies={studies}
                        sites={sites}
                        site={this.state.site}
                        study={this.state.study}/>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ({ user, studies, sites }) => {
    return {
        token: user.token,
        user: user,
        role: user && user.role ? user.role.name : '',
        username: user ? user.staffProfile ? `${user.staffProfile.firstName} ${user.staffProfile.lastName}` : '' : '',
        studies: studies.myStudies,
        study: studies.study,
        activeSites: studies.activeSites,
        site: sites.current
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getStudiesByStaff: (token, staffId, role) => StudyActions.getStudiesByStaff(dispatch, token, staffId, role),
        getStudyDetails: (token, studyId) => StudyActions.getStudyDetails(dispatch, token, studyId),
        getSiteDetails: (token, siteId) => SiteActions.getSiteDetails(dispatch, token, siteId),
        logout: () => UserActions.logout(dispatch),
        getActiveSites: (token, studyId) => StudyActions.getActiveSites(dispatch, token, studyId)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Protocol);