import React from 'react';
// reactstrap validations
import { AvForm, AvField } from 'availity-reactstrap-validation';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import { DocTypes, } from "constants/index.js";
import { RenderImage } from 'components';
import { 
    updateCurrentMonitorDocument, 
    createMonitorQueryComments, 
} from "api/index.js";

const MetaDataQuery = (props) => {
    const data = props.query;
    const document = data.monitorDocument.versionedDocument && data.monitorDocument.versionedDocument.length > 0 && data.monitorDocument.versionedDocument[data.monitorDocument.versionedDocument.length - 1].url

    const onSubmitMetaData = async (event, values) => {
        const monitorDoc = props.query.monitorDocument;
        
        let newSubject = values.subject ? props.subjects.find( s => s._id === values.subject) : null;
        let newVisit = values.visit ? props.visits.find( v => v._id === values.visit) : null;
        
        let oldSubject = monitorDoc.subject ? props.subjects.find( s => s._id === monitorDoc.subject) : null;
        let oldVisit = monitorDoc.visit ? props.visits.find( v => v._id === monitorDoc.visit) : null;

        var commentsObj = {
            'monitorQuery': props.match.params.id,
            'comment': values.comments,
            'ownerStaff': props.user.staffProfile._id,
            'oldMeta': { 
                domainData: monitorDoc.domainData, 
                visit: oldVisit ? oldVisit.name : '', 
                subject: oldSubject ? oldSubject.uniqueCode : '', 
                docType: monitorDoc.docType 
            },
            'newMeta': { 
                domainData: values.domainData, 
                visit: newVisit ? newVisit.name : '', 
                subject: newSubject ? newSubject.uniqueCode : values.subject, 
                docType: values.docType 
            }
        }
        try {
            // create query comments
            await createMonitorQueryComments(props.token, commentsObj)
            // update monitor doc
            await updateCurrentMonitorDocument(props.token, monitorDoc._id, { 
                domainData: values.domainData, 
                visit: values.visit, 
                subject: values.subject, 
                docType: values.docType
            })
            props.onCancel();
        } catch (error) {
            console.log(error);
            alert('update meta data failed!')
        }
    }

    return (
        <Row>
            <Col md="12">
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5">Update Document Metadata</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <AvForm onValidSubmit={(event, values) => {
                            onSubmitMetaData(event, values)
                        }}>
                            <Row>
                                <Col sm={3}>
                                    <AvField name="query" autoComplete="off"
                                        disabled
                                        value={props.query && props.query.query}
                                        label="Query Description"
                                        type="textarea" />
                                    <AvField name="comments" autoComplete="off"
                                        required
                                        label="Response To Query"
                                        type="textarea"
                                        errorMessage="Please enter text in Response to Query."
                                        validate={{
                                            maxLength: { value: 500, errorMessage: 'Response must not be more than 500 characters.' }
                                        }}/>
                                    <AvField className="minimal" 
                                        type="select" 
                                        value={props.query.monitorDocument ? props.query.monitorDocument.docType : ''}
                                        name="docType" 
                                        label="Document Type" 
                                        errorMessage='Please select a type.'
                                    >
                                        <option>Select</option>
                                        {
                                            DocTypes.map((val, index) => {
                                                return (
                                                    <option value={val.value}>{val.label}</option>
                                                )
                                            })
                                        }
                                    </AvField>
                                    <AvField className="minimal" type="select" 
                                        value={props.query && props.query.subject ? props.query.subject._id : undefined} 
                                        name="subject" 
                                        label="Trial Participant ID" 
                                        errorMessage='Please select a Trial Participant.'
                                        required
                                    >
                                        <option>Select</option>
                                        {
                                            props.subjects && props.subjects.map(val =>
                                                <option key={val._id} value={val._id}>{val.uniqueCode}</option>
                                            )
                                        }
                                    </AvField>
                                    <AvField className="minimal" type="select" 
                                        name="domainData" 
                                        value={props.query.monitorDocument ? props.query.monitorDocument.domainData : ''}
                                        label="Data Domain"
                                        errorMessage='Please select a domain.'
                                        required
                                    >
                                        <option>Select</option>
                                        {
                                            props.query && props.query.studySite.domainData.map(val =>
                                                <option key={val} value={val}>{val}</option>
                                            )
                                        }
                                    </AvField>
                                    <AvField className="minimal" 
                                        type="select" 
                                        name="visit" label="Visit ID" 
                                        errorMessage='Please select a visit.'
                                        value={props.query.monitorDocument ? props.query.monitorDocument.visit : ''}
                                    >
                                        <option>Select</option>
                                        {
                                            props.visits && props.visits.map(val =>
                                                <option key={val} value={val._id}>{val.name}</option>
                                            )
                                        }
                                    </AvField>
                                     <Button color="info" className="btn-round btn-title" size="sm" type="submit">
                                        Submit
                                     </Button>
                                </Col>
                                <Col className="text-center">
                                    <div className="mx-auto mb-4 query-image" >
                                        <RenderImage alt="Monitor Document" src={document} />
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default MetaDataQuery;