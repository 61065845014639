import React from 'react';
import { Row, Col, Label, Badge, Progress } from 'reactstrap';
import moment from 'moment';

const SiteStats = ((props) => {
    
     const { subjectMetrics, documentMetrics, queryMetrics }  = props.metrics ? props.metrics : {};
    
    return (
        <>
            <Row style={{ marginTop: '20px' }}>
                <Col sm={3}>
                    <Label>No. of Trial Participants</Label>
                    <p>{subjectMetrics ? subjectMetrics.totalSubjects : ''}</p>
                </Col>
                <Col sm={2}>
                    <Label>Investigator</Label>
                    <p style={{ marginBlockEnd: '0px' }} >{props.site && props.site.investigator}</p>
                </Col>
                <Col sm={2}>
                    <Label>Clinical Trial Status</Label>
                    <p><Badge className={props.site && props.site.isActive ? "active-badge" : "inactive-badge"}>
                        {props.site && props.site.isActive? 'Active' : 'Inactive'}
                    </Badge></p>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    <Label>Unique ID for Trial Participants</Label>
                    <p>{props.subjectPrefix ? props.subjectPrefix : 'MANUAL'}</p>
                </Col>
                <Col sm={2}>
                    <Label>CRF Type</Label>
                    <p>{props.site ? props.site.crfType : ''}</p>
                </Col>
            </Row>
            <Row>
            {
                subjectMetrics &&
                <Col xs={8} className="my-3">
                    <div className="progress-container progress-primary">
                        <Progress max= {subjectMetrics.totalSubjects} value={subjectMetrics.totalSubjectsRecruited}>
                            <span className="progress-value" style={{ fontSize : '1.2em', margin: '10px'}}>{subjectMetrics.totalSubjects}</span>
                        </Progress>
                        <span className="progress-badge">No. of Trial Participants Recruited - {subjectMetrics.totalSubjectsRecruited}</span>
                    </div>
                </Col>
            }
            {
                documentMetrics &&
                <Col xs={8} className="my-3">
                    <div className="progress-container progress-warning ">
                        <Progress max={documentMetrics.totalDocuments} value={documentMetrics.totalUploaded}>
                             <span className="progress-value" style={{ fontSize : '1.2em', margin: '10px'}}>{documentMetrics.totalDocuments}</span>
                        </Progress>
                        <span className="progress-badge">No. of Documents Uploaded - {documentMetrics.totalUploaded}</span>
                        
                    </div>
                </Col>
            }
            {
                documentMetrics &&
                <Col xs={8} className="my-3">
                    <div className="progress-container progress-info">
                        <Progress max={documentMetrics.totalUploaded} value={documentMetrics.totalVerified}>
                             <span className="progress-value" style={{ fontSize : '1.2em', margin: '10px'}}>{documentMetrics.totalUploaded}</span>
                        </Progress>
                        <span className="progress-badge">No. of Documents Verified - {documentMetrics.totalVerified}</span>
                    </div>
                </Col>
            }
            </Row>  
            <Row style={{ marginTop: '20px' }}>
                <Col sm={6}>
                    <Row>
                        <Col sm={6}>
                            <Label>Number of Queries</Label>
                            <Label tag="h5" className='title'>{queryMetrics ? queryMetrics.totalQueries : 0}</Label>
                        </Col>
                        <Col sm={6}>
                            <Label>Longest Oustanding Query</Label>
                            <Label tag="h5" className='title'>
                                {
                                    queryMetrics && queryMetrics.longestOpenQuery ?
                                        moment(queryMetrics.longestOpenQuery.createdAt).fromNow() : 'N/A'
                                }
                             </Label>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
})

export default SiteStats