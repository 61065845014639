import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    FormGroup,
    Label,
    Button,
    CustomInput
} from "reactstrap";

// reactstrap validations
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';

export default function UploadSDVConfirm(props) {

    const [isUploading, setIsUploading] = useState(false);

    const handleValidSubmit = (event, values) => {
        setIsUploading(true)
        props.onUploadConfirmClick(values)
    }

    return (
        <Card style={{ minHeight: '756px' }}>
            <CardHeader>
                <CardTitle tag="h5">Confirm Document Upload</CardTitle>
            </CardHeader>
            <CardBody>
                <AvForm onValidSubmit={handleValidSubmit}>
                    <Row style={{ marginLeft: '0px' }}>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Site Name</Label>
                                <p>{props.site ? props.site.name : ''}</p>
                            </FormGroup>
                            <FormGroup>
                                <Label>Protocol</Label>
                                <p>{props.study ? props.study.protocolId : ''}</p>
                            </FormGroup>
                            <FormGroup>
                                <Label>Trial Participant ID</Label>
                                <p>{props.query && props.query.subject ? props.query.subject.uniqueCode : ''}</p>
                            </FormGroup>
                            <FormGroup>
                                <Label>Doc Type</Label>
                                <p>{props.document ? props.document.docType : ''}</p>
                            </FormGroup>
                            <FormGroup>
                                <Label>Description Of Image</Label>
                                <p>{props.document ? props.document.description : ''}</p>
                            </FormGroup>

                            <h6 style={{ marginTop: '50px' }}>Signature Copy</h6>
                            <AvInput tag={CustomInput} className="checkbox" type="checkbox" name="guidelines" label="Check to accept guidelines" required errorMessage="Please accept the guidelines." />
                            <AvField name="username" autoComplete="off" value={props.user.staffProfile ? `${props.user.staffProfile.firstName} ${props.user.staffProfile.lastName}` : ''} placeholder="Some" label="Name" type="text" disabled />
                            <AvField name="password" autoComplete="off" placeholder="Enter Password" label="Password" type="password" required errorMessage="Please enter your password." />
                        </Col>
                        <Col xs={12} md={12} lg={9}>
                            <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                                <Col xs={10}>
                                    <div className="text-center">
                                        <div style={{ borderColor: 'gray', borderWidth: '1px' }}>
                                            {
                                                props.file ?
                                                    <img
                                                        alt="." width={600} height={400}
                                                        src={props.file.previewUrl}
                                                    />
                                                    : null
                                            }
                                        </div>
                                        <div style={{ marginTop: '20px' }} className="text-center">
                                            <Button color="info" className="btn-round" size="sm" disabled={isUploading} type="submit" >{isUploading ? 'Please wait..' : 'Submit'}</Button>
                                            <Button color="info" className="btn-round ml-4" outline size="sm" type="button" onClick={props.onCancel} >Cancel</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </AvForm>
            </CardBody>
        </Card>
    );
};
