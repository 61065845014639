import React from "react";
// reactstrap components
import {
    Col,
    Table,
} from "reactstrap";

export default class MonitoringPlan extends React.Component {
    render() {
        return (
            <>
                <Col xs={3} md={3} lg={12}>
                    <Table responsive hover >
                        <thead className="info-title">
                            <tr>
                                <th>Domain ID</th>
                                <th>SCR1</th>
                                <th>V1</th>
                                <th>V2</th>
                                <th>V3</th>
                                <th>V4</th>
                                <th>V5</th>
                                <th>V6</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>ICF</td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                            </tr>
                            <tr>
                                <td>Randomisation</td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                            </tr>
                            <tr>
                                <td>Demography</td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                            </tr>
                            <tr>
                                <td>Medical History</td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                            </tr>
                            <tr>
                                <td>Concomitant Meds</td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                                <td><input type="radio" /></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </>
        )
    }
}
