import React, { useState } from 'react'
// reactstrap components
import {
    Nav,
    NavLink,
    NavItem,
    Row,
    Col,
    Label,
    Button,
    Input,
} from "reactstrap";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Validations } from "constants/index.js";
import Select from 'react-select';
const PublishSites = (props) => {

    // const { siteTab } = `site${props.activeSite + 1}`;
    // var formData = props.data;
    var siteType = props.siteType;
    var form;

    // const [activeSite, setActiveSite] = useState(siteTab)
    const [uniqueCodeType, setUniqueIDType] = useState('automatic');
    const [crfType, setCrfType] = useState('PAPER');
    const [site_select, setSiteSelect] = useState()
    
    // const [patientsCount, setPatientsCount] = useState()
    const [patientsList, setPatientsList] = useState([{ uniqueId: "" }]);
	
    function sitesInfo() {
        var sites = []
        for (var i = 0; i < props.sitesCount; i++) {
            const tabId = `site${i + 1}`
            sites.push(
                <NavItem key={tabId}>
                    <NavLink
                        key={tabId}
                        style={{ padding: '0px 8px', minWidth: '80px' }}
                        className={`site${props.activeSite + 1}` === `${tabId}` ? "active" : ""}>
                        Site {i + 1}
                    </NavLink>
                </NavItem>
            )
        }
        return sites;
    }

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...patientsList];
        list[index][name] = value;
        setPatientsList(list);
    };

    const onCreateTypeSelection = (e) => {
        setUniqueIDType(e.target.value)
    }
    
    const onCrfTypeSelection = (e) => {
        setCrfType(e.target.value);
    }

    const handleSubmit = (e, values) => {
        values['siteType'] = siteType;
        values['uniqueCodeType'] = uniqueCodeType;
        values['crfType'] = crfType;
        
        if (uniqueCodeType === 'manual') {
            var siteSubjects = []
            patientsList.map((val, i) => {
               return siteSubjects.push(patientsList[i][val.key])
            });
            
            values['siteSubjects'] = siteSubjects;
            values['subjectPrefix'] = 'PREFIX'
        }
        console.log(values);
        props.onCreateSite(values, form)
    }

    console.log(crfType);
    return (
        <>
            <Row>
                <Col sm={5}>
                    <h6>Create Hospital</h6>
                    <Label>There are {`${props.sitesCount}`} sites to be created. All the hospital are to created to proceed futher</Label>
                    <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <Nav pills className="nav-pills-primary">
                            {
                                sitesInfo()
                            }
                        </Nav>
                    </Row>
                    <Row>
                        <AvForm onValidSubmit={handleSubmit} ref={c => (form = c)}>
                            {
								props.sites && props.sites.length > 0 &&
                                <AvGroup check className="form-check-radio" inline>
                                    <Label check style={{ paddingLeft: '12px' }}>
                                        <Input
                                            value="select"
                                            name="siteType"
                                            type="radio"
                                            checked={siteType === 'select' ? true : false}
                                            onChange={(value) => {
                                                props.onSiteTypeChanged('select');
                                            }}
                                        />
                                        <span className="form-check-sign" />
                                        Select Hospital
                                    </Label>
                                    <Label check style={{ paddingLeft: '12px', marginLeft: '20px' }}>
                                        <Input
                                            value="create"
                                            name="siteType"
                                            type="radio"
                                            checked={siteType === 'create' ? true : false}
                                            onChange={(value) => {
                                                props.onSiteTypeChanged('create');
                                            }}
                                        />
                                        <span className="form-check-sign" />
                                        Create Hospital
                                    </Label>
                                </AvGroup>
                            }
                            {
                                siteType === 'select' ?
                                    <>
                                        {/* <AvField className="minimal" type="select" defaultValue={formData.site} placeholder="Hospital" name="site" label="Hospital" errorMessage='Please select a hospital.'
                                            required={siteType === 'select' ? true : false}>
                                            <option>Select</option>
                                            {
                                                props.sites && props.sites.map(val => {
                                                    return <option key={val._id} value={val._id}>{val.name}</option>
                                                })
                                            }
                                        </AvField> */}

                                        <AvGroup>
                                            <Label>Hospital</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder="Select Hospital"
                                                name="site_select"
                                                options={props.sites ?
                                                    props.sites.map((val, index) => {
                                                        return ({
                                                            value: val._id,
                                                            label: val.name
                                                        })
                                                    }) : []}
                                                onChange={(value) => { setSiteSelect(value.value) }}
                                            />
                                            <AvInput name="site" value={site_select} hidden autoComplete="off" required={siteType === 'select' ? true : false} />
                                            <AvFeedback>Please select a hospital.</AvFeedback>
                                        </AvGroup>
                                    </>
                                    : ''
                            }
                            {
                                siteType === 'create' ?
                                    <>
                                        <AvField name="name" autoComplete="off" label="Hospital Name" type="text" validate={{
                                            required: { value: true, errorMessage: 'Please enter a hospital name.' },
											pattern: Validations.ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
											minLength: { value: 6, errorMessage: 'Hospital name must be at least 6 characters' },
											maxLength: { value: 50, errorMessage: 'Hospital name must not be more than 50 characters.' },
                                        }} />
                                        <AvField name="address" autoComplete="off" label="Address" type="textarea" validate={{
											required: { value: true, errorMessage: 'Please enter an address.' },
											pattern: Validations.MULTI_LINE_ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
											minLength: { value: 4, errorMessage: 'Address must be at least 4 characters' },
											maxLength: { value: 100, errorMessage: 'Address must not be more than 100 characters.' }
										}} />
										<AvField name="contactPerson" autoComplete="off" label="Contact Person" type="text" validate={{
                                            required: { value: true, errorMessage: 'Please enter contact person name.' },
                                            pattern: Validations.ALPHABETS_WITH_SPACES,
											minLength: { value: 4, errorMessage: 'Contact person name must be at least 4 characters' },
											maxLength: { value: 50, errorMessage: 'Contact person name should be max 50 characters.' },
                                        }} />
                                        <AvField name="contactNumber" placeholder="+447911123456" autoComplete="off" label="Contact Number" type="text" validate={{
                                            required: { value: true, errorMessage: 'Please enter a contact number.' },
                                            pattern: Validations.TELEPHONE,
                                        }} />
                                        
										<AvField name="contactEmail" autoComplete="off" label="Contact Email" type="email" required validate={{
											required: { value: true, errorMessage: 'Please enter an email.' },
											email: { value: true, errorMessage: 'Please enter a valid email address.' },
											maxLength: { value: 50, errorMessage: 'Admin Email should not be more than 50 characters. ' },
										}} />
                                        <AvField name="designation" autoComplete="off" label="Designation" type="text" validate={{
                                            required: { value: true, errorMessage: 'Please enter a designation.' },
                                            pattern: Validations.ALPHABETS_WITH_SPACES,
                                            minLength: { value: 4, errorMessage: 'Designation must be at least 4 characters' },
											maxLength: { value: 20, errorMessage: 'Designation must not be more than 20 characters.' },
                                        }} />
                                    </>
                                    : ''
                            }
                            <Label> CRF Type </Label><br/>
                            <AvGroup check className="form-check-radio" inline required>
                                <Label check style={{ paddingLeft: '12px' }}>
                                    <Input
                                        value="PAPER"
                                        name="crfType"
                                        type="radio"
                                        checked={crfType === 'PAPER' ? true : false}
                                        onChange={onCrfTypeSelection}
                                    />
                                    <span className="form-check-sign" />
                                    Paper
                                </Label>
                                <Label check style={{ paddingLeft: '12px', marginLeft: '20px' }}>
                                    <Input
                                        value="ELECTRONIC"
                                        name="crfType"
                                        type="radio"
                                        checked={crfType === 'ELECTRONIC' ? true : false}
                                        onChange={onCrfTypeSelection}
                                    />
                                    <span className="form-check-sign" />
                                    Electronic
                                </Label>
                            </AvGroup>
                            
                            <AvField name="investigator" autoComplete="off" label="Investigator" type="text" validate={{
                                 required: { value: true, errorMessage: 'Please enter investigator name.' },
                                 pattern:Validations.ALPHABETS_WITH_SPACES,
								minLength: { value: 4, errorMessage: 'Investigator name must be at least 4 characters' },
								maxLength: { value: 30, errorMessage: 'Investigator name must not be more than 30 characters.' },
                            }} />
                            <AvField name="numSubjects" autoComplete="off" label="No. of Trial Participants" type="number" required validate={{
                                required: { value: true, errorMessage: 'Please enter number of trial participants.' },
                                min: { value: 1, errorMessage: 'At least 1 patient need to be created.' },
                                maxLength: { value: 3, errorMessage: 'Trial Participants cannot be more than 999.'}
                            }}
                                onChange={e => {
                                    var patientsList = []
                                    for (var i = 0; i < e.target.value; i++) {
                                        patientsList.push({ key: `uniqueCode${i}`, value: '' })
                                    }
                                    // setPatientsCount(e.target.value)
                                    setPatientsList(patientsList)

                                }} />
                            <AvGroup>
                                <Label>Generate UniqueID format for Trial Participants</Label>
                                <AvGroup check className="form-check-radio" inline>
                                    <Label check style={{ paddingLeft: '12px' }}>
                                        <Input
                                            value="automatic"
                                            name="createType"
                                            type="radio"
                                            checked={uniqueCodeType === 'automatic' ? true : false}
                                            onChange={onCreateTypeSelection}
                                        />
                                        <span className="form-check-sign" />
                                                    Generate Automatically
                                    </Label>
                                    <Label check style={{ paddingLeft: '12px', marginLeft: '20px' }}>
                                        <Input
                                            value="manual"
                                            name="createType"
                                            type="radio"
                                            checked={uniqueCodeType === 'manual' ? true : false}
                                            onChange={onCreateTypeSelection}
                                        />
                                        <span className="form-check-sign" />
                                                    Generate Manualy
                                    </Label>
                                </AvGroup>
                            </AvGroup>
                            {
                                (uniqueCodeType === 'automatic') &&
                                <AvField name="subjectPrefix" autoComplete="off" label="UniqueID format for Trial Participants" type="text" validate={{
                                    required: { value: true, errorMessage: 'Please enter an unique id.' },
                                    minLength: { value: 2, errorMessage: 'Unique id must be at least 2 characters' },
                                    maxLength: { value: 6, errorMessage: 'Unique id must not be more than 6 characters.'}
                                }} />}

                            {
                                (uniqueCodeType === 'manual') &&
                                patientsList.map((val, i) =>
                                    <AvField key={i} name={val.key} autoComplete="off" label={`${i + 1}.Patient ID`} type="text" validate={{
                                        required: { value: true, errorMessage: 'Please enter an id.' },
                                        maxLength: { value: 10, errorMessage: 'Patient id must not be more than 10 characters.' }
                                    }}
                                        onChange={e => handleInputChange(e, i)} />
                                )
                            }
                            <Button color="primary" style={{ marginLeft: '20px' }} className="btn-round" size="sm" type="submit">
                                {((props.activeSite + 1) < props.sitesCount) ? 'Create Hospital' : 'Finish'}
                            </Button>
                        </AvForm>
                    </Row>

                </Col>
            </Row>
        </>
    );
}

export default PublishSites