import React from 'react';
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
// reactstrap validations
import { AvForm, AvField } from 'availity-reactstrap-validation';
import 'tui-image-editor/dist/tui-image-editor.css'
import { DocTypes } from 'constants/index.js';
import BasicImageRedactor from './BasicImageRedactor';

function CertifyUploadDocument(props) {
    console.log(props,"propssssssssssssssssssssss")

    const document = props.details ? props.details : undefined;

    // const documentUrl = props.details && props.details[selectedImage].versionedDocument[props.details[selectedImage].versionedDocument.length - 1].url

    const handleValidSubmit = (event, values) => {
        console.log(values);
        const subject = props.subjects.filter(val => val._id === values.subject)[0];
        const visit = props.visits.filter(val => val._id === values.visit)[0];
        values['subject'] = subject;
        values['visit'] = visit;
        props.onDocumentDataCollected(values);
    }
 // console.log(props.file,"props.fileeeeeeeeeeeeeee")
    return (

        <Card style={{ minHeight: '726px' }}>
            <CardHeader>
                <CardTitle tag="h5">Certify Document Information</CardTitle>
            </CardHeader>
            <CardBody>
                <AvForm onValidSubmit={handleValidSubmit}>
                    <Row style={{ marginLeft: '0px' }}>
                        <Col xs={6} md={12} lg={3}>
                            <AvField name="site" autoComplete="off" value={props.site ? props.site.name : ''} placeholder="Site Name" label="Site Name" type="text" disabled />
                            <AvField name="protocol" autoComplete="off" value={props.study ? props.study.name : ''} placeholder="Protocol" label="Study Name" type="text" disabled />
                            <AvField className="minimal" type="select" value={document ? document.docType : undefined} name="docType" label="Document Type" errorMessage='Please select a doc type.'>
                                {
                                    DocTypes.map((val, index) => {
                                        return (
                                            <option key={val.value} value={val.value}>{val.label}</option>
                                        )
                                    })
                                }
                            </AvField>
                            <AvField className="minimal" type="select" value={document && document.subject ? document.subject._id : undefined} name="subject" label="Trial Participant ID" errorMessage='Please select a subject.'
                            >
                                {
                                    props.subjects && props.subjects.map(val =>
                                        <option key={val._id} value={val._id}>{val.uniqueCode}</option>
                                    )
                                }
                            </AvField>
                            <AvField className="minimal" type="select" value={document ? document.domainData : undefined} name="domainData" label="Domain Data" errorMessage='Please select a domain.'
                            >
                                <option>Select</option>
                                {
                                    props.site && props.site.domainData && props.site.domainData.map(val =>
                                        <option key={val} value={val}>{val}</option>
                                    )
                                }
                            </AvField>
                            <AvField className="minimal" type="select" value={document && document.visit ? document.visit._id : undefined} name="visit" label="Visit" errorMessage='Please select a visit.'
                            >
                                <option>Select</option>
                                {
                                    props.visits && props.visits.map(val =>
                                        <option key={val._id} value={val._id}>{val.name}</option>
                                    )
                                }
                            </AvField>
                        </Col>
                        <Col xs={12} md={12} lg={9}>
                            <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                                {
                                    props.file &&
                                    <Col xs={10}>
                                    <Modal 
                                        isOpen = {props.documentPopup} 
                                        size="lg" 
                                        centered 
                                        toggle={ async () => await props.toggle()} 
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <ModalHeader toggle={ async () => await props.toggle()}>
                                            Document Preview
                                        </ModalHeader>
                                        <ModalBody>
                                           <div style={{ borderColor: 'gray', borderWidth: '1px' }}>
                                                <img alt="Redact Document" src={props.file ? props.file.previewUrl : ''} />
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                        <div className="mx-auto query-image" >
                                            <BasicImageRedactor
                                                editMode={props.editMode}
                                                closeRedactWindow={props.closeRedactWindow}
                                                url={props.file ? props.file.previewUrl : undefined}
                                                saveRedactedDocument={async (image) => await props.saveRedactedDocument(image)} />
                                            <img
                                                onClick = {props.toggle}
                                                alt="." width={'600px'} height={'400px'} style={{ borderRadius: '8px' }}
                                                src={props.file ? props.file.previewUrl : undefined}
                                            />
                                        </div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button className="btn-round" color="info" size="sm" onClick={props.onEditClick}>
                                                    <i className="fa fa-pen mr-2" aria-hidden="true" style={{}}></i>
                                                    Edit/Redact
                                                </Button>
                                                <Button color="info" className="btn-round ml-4" size="sm" type="submit" >Upload</Button>
                                                <Button color="info" className="btn-round ml-4" outline size="sm" type="button" onClick={props.onCancel} >Cancel</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </Row>

                        </Col>
                    </Row>
                </AvForm>
            </CardBody>
        </Card>
    );
};

export default CertifyUploadDocument;
