import React from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    Breadcrumb,
    BreadcrumbItem,
} from "reactstrap";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin"
import { StaffActions } from "actions"
import { Routes, Roles, Designations } from 'constants/index.js';
import { RemoteTable } from "components";
import { PAGE_SIZE } from "api/list";
import StaffFields from "./StaffFields";

class Staff extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            staff: [],
            employeeType: { value: "All", label: "All" },
            staffCount:0,
            page: 1,
        };
        this.addUser = this.addUser.bind(this);
    }

    componentDidMount() {
        this.loadStaffData()
    }

    loadStaffData = (dataIndex = 0) => {
        // console.log(this.props.role,Roles.SYSTEM_ADMIN,"Roles.SYSTEM_ADMIN")
        if (this.props.role === Roles.SYSTEM_ADMIN || this.props.role === Roles.CCRA)

            this.props.getStaff(this.props.token, undefined, dataIndex);
        else if (this.props.role === Roles.SITE_SCANNER ||
            this.props.role === Roles.SITE_SCANNER_RESOLVER)
            this.props.getSiteSSandSSR(this.props.token, this.props.site._id, dataIndex);
        else {
            console.log(this.props.role,Roles.SYSTEM_ADMIN,"in3333333333333333333")

            if (this.props.site && this.props.site._id && this.props.study && this.props.study._id) {
                this.props.getSiteStaff(this.props.token, this.props.site._id, dataIndex, PAGE_SIZE, true);
                if (dataIndex > this.props.siteStaffCount) {
                    this.props.getStudyStaff(this.props.token, this.props.study._id, 
                        (dataIndex - this.props.siteStaffCount), PAGE_SIZE, true);
                } else if ((dataIndex + PAGE_SIZE) > this.props.siteStaffCount || dataIndex === 0) {
                    this.props.getStudyStaff(this.props.token, this.props.study._id, 0, PAGE_SIZE, true);
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.role === Roles.SYSTEM_ADMIN || this.props.role === Roles.CCRA)
                this.setState({staff: this.props.staff, staffCount : this.props.totalSize})
            else {
                this.setState({
                    staff: this.props.siteStaff.concat(this.props.studyStaff), 
                    staffCount : this.props.siteStaffCount + this.props.studyStaffCount
                })
            }
        }
    }

    addUser() {
        this.props.history.push(`${Routes.STAFF}/add`)
    }

    onItemClick = (e, row, index) => {
        switch (this.props.role) {
            case Roles.SYSTEM_ADMIN:
                this.props.history.push(`${Routes.STAFF}/${row._id}`);
                break;
            case Roles.CCRA:
                this.props.history.push(`${Routes.STAFF}/view/${row._id}`);
                break;
            case Roles.SITE_SCANNER_RESOLVER:
            case Roles.SITE_SCANNER:
            case Roles.PM:
            case Roles.DM:
            case Roles.DE:
            case Roles.CSM:
                this.props.history.push(`${Routes.STAFF}/view/${row.staffProfile._id}`);
                break;
            default:
                return
        }

    }

    handleTableChange = (type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;
        this.setState({ page })
        this.loadStaffData(currentIndex)
    }

   

    render() {
        var { role } = this.props
        var { staff, employeeType, page, staffCount } = this.state

        var staffFilter = [{ value: "All", label: "All" }]
        Designations.map(val => {
            return staffFilter.push(val)
        });

        staff = employeeType.value !== 'All' ? staff.filter(val => val.user && val.user.role === employeeType.value) : staff;
        if (staff.length > PAGE_SIZE) staff.length = PAGE_SIZE;
        
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">User Accounts</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col sm={12}>
                                            <div>
                                                <CardTitle tag="h5" className="float-left mt-2">User Accounts</CardTitle>
                                                {
                                                    role === Roles.SYSTEM_ADMIN &&
                                                    <Button className="btn-round float-right" color="info" onClick={this.addUser} size="sm">
                                                        Add New User
                                                            </Button>
                                                }
                                                {
                                                    (role === Roles.SYSTEM_ADMIN || role === Roles.CCRA) &&
                                                    <div className="float-right mt-2 mr-2" style={{ lineHeight: 1, width: '160px' }} >
                                                        <Select
                                                            className="react-select info"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Employee Type"
                                                            name="singleSelect"
                                                            value={employeeType}
                                                            options={staffFilter}
                                                            onChange={(value) => {
                                                                this.setState({ employeeType: value })
                                                                
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <RemoteTable
                                        data={staff}
                                        sizePerPage={PAGE_SIZE}
                                        page={page}
                                        totalSize={staffCount}
                                        columns={StaffFields(role, this.state)}
                                        onItemClick={this.onItemClick}
                                        onTableChange={this.handleTableChange}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, staff, sites, studies }) => {
    return {
        token: user.token,
        role: user.role ? user.role.name : undefined,
        staff: staff.data || [],
        siteStaff : staff.siteStaff || [],
        siteStaffCount : staff.siteStaffCount,
        studyStaff : staff.studyStaff || [],
        studyStaffCount : staff.studyStaffCount,
        site: sites.current,
        study: studies.current,
        totalSize: staff.count
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getStaff: (token, params, dataIndex) => StaffActions.getStaff(dispatch, token, params, dataIndex),
        getSiteStaff: (token, siteId, start, limit, activeOnly) => 
            StaffActions.getSiteStaff(dispatch, token, siteId, start, limit, activeOnly),
        getStudyStaff: (token, studyId, start, limit, activeOnly) => 
            StaffActions.getStudyStaff(dispatch, token, studyId, start, limit, activeOnly),
        getSiteSSandSSR: (token, siteId, dataIndex) => StaffActions.getSiteSSandSSR(dispatch, token, siteId, dataIndex)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Staff);
