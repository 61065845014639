import React from 'react';
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import { Chip } from 'components';
// used for making the prop types of this component
import PropTypes from "prop-types";

const CRFPages = (props) => {

    function pages() {
        var pages = [];
        for (var i = 0; i < props.totalCRFPages; i++) {
            pages.push(
                <Chip
                    key={i}
                    id={i + 1}
                    disabled={props.disabled}
                    checked={props.selectedPages.indexOf(`${i + 1}`) > -1}
                    name={`CRF Page ${i + 1}`}
                    onCheckedChange={props.onCRFDataChange} />
            );
        }
        return pages;
    }

    return (
        <>
            <Row>
                <Col sm={4}>
                    <FormGroup>
                        <Label>Specify No. Of Pages</Label>
                        <Input type="number" disabled={props.disabled} value={props.totalCRFPages} onChange={props.onTotalCRFPagesChange} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={8} style={{ marginTop: '20px' }}>
                    <FormGroup>
                        <Label>Select Pages</Label>
                        <Row>
                            {
                                pages()
                            }
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
}

CRFPages.propTypes = {
    totalCRFPages: PropTypes.number,
    selectedPages: PropTypes.array,
    disabled: PropTypes.bool,
    checked: PropTypes.bool
};

export default CRFPages