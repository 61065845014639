import Axios from 'axios';
import { STAFF_URL, SITE_STAFF_PIVOT_URL, PAGE_SIZE } from 'api/list';

const getStaff = (dispatch, token, params, start = 0, limit = PAGE_SIZE) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    var countUrl = params ? `${STAFF_URL}/count?${params}` : `${STAFF_URL}/count`

    Axios.get(countUrl, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_STAFF_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });

    var url = params ? `${STAFF_URL}?${params}&_start=${start}&_limit=${limit}` : `${STAFF_URL}?_start=${start}&_limit=${limit}`

    Axios.get(url, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_STAFF',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getStaffDetails = (dispatch, token, id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${STAFF_URL}/${id}`, { headers })
        .then((response) => {
            dispatch({
                type: 'STAFF_DETAILS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getProjectManagers = (dispatch, token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${STAFF_URL}/?user.role.name=Clinical Trial Manager`, { headers })
        .then((response) => {
            dispatch({
                type: 'PROJECT_MANAGERS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getSiteStaff = (dispatch, token, siteId, start = 0, limit=PAGE_SIZE, activeOnly=true) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    
    // get site level staff and study level staff
    Axios.get(`${SITE_STAFF_PIVOT_URL}/count?studySite.id=${siteId}&accessLevel=STUDY_SITE${activeOnly ? '&isAccess=true' : ''}`, { headers })
        .then((response) => {
            let data = response.data;
            dispatch({
                    type: 'GET_SITE_STAFF_COUNT',
                    response: data
                });
            })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
        
    Axios.get(`${SITE_STAFF_PIVOT_URL}?_start=${start}&_limit=${limit}&studySite.id=${siteId}&accessLevel=STUDY_SITE${activeOnly ? '&isAccess=true' : ''}`, { headers })
        .then((response) => {
            let data = response.data;
                dispatch({
                    type: 'GET_SITE_STAFF',
                    response: data
                });
            })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getStudyStaff = (dispatch, token, studyId, start = 0, limit=PAGE_SIZE, activeOnly=true) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    Axios.get(`${SITE_STAFF_PIVOT_URL}/count?accessLevel=CLINICAL_STUDY${activeOnly ? '&isAccess=true' : ''}&clinicalStudy.id=${studyId}`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_STUDY_STAFF_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
    
    // get only study level staff
    Axios.get(`${SITE_STAFF_PIVOT_URL}?_start=${start}&_limit=${limit}&accessLevel=CLINICAL_STUDY${activeOnly ? '&isAccess=true' : ''}&clinicalStudy.id=${studyId}`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_STUDY_STAFF',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getSiteSSandSSR = (dispatch, token, siteId, start = 0, limit=PAGE_SIZE) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    Axios.get(`${SITE_STAFF_PIVOT_URL}/count?studySite._id=${siteId}&isAccess=true&role.name_in=Site Staff Scanner and Resolver&role.name_in=Site Staff Scanner`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_SITE_STUDY_STAFF_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
    
    Axios.get(`${SITE_STAFF_PIVOT_URL}?_start=${start}&_limit=${limit}&studySite._id=${siteId}&isAccess=true&role.name_in=Site Staff Scanner and Resolver&role.name_in=Site Staff Scanner`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_SITE_STUDY_STAFF',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}


export default { getStaff, getStaffDetails, getProjectManagers, getSiteStaff, getSiteSSandSSR, getStudyStaff }