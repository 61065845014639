import React from 'react';
import { Label, Row, Col } from 'reactstrap';
import {RenderImage} from 'components';

const SponsorInfo = (props) => {

    const { name, contactNumber, address, contactName, designation, logo } = props.data ? props.data : {};
    return (
        <>
            <Row>
                <Col xs={8} md={9} lg={10} >
                    <Label tag="p" className="info-title" style={{ marginTop: '15px' }}>Company Details</Label>
                </Col>
            </Row>
            <Row>
                
                {
                    logo &&
                    <Col sm={4}>
                        <RenderImage
                            alt="..." style={{ maxHeight: '72px' }}
                            src={logo}
                        />
                    </Col>
                }
                <Col sm={4}>
                    <Label>Company's Name</Label>
                    <p>{name}</p>
                </Col>
                <Col sm={4}>
                    <Label>Contact Number</Label>
                    <p>{contactNumber}</p>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Label>Corporate Address</Label>
                    <p>{address}</p>
                </Col>
                <Col sm={4}>
                    <Label>Contact Person</Label>
                    <p>{contactName}</p>
                </Col>
                <Col sm={4}>
                    <Label>Designation</Label>
                    <p>{designation}</p>
                </Col>
            </Row>
        </>
    );
}

export default SponsorInfo