import React from "react";
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
// reactstrap components
import {  Row } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import { Roles } from "constants/index.js";
import { Sidebar, SiteScannerResolverSidebar, SiteScannerSidebar, CCRASidebar, PMSidebar, DMSidebar, DESidebar, CSMSidebar } from "./Components";

var ps;

class RootSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.sidebar = React.createRef();
    }

    componentDidMount() {
        if (this.sidebar.current && navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.sidebar.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }
    }
    
    componentWillUnmount() {
        if (this.sidebar.current && navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
        // to stop the warning of calling setState of unmounted component
        var id = window.setTimeout(null, 0);
        while (id--) {
            window.clearTimeout(id);
        }
    }

    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = (routes) => {
        return routes.map((prop, key) => {
            return (
                <li className={this.activeRoute(prop.path)} key={key}>
                    <NavLink to={prop.path} activeClassName="">
                        <Row className="d-flex align-items-center" style={{ marginLeft: '15px' }}>
                            <img alt="..." src={prop.icon} width={24} height={24} />
                            <p style={{ marginLeft: '15px', fontSize: '14px' }}>{prop.name}</p>
                        </Row>
                    </NavLink>
                </li>
            );
        });
    };

    // verifies if routeName is the one active (in browser input)
    activeRoute = (routeName) => {
        return window.location.href.indexOf(routeName) > -1 ? "active" : "";
    };

    render() {
        const { role } = this.props;
        return (
            <div ref={this.sidebar}>
                {
                    (role === Roles.SYSTEM_ADMIN) ?
                        <Sidebar {...this.props} />
                        : null
                }
                {
                    (role === Roles.SITE_SCANNER) ?
                        <SiteScannerSidebar {...this.props} />
                        : null
                }
                {
                    (role === Roles.SITE_SCANNER_RESOLVER) ?
                        <SiteScannerResolverSidebar {...this.props} />
                        : null
                }
                {
                    (role === Roles.CCRA) ?
                        <CCRASidebar {...this.props} />
                        : null
                }
                {
                    (role === Roles.PM) ?
                        <PMSidebar {...this.props} />
                        : null
                }
                {
                    (role === Roles.DM) ?
                        <DMSidebar {...this.props} />
                        : null
                }
                {
                    (role === Roles.DE) ?
                        <DESidebar {...this.props} />
                        : null
                }
                {
                    (role === Roles.CSM) ?
                        <CSMSidebar {...this.props} />
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = ({ user, studies, sites }) => {
    return {
        role: user.role ? user.role.name : undefined,
        user: user,
        study: studies.current,
        site: sites.current
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RootSidebar);