import React from "react";
import { connect } from 'react-redux';
// react plugin used to create switch buttons
import DashboardLayout from "layouts/Admin"

// reactstrap components
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Label,
    Row,
    Col,
} from "reactstrap";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import { PanelHeader } from "components";
import { StudyActions, SponsorActions, StaffActions } from "actions";
import { Routes } from "constants/index.js";
import { updateStudyStatus } from "api";
import sponsorsIcon from "assets/img/sites.svg"
import studyTypesIcon from "assets/img/staff.svg"
import staffIcon from "assets/img/studies.svg"
import { RemoteTable } from "components";
import { sponsorTableColumns, studyTableColumns } from './TableColumns';
import { PAGE_SIZE } from "api/list";

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alert : null,
            details: [],
            disabled: false,
            sponsorPage : 1,
            studyPage : 1,
            
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.confirmStudyStatus = this.confirmStudyStatus.bind(this);
  
    }
    
    componentDidMount() {
        this.props.getSponsors(this.props.token, 0, PAGE_SIZE, true);
        this.props.getStudies(this.props.token, 0, PAGE_SIZE, true);
        this.props.getStaff(this.props.token);
    }

    hideAlert() {
        this.setState({
            alert: null,
        });
    }
    
    handleTableChange = (type, paging) => {
        let start = (paging.page - 1) * paging.sizePerPage;
        if (type === 'sponsor') {
            this.props.getSponsors(this.props.token, start, paging.sizePerPage);
            this.setState({ sponsorPage : paging.page });
        } else if (type === 'study') {
            this.props.getStudies(this.props.token, start, paging.sizePerPage);
            this.setState({ studyPage : paging.page });
        }
    }

    confirmUpdateStudyStatus = (status, id) => {
        const { token } = this.props;
        updateStudyStatus(token, id, status).then(res => {
            this.hideAlert();
            this.props.getStudies(token);
        }).catch(e => console.log(e))
    }


    confirmStudyStatus = (status, studyId) => {
        console.log(status);
        const title = status ? 'Activate' : 'Deactivate'
        this.setState({
            alert: (
                <SweetAlert
                    title=''
                    style={{ minHeight: '200px' }}
                    closeOnClickOutside={false}
                    allowEscape={false}
                    onConfirm={() => this.confirmUpdateStudyStatus(status, studyId)}
                    onCancel={this.hideAlert}
                    confirmBtnBsStyle="primary btn-round btn-sm"
                    cancelBtnBsStyle="outline-primary btn-round btn-sm"
                    confirmBtnText={title}
                    cancelBtnText="Cancel"
                    showCancel
                >
                    <h6>{title}</h6>
                    Are you sure you want to {title} this study?
                </SweetAlert>
            )
        });
    }

    render() {

        return (
            <DashboardLayout>
                <PanelHeader size="sm" />
                <div className="content">
                    {this.state.alert}
                    <Row>
                        <Col lg="4" sm="6" href={Routes.SPONSORS} tag="a">
                            <Card className="card-stats">
                                <CardBody className="dashboard-card bg-blue">
                                    <div className="statistics statistics-horizontal">
                                        <div className="info info-horizontal">
                                            <Row className="d-flex align-items-center">
                                                <Col className="text-left" xs="9">
                                                    <h1 className="dashboard-card-title">{this.props.sponsorCount}</h1>
                                                    <Label className="dashboard-card-subtitle">Total Sponsors</Label>
                                                </Col>
                                                <Col xs="3">
                                                    <div className="bg-blue-light icon icon-circle" style={{ boxShadow: 'none' }}>
                                                        <img alt="..." src={sponsorsIcon} style={{ margin: '20px' }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" sm="6" tag="a" href={Routes.STUDIES}>
                            <Card className="card-stats">
                                <CardBody className="dashboard-card bg-green">
                                    <div className="statistics statistics-horizontal">
                                        <div className="info info-horizontal">
                                            <Row className="d-flex align-items-center">
                                                <Col className="text-left" xs="9">
                                                    <h1 className="dashboard-card-title">{this.props.studiesCount}</h1>
                                                    <Label className="dashboard-card-subtitle">Total Studies</Label>
                                                </Col>
                                                <Col xs="3">
                                                    <div className="icon icon-circle bg-green-light" style={{ boxShadow: 'none' }}>
                                                        <img alt="..." src={studyTypesIcon} style={{ margin: '20px' }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" sm="6" tag="a" href={Routes.STAFF}>
                            <Card className="card-stats">
                                <CardBody className="dashboard-card bg-pink">
                                    <div className="statistics statistics-horizontal">
                                        <div className="info info-horizontal">
                                            <Row className="d-flex align-items-center">
                                                <Col className="text-left" xs="9">
                                                    <h1 className="dashboard-card-title">{this.props.staffCount}</h1>
                                                    <Label className="dashboard-card-subtitle">Total Users</Label>
                                                </Col>
                                                <Col xs="3">
                                                    <div className="icon icon-circle bg-pink-light" style={{ boxShadow: 'none' }}>
                                                        <img alt="..." src={staffIcon} style={{ margin: '20px' }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" sm="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle className="sys-card-title">List of companies</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <RemoteTable
                                        columns={sponsorTableColumns((this.state.sponsorPage - 1) * PAGE_SIZE)}
                                        onTableChange={(type, paging) => this.handleTableChange('sponsor', paging)}
                                        data={this.props.sponsors}
                                        onItemClick={(event, row, rowIndex) => this.props.history.push(`${Routes.SPONSORS}/${row._id}`)}
                                        page={this.state.sponsorPage}
                                        sizePerPage={PAGE_SIZE}
                                        totalSize={this.props.sponsorCount}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="6" sm="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle className="sys-card-title">List of Studies</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <RemoteTable
                                        columns={studyTableColumns(
                                            ((this.state.studyPage - 1) * PAGE_SIZE), 
                                            this.confirmStudyStatus, 
                                            this.props.history)
                                        }
                                        onTableChange={(type, paging) => this.handleTableChange('study', paging)}
                                        data={this.props.studies}
                                        //onItemClick={ (event, row, rowIndex) => this.props.history.push(`${Routes.STUDIES}/${row._id}`)}
                                        page={this.state.studyPage}
                                        sizePerPage={PAGE_SIZE}
                                        totalSize={this.props.studiesCount}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout >
        );
    }

}

const mapStateToProps = ({ user, sponsors, studies, staff }) => {
    return {
        role: user.role ? user.role.name : '',
        user: user,
        token: user.token,
        sponsors: sponsors.data || [],
        studies: studies.data || [],
        study: studies.study,
        staff: staff.data,
        staffCount : staff.count,
        sponsorCount : sponsors.count,
        studiesCount : studies.count
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSponsors: (token, start, limit, count=false) => SponsorActions.getSponsors(dispatch, token, start, limit, count),
        getStudies: (token, start, limit, count=false) => StudyActions.getStudies(dispatch, token, '', start, limit, count),
        getStaff: (token) => StaffActions.getStaff(dispatch, token)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

