import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

class PanelHeader extends React.Component {
  
  render() {
    const { children } = this.props;

    return (
      <div
        className={
          "panel-header " +
          (this.props.size !== undefined
            ? "panel-header-" + this.props.size
            : "")
        }
      >
        {children}
      </div>
    );
  }
}

PanelHeader.defaultProps = {
  size: undefined,
};

PanelHeader.propTypes = {
  // size of the panel header
  size: PropTypes.oneOf(["sm", "lg", undefined]),
  children: PropTypes.node
};

export default PanelHeader;
