import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Col,
    Row,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    CardTitle,
    CardSubtitle,
    Navbar,
    Nav,
    NavItem,
} from "reactstrap";
import { AvForm, AvFeedback, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { getToken } from 'api/index';
import { Routes, Roles } from 'constants/index.js';
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// core components
import nowLogo from "assets/img/logo.png";
import scienslogo from "assets/img/scienslogo.png";
// import bgImage from "assets/img/login-background.png";
import bgImage from "assets/img/home1.jpeg";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            passwordFocus: '',
            identifierFocus: '',
            showPassword: false
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    }

    validatePassword = (value, context) => {
        const password = value;
        const regex = new RegExp('^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{8,}$');
        return regex.test(password)
    }
    
    handleValidSubmit(event, values) {
        const { identifier, password } = values;
        getToken(identifier, password).then((response) => {
            this.props.saveToken(response.data);
            const user = response.data.user;

            if (this.props.location.callback) {
                this.props.history.push(this.props.location.callback.pathname);
                return;
            }
            if (this.props.history && user) {
                console.log(user.role.name,"user.role.name")
                switch (user.role.name) {

                    case Roles.SYSTEM_ADMIN:
                        this.props.history.push(Routes.DASHBOARD);
                        break;
                    case Roles.SITE_SCANNER_RESOLVER:
                    case Roles.SITE_SCANNER:
                    case Roles.CCRA:
                    case Roles.PM:
                    case Roles.DM:
                    case Roles.DE:
                    case Roles.CSM:
                        this.props.history.push(Routes.PROTOCOL);
                        break;
                    default:
                        return
                }
            }
        })
            .catch((e) => {
                const m = e.response ? e.response.data.message[0].messages[0].message : '';
                this.setState({
                    alert: (
                        <SweetAlert
                            warning
                            title="Alert"
                            onConfirm={this.hideAlert}
                        >
                            {m}
                        </SweetAlert>
                    )
                });
            });
    }

    render() {
        const { showPassword } = this.state;
        return (
            <Fragment>
                <Navbar
                    className={"navbar-transparent navbar-absolute"}>
                    <Container fluid>
                        <Nav className="ml-auto" navbar>
                            <NavItem >
                                <Link to="/" className="nav-link" style={{ textTransform: 'none' }}>
                                    <i className="fa fa-question-circle" aria-hidden="true" /> Need Help?
                                </Link>
                            </NavItem>
                        </Nav>
                    </Container>
                </Navbar>
                <div className="wrapper wrapper-full-page">
                    <div className="full-page section-image">
                        <div className="content" style={{"overflow":"hidden"}}>
                            {this.state.alert}
                            <div className="login-page">
                                <Container>
                                    <Col xs={12} md={8} lg={5}>
                                        <Card className="card-login card-plain">
                                            <CardHeader>
                                                <div className="logo-container">
                                                    <img src={scienslogo} alt="now-logo" />
                                                </div>
                                                <CardTitle>
                                                    <span className="title" style={{ fontSize: '24px' }}>Remote Source Data Verification</span>
                                                </CardTitle>
                                                <CardSubtitle style={{ color: 'white' }}>Please enter your account credentials</CardSubtitle>
                                            </CardHeader>
                                            <CardBody>
                                                <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
                                                    <AvGroup>
                                                        <InputGroup className={
                                                            this.state.identifierFocus ? "input-group-focus" : ""
                                                        }>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fa fa-envelope" aria-hidden="true" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <AvInput name="identifier" autoComplete="off" placeholder="Email" type="email" validate={{
                                                                required: true,
                                                                email: true
                                                            }}
                                                                onFocus={(e) =>
                                                                    this.setState({ identifierFocus: true })
                                                                }
                                                                onBlur={(e) =>
                                                                    this.setState({ identifierFocus: false })
                                                                }
                                                                style={{ minHeight: '45px' }} />
                                                            <AvFeedback name="identifier">Please enter a valid email address.</AvFeedback>
                                                        </InputGroup>
                                                    </AvGroup>
                                                    <AvGroup>
                                                        <InputGroup className={
                                                            this.state.passwordFocus ? "input-group-focus" : ""
                                                        }>
                                                            
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText><i className="fa fa-lock" aria-hidden="true" /></InputGroupText>
                                                            </InputGroupAddon>
                                                            <AvInput autoComplete="off" name="password" placeholder="Password" type={showPassword ? "text" : "password"}
                                                                validate={{
                                                                    required: true,
                                                                    // async: this.validatePassword
                                                                }}
                                                                onChange={this.validatePassword}
                                                                onFocus={(e) =>
                                                                    this.setState({ passwordFocus: true })
                                                                }
                                                                onBlur={(e) =>
                                                                    this.setState({ passwordFocus: false })
                                                                }
                                                                style={{ minHeight: '45px', borderRight: 'none', borderRadius: '0px', maxWidth:'330px' }} />
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText style={{ paddingRight: '18px', borderTopRightRadius: '30px', borderBottomRightRadius: '30px' }} onClick={() => { this.setState({ showPassword: !showPassword }) }}><i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true" /></InputGroupText>
                                                            </InputGroupAddon>
                                                            <AvFeedback name="password">Password must be atleast 8 characters with minimum - 1 uppercase, 1 lowercase, 1 digit and 1 special character(#?!@$%^&*-).</AvFeedback>
                                                        </InputGroup>
                                                        
                                                    </AvGroup>
                                                    <Row>
                                                        <Col>
                                                            <div className="pull-right">
                                                                <a href="/forgetpassword" style={{ fontSize: '14px', color: 'white' }}>
                                                                    Reset Password
                                                                </a>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="info" className="btn-round">Login</Button>
                                                </AvForm>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Container>
                            </div>
                        </div>
                        <div
                            className="full-page-background"
                            style={{ backgroundImage: "url(" + bgImage + ")", backgroundSize: '100% 100%' }}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // getToken: (identifier, password) => getToken(dispatch, identifier, password)
        saveToken: (data) => dispatch({
            type: 'GET_TOKEN',
            response: { ...data }
        }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);