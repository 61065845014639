import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Col, Breadcrumb, BreadcrumbItem,
    CardHeader, CardTitle, Card, Row,
    Table,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import { Routes } from "constants/index.js";
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin";

import { MetricCard } from "./Components";
import { SiteActions, DashboardActions } from "actions";

class Metrics extends React.Component {
    constructor() {
        super();
        this.state = {
            uploadData: true,
            overMetrics: false,
            disabled: false,
            activeTab: 'ht1',
            activePage: 1,
            itemsPerPage: 5,
            startIndex: 0,
            endIndex: 5,
        }
    }

    componentDidMount() {
        const { token, site } = this.props;
        if (site && site._id) {
            this.props.getMyMetrics(token, site._id);
            this.props.getSiteSubjects(token, site._id);
            this.props.getSiteVisitDetails(token, site._id);
        }
    }

    handlePageChange = (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
    }
    
    getRAGSubjectStatus = (subject, visit) => {
        const { site, data } = this.props;
        if (site && data) {
            let ragStatus = data.subjectRAGStatus;
            let totalQueries = 0, openQueries = 0;
            ragStatus.map (status => {
                if (status.subject === subject && status.visit === visit) {
                    totalQueries += status.queryCount;
                    if (['OPENED', 'RE_OPENED'].includes(status.queryStatus)) {
                        openQueries += status.queryCount;
                    }
                }
                
                return null;
            });
            
           
            if (totalQueries) {
                // check Red, Amber, Green status
                let percent = (openQueries/totalQueries) * 100;
                if (parseFloat(site.riskAssessment.Green.from) <= percent && percent <= parseFloat(site.riskAssessment.Green.to)) {
                    return "bg-success";
                } else if (parseFloat(site.riskAssessment.Amber.from) <=  percent && percent <= parseFloat(site.riskAssessment.Amber.to)) {
                    return "bg-warning";
                } else if (parseFloat(site.riskAssessment.Red.from) <=  percent && percent <= parseFloat(site.riskAssessment.Red.to)) {
                    return "bg-danger";
                } else {
                    return "bg-success";
                }
            }
            
            return "bg-success";
        }
    }

    render() {
        const { data, subjects, visits } = this.props;
      
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={Routes.DASHBOARD}>Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Metrics</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5">Metrics</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={4}>
                                    <MetricCard label={"Trial Participants Recruited"} totalLabel={'Total Trial Participants'} actualLabel={'Trial Participants Recruited'} measure={'Recruited'} total={data && data.subjectMetrics.totalSubjects} actual={data && data.subjectMetrics.totalSubjectsRecruited} />
                                </Col>
                                <Col xs={4}>
                                    <MetricCard label={"Pages Uploaded"} totalLabel={'Total Pages'} actualLabel={'Pages Uploaded'} measure={'Uploaded'} total={data && data.documentMetrics.totalDocuments} actual={data && data.documentMetrics.totalUploaded} />
                                </Col>
                                <Col xs={4}>
                                    <MetricCard label={"Pages SDVd"} totalLabel={'Pages Uploaded'} actualLabel={'Pages SDVd'} measure={'SDVd'} total={data && data.documentMetrics.totalUploaded} actual={data && data.documentMetrics.totalVerified} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={10} xs={12}>
                                    <Nav pills className="nav-pills-info">
                                        <NavItem>
                                            <NavLink
                                                className={this.state.activeTab === `ht1` ? "active" : ""}
                                                onClick={() => this.setState({ activeTab: `ht1` })}>
                                                Overall Metrics
                                        </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={this.state.activeTab === `ht2` ? "active" : ""}
                                                onClick={() => this.setState({ activeTab: `ht2` })}>
                                                Trial Participant Level Metrics
                                        </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent
                                        activeTab={this.state.activeTab} style={{ border: '2px' }}
                                        className="tab-space">
                                        <TabPane tabId="ht1">
                                            
                                            <Row style={{ marginTop: '40px' }}>
                                                <Col>
                                                    <Table>
                                                        <thead className="info-title">
                                                            <tr>
                                                                <th>Title</th>
                                                                <th className="text-center">Stats</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Trial Participants Recruited</td>
                                                                <td className="text-right">{data && data.subjectMetrics.totalSubjectsRecruited}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Pages Uploaded</td>
                                                                <td className="text-right">{data && data.documentMetrics.totalUploaded}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Pages With Queries</td>
                                                                <td className="text-right">{data && data.queryMetrics.totalPagesWithQueries}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ paddingLeft: '60px', border: '0px' }}>Meta Data Queries</td>
                                                                <td style={{ border: '0px' }} className="text-right">{data && data.queryMetrics.pagesWithMetaQueries}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ paddingLeft: '60px', border: '0px' }}>Image Queries</td>
                                                                <td style={{ border: '0px' }} className="text-right">{data && data.queryMetrics.pagesWithImageQueries}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Pages DE Completed</td>
                                                                <td className="text-right">{data && data.documentMetrics.totalDECompleted}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Pages SDVd</td>
                                                                <td className="text-right">{data && data.documentMetrics.totalVerified}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Longest outstanding query</td>
                                                                <td className="text-right">
                                                                    {
                                                                        data && data.queryMetrics && data.queryMetrics.longestOpenQuery ?
                                                                            moment(data.queryMetrics.longestOpenQuery.createdAt).fromNow() : 'N/A'
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Days from query raised to resolution</td>
                                                                <td className="text-right">{data && data.queryMetrics.avgResolveTime}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Days from upload to SDV</td>
                                                                <td className="text-right">{data && data.documentMetrics.avgSVDTime}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="ht2">
                                            <h6>RAG Status</h6>
                                            <Row>
                                                <Col>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>Trial Participant ID</th>
                                                                <th>Metrics</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                        {
                                                            subjects && visits &&
                                                            subjects.map (subject => {
                                                                return (
                                                                    <tr>
                                                                        <td>{subject.uniqueCode}</td>
                                                                        <td>
                                                                            <Row style={{ justifyContent: 'space-between' }}>
                                                                            {
                                                                                visits.map( visit => {
                                                                                    let className = "col-xs-1 patient-metric " +  
                                                                                                    this.getRAGSubjectStatus(subject.uniqueCode, visit.name);
                                                                                    return (
                                                                                        <div className={className}>{visit.name}</div>
                                                                                    );
                                                                                })
                                                                            }
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, dashboard, studies, sites }) => {
    return {
        token: user.token,
        data: dashboard.metrics,
        study: studies.current,
        site: sites.current,
        subjects: sites.subjects,
        visits : sites.visits
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getMyMetrics: (token, siteId) => DashboardActions.getSSRMetrics(dispatch, token, siteId),
        getSiteSubjects: (token, id) => SiteActions.getSiteSubjects(dispatch, token, id),
        getSiteVisitDetails: (token, id) => SiteActions.getSiteVisitDetails(dispatch, token, id),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Metrics);