import React from 'react';
import { Row, Col } from 'reactstrap';
import RemoteTable from 'components/RemoteTable/RemoteTable';
import { PAGE_SIZE } from 'api/list';

const Documents = (props) => {

    return (
        <Row>
            <Col>
                <RemoteTable
                    columns={[{
                        dataField: 'name',
                        text: 'Document Name',
                    },
                    {
                        dataField: 'description',
                        text: 'Description',
                    }]}
                    onTableChange={props.handleProcessDocTableChange}
                    data={props.documents ? props.documents : []}
                    onItemClick={props.onDocumentItemClick}
                    page={props.page}
                    sizePerPage={PAGE_SIZE}
                    totalSize={props.totalSize}
                />
            </Col>
        </Row>
    );
}

export default Documents