import validator from 'validator';

export const Routes = {
    DASHBOARD: '/dashboard',
    SPONSORS: '/sponsors',
    SITES: '/sites',
    STAFF: '/staff',
    STUDIES: '/studies',
    PROFILE: '/profile',
    QUERIES: '/queries',
    STUDY: '/study',
    METRICS: '/metrics',
    DOCUMENTS: '/documents',
    REPORTS: '/reports',
    PROTOCOL: '/protocol',
    SEARCH: '/search',
    STUDYINFORMATION: '/studyinformation',
}

export const Roles = {
    // SYSTEM_ADMIN: 'System Admin',
    // SITE_SCANNER_RESOLVER: 'Site Staff Scanner and Resolver',
    // SITE_SCANNER: 'Site Staff Scanner',
    // CCRA: 'cCRA',
    // PM: 'Project Manager',
    // DM: 'Data Manager',
    // DE: 'Data Entry Staff',
    // CSM: 'CSM'
     SYSTEM_ADMIN: 'Admin',
    SITE_SCANNER_RESOLVER: 'Hospital Staff Scanner and Resolver',
    SITE_SCANNER: 'Hospital Staff Scanner',
    CCRA: 'Auditor',
    PM: 'Clinical Trial Manager',
    DM: 'Data Manager',
    DE: 'Data Entry Staff',
    CSM: 'CSM'
}

export const Designations = [
    { value: Roles.SYSTEM_ADMIN, label: Roles.SYSTEM_ADMIN },
    { value: Roles.SITE_SCANNER_RESOLVER, label: Roles.SITE_SCANNER_RESOLVER },
    { value: Roles.SITE_SCANNER, label: Roles.SITE_SCANNER },
    { value: Roles.CCRA, label: Roles.CCRA },
    { value: Roles.PM, label: Roles.PM },
    { value: Roles.DM, label: Roles.DM },
    { value: Roles.DE, label: Roles.DE },
    { value: Roles.CSM, label: Roles.CSM }
]

export const Domains = [
    { value: "ICF", label: "ICF" },
    { value: "Randomisation", label: "Randomisation" },
    { value: "Demography", label: "Demography" },
    { value: "Medical History", label: "Medical History" },
    { value: "Concomitant Meds", label: "Concomitant Meds" },
    { value: "Adverse Events", label: "Adverse Events" },
    { value: "Vital Signs", label: "Vital Signs" },
    { value: "Lab Parameters", label: "Lab Parameters" },
]

export const DocTypes = [
    { value: "ICF", label: "ICF" },
    { value: "CRF", label: "CRF" },
    { value: "SOURCE", label: "SOURCE" },
]

export const PMDocTypes = [
    { value: "CRF", label: "CRF" },
    { value: "SOURCE", label: "SOURCE" },
]

export const QueryTypeString = {
    IMAGE_QUERY: 'IMAGE QUERY',
    SOURCE_DATA_QUERY: 'SOURCE DATA QUERY',
    META_DATA_QUERY: 'META DATA QUERY',
    SDV_QUERY: 'SDV QUERY',
    EDC_QUERY: 'EDC QUERY'
}

export const QueryType = {
    IMAGE_QUERY: 'IMAGE_QUERY',
    SOURCE_DATA_QUERY: 'SOURCE_DATA_QUERY',
    META_DATA_QUERY: 'META_DATA_QUERY',
    SDV_QUERY: 'SDV_QUERY',
    EDC_QUERY: 'EDC_QUERY'
}

export const QueryTypes = [
    { value: 'IMAGE_QUERY', label: "Image Query" },
    { value: 'SOURCE_DATA_QUERY', label: "Source Data Query" },
    { value: 'META_DATA_QUERY', label: "Meta Data Query" },
    { value: 'SDV_QUERY', label: "SDV Query" },
    { value: 'EDC_QUERY', label: "EDC Query" },
]

export const QueryStatusString = {
    OPENED: 'OPENED',
    RESOLVED: 'RESOLVED',
    RE_OPENED: 'RE OPENED',
    CLOSED: 'CLOSED',
    CANCELLED: 'CANCELLED'
}

export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm A'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const DEFAULT_PASSWORD = 'Test@123'

export const Validations = {
    ALPHABETS: {
        value: new RegExp('^[a-zA-Z]*$'),
        errorMessage: 'Only alphabets are allowed.'
    },
    ALPHABETS_WITH_SPACES: {
        value: new RegExp('^[a-zA-Z. ]*$'),
        errorMessage: 'Only alphabets, full stops and spaces are allowed.'
    },
    ALPHABETS_WITH_SPECIAL_CHARS: {
        value: new RegExp('^[A-Za-z \'_.]*$'),
        errorMessage: 'Only alphabets, spaces, \', -, _ and . are allowed.'
    },
    ALPHA_NUMERCIS: {
        value: new RegExp('^[A-Za-z0-9]*$'),
        errorMessage: 'Only alphanumerics are allowed.'
    },
    ALPHA_NUMERICS_WITH_SPECIAL_CHARS: {
        value: new RegExp('^[a-zA-Z0-9- \'._]*$'),
        errorMessage: 'Only alphanumerics, spaces, \', -, _ and . are allowed.'
    },
    MULTI_LINE_ALPHA_NUMERICS_WITH_SPECIAL_CHARS: {
        // eslint-disable-next-line
        value: new RegExp('^[a-zA-Z-,\,_ /\\,/\\\\\'.0-9\\n\]*$'),
        errorMessage: 'Only alphanumerics, spaces, commas, \\, /, \', -, _ and . are allowed.'
    },
    TELEPHONE: (value, ctx) => {
        var phoneNumber = ctx.contactNumber || ctx.mobileNo
        if (phoneNumber) {
            const isValidPhoneNumber = validator.isMobilePhone(phoneNumber)
            if (isValidPhoneNumber) {
                return true;
            }
        }
        return "Please enter a valid phone number";

    },
    POSITIVE_NUMBERS: {
        value: new RegExp('^\\d+$'),
        errorMessage: 'Only numbers greater than 0 are allowed.'
    },
    PASSWORD: {
        values: new RegExp('^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{8,}$'),
        errorMessage: 'Password must be atleast 8 characters with minimum - 1 uppercase, 1 lowercase, 1 digit and 1 special character(#?!@$%^&*-)'
    }
}