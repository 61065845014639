import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Button,
    Label,
} from "reactstrap";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Routes, Roles, Validations } from 'constants/index.js';
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin"
import { createProcessDocs } from "api";
import { uploadFileToStrapi } from 'commons';
import { checkFileSize } from "commons/Utils";
import cogoToast from "cogo-toast";

class UploadMonitorDocument extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            study_id: null,
            fileName: '',
            isUploading: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            study_id: this.props.match.params.id
        })
    }

    async onFileChange(event) {
        const file = event.target.files[0];
        const validFile = checkFileSize(file, 10)
        if (validFile.valid) {
            this.setState({ file: file, fileName: file.name })
        } else {
            cogoToast.warn(validFile.message)
        }

    }

    async handleSubmit(event, values) {

        const { file, fileName } = this.state;
        const studyId = this.props.match.params.id;
        this.setState({ isUploading: true })
        try {
            const response = await uploadFileToStrapi(this.props.token, file, fileName)
            await createProcessDocs(this.props.token, { name: values.name, description: values.description, url: response.data[0].url, docLevel: 'CLINICAL_STUDY', clinicalStudy: studyId });
            this.props.history.push(`${Routes.STUDIES}/${studyId}`);
        } catch (error) {
            alert('Doc creation failed');
            console.log(error);
            this.setState({ isUploading: false })
        }

    }

    render() {
        const { role } = this.props;
        const studyId = this.props.match.params.id;
        const { isUploading } = this.state;

        return (
            <>
                <DashboardLayout>
                    <PanelHeader size="sm" >
                        <Col>
                            <Breadcrumb tag="nav" listTag="div">
                                <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                                {
                                    (role === Roles.SYSTEM_ADMIN) ?
                                        <>
                                            <BreadcrumbItem tag="a" href={`${Routes.STUDIES}`}>Clinical Trials</BreadcrumbItem>
                                            <BreadcrumbItem tag="a" href={`${Routes.STUDIES}/${studyId}`}>Clinical Trial Details</BreadcrumbItem>
                                        </>
                                        : null
                                }
                                {
                                    (role === Roles.SITE_SCANNER_RESOLVER || role === Roles.SITE_SCANNER || role === Roles.CCRA) ?
                                        <>
                                            <BreadcrumbItem tag="a" href={`${Routes.STUDIES}/${studyId}`}>Clinical Trial Protocol</BreadcrumbItem>
                                        </>
                                        : null
                                }
                                <BreadcrumbItem active tag="span">Upload Document</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    </PanelHeader>
                    <div className="content">
                        <Row>
                            <Col md="12">
                                <Card style={{ minHeight: '755px' }}>
                                    <CardHeader>
                                        <CardTitle tag="h4">Upload Document</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm onValidSubmit={this.handleSubmit}>
                                            <Row>
                                                <Col sm={4}>
                                                    <AvField name="name" autoComplete="off" label="Document Name" type="text"
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter document name.' },
                                                            pattern: Validations.ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                                            minLength: { value: 4, errorMessage: 'Document name must be at least 4 characters' },
                                                            maxLength: { value: 50, errorMessage: 'Document name must not be more than 50 characters.' },
                                                        }} />
                                                    <AvField name="description" autoComplete="off" label="Document Description" type="textarea"
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter Document description.' },
                                                            minLength: { value: 4, errorMessage: 'Document Description must be at least 4 characters' },
                                                            maxLength: { value: 500, errorMessage: 'Description must not be more than 500 characters.' }
                                                        }} />
                                                    <AvGroup>
                                                        <i className=" fa fa-arrow-up text-info" aria-hidden="true" style={{ cursor: 'pointer' }} />
                                                        <Label htmlFor="file" className="text-info" style={{ marginLeft: '10px', cursor: 'pointer' }}> Upload Document</Label>
                                                        <AvInput type="file" name="file" style={{ cursor: 'pointer' }} onChange={this.onFileChange} validate={{
                                                            required: true,
                                                        }} />
                                                        <AvFeedback name="file">Please select a Document.</AvFeedback>
                                                    </AvGroup>
                                                    <p className="text-primary">{this.state.fileName}</p>
                                                    <Button color="info" disabled={isUploading} className="btn-round" type="submit" size="sm">{isUploading ? 'Please wait..' : 'Upload'}</Button>
                                                    <Button color="info" outline className="btn-round" size="sm"
                                                        type="button"
                                                        onClick={() => this.props.history.goBack()}
                                                        style={{ marginLeft: '20px' }}>
                                                        Cancel
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </DashboardLayout>
            </>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return {
        token: user.token,
        role: user && user.role ? user.role.name : ''
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadMonitorDocument);