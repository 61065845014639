import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    BreadcrumbItem,
    Row,
    Col,
    Label,
    Breadcrumb,
} from "reactstrap";

import * as moment from "moment";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DashboardLayout from "layouts/Admin";
import { Routes, QueryType, DATE_TIME_FORMAT } from 'constants/index.js';

import { 
    AnswerImageQuery, 
    AnswerSDVQuery, 
    AnswerMetaDataQuery,
    AnswerEDCQuery
} from "./";
import { QueryActions, SiteActions } from "actions/index.js";
import { RenderImage } from 'components';

class AnswerQuery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: undefined
        }
    }

    componentDidMount() {
        this.props.getQueryDetails(this.props.token, this.props.match.params.id)
        this.props.getQueryHistory(this.props.token, this.props.match.params.id)

    }

    componentDidUpdate(prevProps) {
        if (prevProps.query !== this.props.query) {
            this.props.getSiteSubjects(this.props.token, this.props.query.studySite._id)
            this.props.getSiteVisits(this.props.token, this.props.query.studySite._id)
        }
    }
    
    onQueryCancel = () => {
        this.props.history.push(`${Routes.QUERIES}/${this.props.match.params.id}`);
    }
    render() {
        const { query } = this.props;
        
        let answerText = 'Answer Query';
        if (query) {
            switch (query.queryType) {
                case QueryType.EDC_QUERY:
                    answerText = 'Reupload Source';
                break;
                case QueryType.SOURCE_DATA_QUERY:
                    answerText = 'Upload Source';
                break;
                default:
                break;
                    
            }
        }

        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.QUERIES}`}>Queries</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.QUERIES}/${this.props.match.params.id}`}>Query Details</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">{answerText}</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    {this.state.alert}
                    {
                        query && query.queryType === QueryType.IMAGE_QUERY &&
                        <AnswerImageQuery file={this.state.file} {...this.props} onCancel = {this.onQueryCancel} />
                    }
                    {
                        query && 
                        (query.queryType === QueryType.SDV_QUERY ||
                         query.queryType === QueryType.SOURCE_DATA_QUERY) &&
                        <AnswerSDVQuery file={this.state.file} {...this.props} onCancel = {this.onQueryCancel} />
                    }
                    {
                        query && query.queryType === QueryType.META_DATA_QUERY &&
                        <AnswerMetaDataQuery {...this.props} onCancel = {this.onQueryCancel}/>
                    }
                    {
                        query && query.queryType === QueryType.EDC_QUERY &&
                        <AnswerEDCQuery file={this.state.file} {...this.props} onCancel = {this.onQueryCancel} />
                    }
                    {
                        this.props.responseHistory && this.props.responseHistory.length > 0 &&
                        
                        <Row className="mt-4 mb-4">
                            <Col>
                                <span style={{ fontWeight: 500, fontSize: '18px' }}>Response History</span>
                                {
                                    this.props.responseHistory.map((val, index) => {
                                        return (
                                            <Row key={index} height={110} className="mt-4 ml-2 border-bottom">
                                                <Col sm={1}>
                                                    <RenderImage alt="." className="rounded-circle" width={48} height={48} style={{ maxHeight: 48, maxWidth: 48 }} 
                                                        src={val.ownerStaff.profilePicture ? val.ownerStaff.profilePicture : require('assets/img/default_profile.jpg')} 
                                                     />
                                                </Col>
                                                <Col sm={8}>
                                                    <span style={{ fontSize: '14px', fontWeight: 700 }}>{`${val.ownerStaff.firstName} ${val.ownerStaff.lastName}`}</span><br />
                                                    <Label style={{ fontSize: '12px', fontWeight: 700, marginBottom: '0px' }}>{moment(val.createdAt).format(DATE_TIME_FORMAT)}</Label>
                                                    <p style={{ fontSize: '14px', fontWeight: 500 }}>{val.comment}</p>
                                                </Col>
                                                {
                                                    val.attachment ?
                                                        <Col sm={3}>
                                                            <RenderImage alt="." className="rounded" width={48} height={48} style={{ maxHeight: 48, maxWidth: 48 }} src={val.attachment} />
                                                        </Col>
                                                        : null
                                                }
                                            </Row>
                                        );
                                    })
                                }
                            </Col>
                        </Row>
                    }
                </div>
            </DashboardLayout>
        )
    }
}

const mapStateToProps = ({ user, queries, sites, studies }) => {
    return {
        token: user.token,
        user: user,
        query: queries.current,
        site: sites.current,
        visits: sites.visits,
        subjects: sites.subjects,
        study: studies.current,
        responseHistory: queries.history
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getQueryDetails: (token, id) => QueryActions.getQueryDetails(dispatch, token, id),
        getQueryHistory: (token, id) => QueryActions.getQueryHistory(dispatch, token, id),
        getSiteSubjects: (token, siteId) => SiteActions.getSiteSubjects(dispatch, token, siteId),
        getSiteVisits: (token, siteId) => SiteActions.getSiteVisitDetails(dispatch, token, siteId),
        saveToken: (data) => dispatch({
            type: 'GET_TOKEN',
            response: { ...data }
        }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnswerQuery)
